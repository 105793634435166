import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _isNumber from 'lodash/isNumber';
import _omit from 'lodash/omit';
import _reduce from 'lodash/reduce';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _toPairs from 'lodash/toPairs';
import _uniqBy from 'lodash/uniqBy';

import SvgPro from '@@assets/images/icons/pro.svg';
import SvgUndeliverable from '@@assets/images/icons/undeliverable.svg';

import {
  CHART_DATA_FIELD,
  DIAGRAM_TYPE,
  PAYMENT_STATUS,
  TREND_TYPE,
} from '@@constants/analytics';
import { ICON_FILL } from '@@constants/images';
import {
  getAggregateDataStoreKey,
  getChartLabel,
  getDictionary,
  getDimensionFieldName,
  getDimensionKey,
  getMembersConfig,
  getRanges,
} from '@@helpers/analytics';
import { isProAccess } from '@@helpers/billing';
import { lastMonthEnd, lastMonthStart } from '@@helpers/format/date';

import {
  createDateGroupKeyGetter,
  getDiagramDefaultConfig,
  getInfoFlag,
  getTrendDefaultConfig,
} from './utils';

const { isHobby } = require('lib/billing');
const { haveBranchAccess } = require('interface/IAccess');

export const selectAnalytics = (state) => {
  return state.analytics;
};

export const selectStatus = (state) => {
  return selectAnalytics(state).status;
};

export const selectAnalyticsConfig = (state) => {
  return selectAnalytics(state).config;
};

export const selectAnalyticsConfigData = (state) => {
  return selectAnalyticsConfig(state).data;
};

export const selectAnalyticsFilters = (state) => {
  return selectAnalytics(state).filters;
};

export const selectAnalyticsRange = (state) => {
  return (
    selectAnalyticsFilters(state).range || [lastMonthStart(), lastMonthEnd()]
  );
};

export const selectAnalyticsReportAggregates = (state) => {
  return selectAnalytics(state).aggregates;
};

export const selectAnalyticsReportCubes = (state) => {
  return selectAnalytics(state).cubes;
};

export const selectAnalyticsModels = (state) => {
  return selectAnalytics(state).models;
};

export const selectAnalyticsMembers = (state) => {
  return selectAnalytics(state).members;
};

export const selectAnalyticsDefaultConfigs = (state) => {
  return selectAnalytics(state).defaultConfigs;
};

export const selectAnalyticsMembersByConfig = (state, membersConfig) => {
  return selectAnalyticsMembers(state)[JSON.stringify(membersConfig)] || {};
};

export const selectAnalyticsReportCubesIsLoading = (state) => {
  return selectAnalyticsReportCubes(state).isLoading;
};

export const selectAnalyticsConfigsIsLoading = (state) => {
  return selectAnalyticsConfig(state).isLoading;
};

export const selectAnalyticsModelsIsLoading = createSelector(
  selectAnalyticsModels,
  (models) => {
    return _toPairs(models).some(([, { isLoading }]) => isLoading);
  },
);

export const selectCubes = createSelector(
  selectAnalyticsReportCubes,
  (cubes) => {
    return cubes.data.map(({ name, label }) => ({
      value: name,
      label,
    }));
  },
);

export const selectSortedAnalyticsConfigData = createSelector(
  selectAnalyticsConfigData,
  (configs) => {
    return [...configs].sort(({ pos: posA }, { pos: posB }) => posA - posB);
  },
);

export const selectAnalyticsOptimizedModels = createSelector(
  selectAnalyticsModels,
  (models) => {
    return _reduce(
      models,
      (result, model, object) => {
        if (model && model.isInitialized) {
          return {
            ...result,
            [object]: {
              ...model,
              data: {
                ...model.data,
                aggregates: getDictionary(model.data.aggregates),
                dimensions: getDictionary(
                  model.data.dimensions.map((dimension) => ({
                    ...dimension,
                    hierarchies: getDictionary(dimension.hierarchies),
                    levels: getDictionary(dimension.levels),
                  })),
                ),
              },
            },
          };
        }

        return { ...result, [object]: model };
      },
      {},
    );
  },
);

export const selectPreparedDefaultConfigs = createSelector(
  selectAnalyticsDefaultConfigs,
  selectAnalyticsOptimizedModels,
  selectCubes,
  (defaultConfigs, models, cubes) => {
    const groupedConfigs = _groupBy(defaultConfigs.data, ({ is_trend }) => {
      return is_trend ? String(TREND_TYPE) : String(DIAGRAM_TYPE);
    });

    const cubesMap = getDictionary(cubes, 'value');
    const rangesMap = getDictionary(getRanges(), 'value');

    return {
      [TREND_TYPE]: (groupedConfigs[TREND_TYPE] || []).reduce((res, config) => {
        const model = models[config.cube];

        if (!model || !model.isInitialized) {
          return res;
        }

        return [
          ...res,
          getTrendDefaultConfig({ model, cubesMap, rangesMap, config }),
        ];
      }, []),
      [DIAGRAM_TYPE]: (groupedConfigs[DIAGRAM_TYPE] || []).reduce(
        (res, config) => {
          const model = models[config.cube];

          if (!model || !model.isInitialized) {
            return res;
          }

          return [...res, getDiagramDefaultConfig({ model, cubesMap, config })];
        },
        [],
      ),
    };
  },
);

export const selectPreparedMembers = createSelector(
  selectAnalyticsMembers,
  selectAnalyticsOptimizedModels,
  (membersMap, models) => {
    return _toPairs(membersMap).reduce((result, [storeKey, members]) => {
      const { object, dimension, dimensionKey } = JSON.parse(storeKey);

      const dpMembers =
        members && members.isInitialized ? members.data.data : [];

      if (models[object]) {
        const { dimensions } = models[object].data;
        const labelKey = getDimensionFieldName(
          dimensions,
          dimensionKey,
          'label',
        );
        const idKey = getDimensionFieldName(dimensions, dimensionKey, 'id');
        const orderKey = getDimensionFieldName(
          dimensions,
          dimensionKey,
          'order',
        );

        const preparedDpMembers = _uniqBy(
          _sortBy(dpMembers, orderKey).map((member) => {
            return {
              id: member[idKey],
              title: member[labelKey],
              ...(member.isDeactive && {
                icon: {
                  image: SvgUndeliverable,
                  fill: ICON_FILL.RED_100,
                  size: 16,
                  position: 'left_4',
                },
              }),
            };
          }),
          'id',
        );

        // eslint-disable-next-line no-param-reassign
        result[storeKey] =
          dimension === 'location'
            ? preparedDpMembers.filter(({ id }) => haveBranchAccess(id))
            : preparedDpMembers;
      }

      return result;
    }, {});
  },
);

export const selectModelAggregateInfo = (state, { object, value }) => {
  const model = selectAnalyticsOptimizedModels(state)[object];

  if (model && model.isInitialized) {
    return _get(model, `data.aggregates.${value}.info`, {});
  }

  return {};
};

export const makeAggregateDataByConfigSelector = () =>
  createSelector(
    selectAnalyticsReportAggregates,
    (_, config) => getAggregateDataStoreKey(config),
    (aggregates, configPath) => {
      return aggregates[configPath];
    },
  );

export const makeAggregateDataIsInitializedSelector = () => {
  const selectAggregateDataByConfig = makeAggregateDataByConfigSelector();

  return createSelector(selectAggregateDataByConfig, (aggregate) => {
    return aggregate && aggregate.isInitialized;
  });
};

export const makeAggregateDataIsLoadingSelector = () => {
  const selectAggregateDataByConfig = makeAggregateDataByConfigSelector();

  return createSelector(selectAggregateDataByConfig, (aggregate) => {
    return aggregate && aggregate.isLoading;
  });
};

export const makeAggregateDataIsRequestFailedSelector = () => {
  const selectAggregateDataByConfig = makeAggregateDataByConfigSelector();

  return createSelector(selectAggregateDataByConfig, (aggregate) => {
    return aggregate && aggregate.isRequestFailed;
  });
};

export const makeChartDataSelector = () => {
  const selectAggregateDataByConfig = makeAggregateDataByConfigSelector();

  return createSelector(
    selectAggregateDataByConfig,
    selectAnalyticsOptimizedModels,
    (_, config) => config,
    (_, config, type) => type,
    (aggregate, models, config, type) => {
      const { object, value, groupBy, segmentedBy, sort } = config;

      if (aggregate && aggregate.data) {
        const model = models[object];
        const { dimensions } = model.data;

        if (type === DIAGRAM_TYPE) {
          const groupByKey = getDimensionFieldName(
            dimensions,
            groupBy,
            'label',
          );

          if (segmentedBy) {
            const segmentGroupByKey = getDimensionFieldName(
              dimensions,
              segmentedBy,
              'label',
            );

            const sergmentedData = _sortBy(
              _toPairs(_groupBy(aggregate.data.cells, groupByKey)).reduce(
                (result, [name, group]) => {
                  const { hasValue, sortValue, values } = group.reduce(
                    (res, item) => {
                      return item[value]
                        ? {
                            ...res,
                            hasValue: true,
                            sortValue: _isNumber(item[sort.field])
                              ? res.sortValue + item[sort.field]
                              : item[sort.field],
                            values: {
                              ...res.values,
                              [item[segmentGroupByKey]]: item[value],
                            },
                          }
                        : res;
                    },
                    { hasValue: false, sortValue: 0, values: {} },
                  );

                  return hasValue
                    ? [
                        ...result,
                        {
                          name: getChartLabel(name),
                          sortValue,
                          ...values,
                        },
                      ]
                    : result;
                },
                [],
              ),
              'sortValue',
            ).map((val) => _omit(val, 'sortValue'));

            if (sort.dir === 'desc') {
              _reverse(sergmentedData);
            }

            return sergmentedData;
          }

          return aggregate.data.cells.reduce((result, cell) => {
            if (cell[value]) {
              result.push({
                name: getChartLabel(cell[groupByKey]),
                [CHART_DATA_FIELD]: cell[value],
              });
            }

            return result;
          }, []);
        }

        if (type === TREND_TYPE) {
          const getGroupKey = createDateGroupKeyGetter(groupBy);

          if (segmentedBy) {
            const segmentGroupByKey = getDimensionFieldName(
              dimensions,
              segmentedBy,
              'label',
            );

            return _toPairs(_groupBy(aggregate.data.cells, getGroupKey)).map(
              ([name, group]) => ({
                name,
                ...group.reduce((result, item) => {
                  // eslint-disable-next-line no-param-reassign
                  result[item[segmentGroupByKey]] = item[value];

                  return result;
                }, {}),
              }),
            );
          }

          return aggregate.data.cells.map((cell) => ({
            name: getGroupKey(cell),
            [CHART_DATA_FIELD]: cell[value],
          }));
        }
      }

      return null;
    },
  );
};

export const makeDimensionsByCubeSelector = () =>
  createSelector(
    selectAnalyticsOptimizedModels,
    (_, object) => object,
    (models, object) => {
      const model = models[object];

      const dimensions =
        model && model.isInitialized ? model.data.dimensions : {};

      return Object.values(dimensions).reduce(
        (result, { name, label, hierarchies, levels, info }) => {
          Object.values(hierarchies).forEach(
            ({ name: hName, levels: hLevels, info: hInfo }) => {
              Object.values(hLevels).forEach((level) => {
                const sharedFields = {
                  type: info.type,
                  isDiagram: getInfoFlag(info.is_diagram, hInfo.is_diagram),
                  isSegmented: getInfoFlag(
                    info.is_segmented,
                    hInfo.is_segmented,
                  ),
                  ...(info.is_pro && !isProAccess()
                    ? {
                        icon: {
                          image: SvgPro,
                          size: 20,
                          position: 'left_4',
                          fill: ICON_FILL.ORANGE_100,
                        },
                        disabled: true,
                      }
                    : {}),
                };

                if (hName === 'default') {
                  result.push({
                    value: `${name}:${level}`,
                    label,
                    ...sharedFields,
                  });
                } else {
                  result.push({
                    value: `${name}@${hName}:${level}`,
                    label: _get(levels, `${level}.label`, ''),
                    ...sharedFields,
                  });
                }
              });
            },
          );

          return result;
        },
        [],
      );
    },
  );

export const makeAggregatesByCubeSelector = () =>
  createSelector(
    selectAnalyticsModels,
    (_, object) => object,
    (models, object) => {
      const model = models[object];

      const aggregates =
        model && model.isInitialized ? model.data.aggregates : [];

      return aggregates.map(({ name, label, info }) => ({
        value: name,
        label,
        ...(info.is_pro && !isProAccess()
          ? {
              icon: {
                image: SvgPro,
                size: 20,
                position: 'left_4',
                fill: ICON_FILL.ORANGE_100,
              },
              disabled: true,
            }
          : {}),
      }));
    },
  );

export const makeDpopdownMembersSelector = () =>
  createSelector(
    selectPreparedMembers,
    (_, memberConfig) => memberConfig,
    (preparedMembers, membersConfig) => {
      return preparedMembers[JSON.stringify(membersConfig)] || [];
    },
  );

export const makeCardMembersIsLoadingSelector = () =>
  createSelector(
    selectAnalyticsMembers,
    (_, object) => object,
    (_, object, filters) => filters,
    (members, object, filters) => {
      return filters.some(({ name }) => {
        const storeKey = JSON.stringify(getMembersConfig(object, name));

        return !members[storeKey] || members[storeKey].isLoading;
      });
    },
  );

export const makePaymentStatusSelector = () =>
  createSelector(
    selectAnalyticsOptimizedModels,
    (_, config) => config,
    (models, config) => {
      const { object, value, groupBy, segmentedBy, filters } = config;

      const model = models[object];

      if (!model || !model.isInitialized) {
        return PAYMENT_STATUS.NO_MODEL;
      }

      if (isHobby()) {
        return PAYMENT_STATUS.NOT_PAID;
      }

      const filterIsPro = filters.some(({ name }) => {
        return _get(
          model,
          `data.dimensions.${getDimensionKey(name)}.info.is_pro`,
        );
      });

      const valueIsPro = _get(model, `data.aggregates.${value}.info.is_pro`);
      const groupByIsPro = _get(
        model,
        `data.dimensions.${getDimensionKey(groupBy)}.info.is_pro`,
      );
      const segmentedByIsPro = _get(
        model,
        `data.dimensions.${getDimensionKey(segmentedBy)}.info.is_pro`,
      );

      if (
        !isProAccess() &&
        (valueIsPro || groupByIsPro || segmentedByIsPro || filterIsPro)
      ) {
        return PAYMENT_STATUS.NOT_PAID;
      }

      return PAYMENT_STATUS.PAID;
    },
  );
