# Dialog header

# +-------------------------------------------------------------+
# | [Title text] [subtitle text] Left side    |  Right side [X] |
# +-------------------------------------------------------------+

assign = require "lodash/assign"
pickBy = require "lodash/pickBy"

{render, bool} = require "lib/helpers"
{headerItem} = require "lib/widgets/Dialog/header/prefabs"
{qs, click, root} = require "lib/tplapp"


# const string: app namespace.
NS = "header"


# Dialog header.
class THeader
    T = THeader

    # static object: default opts.
    @default:

        # void: fires when user
        # wants to close the dialog.
        onClose: ->

    # string: required for
    # TTplApp realization.
    ns: NS

    # IContainer: app container.
    container: null

    # [markup|jquery]: left side layout items.
    # See standard available controls in PREFABS library.
    lside: []

    # [markup|jquery]: right side layout items.
    # See standard available controls in PREFABS library.
    rside: []

    # string: `cancel` selector.
    cancel: ""

    # string: `cancel` id.
    cancel_id: ""

    # [jsonml]: tabs content;
    # see headerTab() for standard prefab.
    tabs: []

    withReactTabs: false

    # Ctor.
    # See THeader props for opts info.
    constructor: (@container, opts={ }) ->
        @lside = opts.lside or @lside
        @rside = opts.rside or @rside
        @cancel = opts.cancel or @cancel
        @cancel_id = opts.cancel_id or @cancel_id
        @tabs = opts.tabs or @tabs
        @withReactTabs = opts.withReactTabs or @withReactTabs
        @opts = assign {}, T.default, opts


# Dialog header template.
class IHeaderTpl

    # Returns markup: header markup.
    @init: (self) ->
        {cancel, cancel_id, tabs, withReactTabs} = self

        # Display tabs?
        withTabs = tabs.length > 0

        ["div", { "class": "b-ddd-hdr js-#{NS}-root" }
            ["div"
                {
                    "class": [
                        "b-ddd-hdr__wrap"
                        "b-ddd-hdr__wrap_style_border" unless withTabs
                    ].join " "
                }

                # Content area.
                ["div", { "class": "b-ddd-hdr__content" }
                    ["div", { "class": "b-ddd-hdr__lside js-#{NS}-lside" }]
                    ["div", { "class": "b-ddd-hdr__rside js-#{NS}-rside" }]
                ]

                # Close button.
                ["div"
                    pickBy {
                        "id": cancel_id
                        "class": [
                            "b-ddd-hdr__close"
                            "js-#{NS}-close"
                            cancel
                        ].join " "
                    }, bool

                    ["div"
                        { 
                            "class": "b-close
                                    b-close_size_std
                                    b-close_style_std"
                            "data-cid": "modal-close"
                        }
                    ]
                ]
            ]

            # Tabs.
            ["div", { "class": "b-ddd-hdr__tabs" }
                ["div", { "class": "b-tabs" }, tabs...]
            ] if withTabs

            ["div", { "class": "b-ddd-hdr__tabs js-react-tabs" }] if withReactTabs
        ]


# Dialog header interface.
class IHeader

    # Returns jquery: root element reference.
    @root: (self) -> root self

    # Renders dialog template.
    @render: (self) ->
        # Rendering template.
        self.container.append(
            render IHeaderTpl.init self
        )

        # Putting items into
        # left, right placeholders.
        append = (item, area) ->
            area.append headerItem(item).data

        lside_root = qs self, "lside"
        append item, lside_root for item in self.lside

        rside_root = qs self, "rside"
        append item, rside_root for item in self.rside

        self

    # Performs dialog config
    # after template render.
    @config: (self) ->
        # Close button.
        click self, "close", =>
            self.opts.onClose self

        self

    # Entry point.
    # -> THeader: app instance.
    @init: (self) -> @config @render self


module.exports = {
    IHeaderTpl
    THeader
    IHeader
}
