import { memo } from 'react';
import PropTypes from 'prop-types';

import FeatureFlagsContext from './context';

function FeatureFlagsProvider(props) {
  const { children, flags } = props;

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flags: PropTypes.arrayOf(
    PropTypes.shape({
      isEnabled: PropTypes.bool,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default memo(FeatureFlagsProvider);
