# Dialog header prefabs.

omit = require "lodash/omit"

{isJsonml, isjQuery, render} = require "lib/helpers"
{pMerge} = require "lib/layout"
{error} = require "lib/logger"


# Header item wrapper function.
# -> markup|jquery content: content to wrap.
# -> *string type: item custom CSS-type.
# Returns { data:IDom }: wrapper object.
headerItem = (content, type) ->
    return unless content

    # Nothing to do, if this already wrapped item.
    return content if content.data

    tpl = (type) ->
        ["div", { "class": [ "b-ddd-hdr__item"
                           , " b-ddd-hdr__item_type_" if type
                           ,  type if type ].join '' }
        ]

    # Returning wrapper object.

    # Is this a markup?
    if isJsonml content then return data: (
        jQuery render tpl(type).concat [content]
    )

    # Is this a DOM-struct?
    if isjQuery content then return data: (
        jQuery render tpl type
    ).html content

    # In other case - WTF?
    error "Bad argument passed to headerItem();
           jsonml or jquery object is expected"


# Dialog title prefab.
# Returns object: standard dialog title layout.
# -> string title: main title text.
# -> string suble: subtitle text.

# NOTE this is a RECOMMENDED title,
# since it's already mobile-friendly.
dtitle = (title, suble, kwargs={}) ->
    {title_attribs, suble_atttibs} = kwargs

    tpl = (title, suble) ->
        ["div", { "class": "b-ddd-title" }
            ["div"
                pMerge(
                    class: "b-ddd-title__title"
                    title_attribs
                )
                title
            ]

            ["div"
                pMerge(
                    class: "b-ddd-title__suble"
                    suble_atttibs
                )
                suble
            ] if suble
        ]

    headerItem tpl(title, suble), "title"


# Returns jsonml: header tab markup.
# Used as `tabs` property of THeader.
# -> string title: title text.
# Some kwargs are passed as HTML-attribs.
headerTab = (title, kwargs={}) ->
    attribs = omit kwargs, [
        # omit here non-HTML attribs
    ]

    ["div"
        pMerge { "class": "b-tabs__tab" }, attribs
        ["span", title]
    ]


module.exports = {
    headerTab
    headerItem
    dtitle
}
