import JSCookie from 'js-cookie';

import namespaceService from '@@services/namespace';

// Initializing cookie service with global default attributes.
// { path: '/' } comes as default attribute.
const Cookie = JSCookie.withAttributes(
  (() => {
    const domain = namespaceService.getHostname();
    const prefix = __DEV__ ? '' : '.';

    return {
      domain: `${prefix}${domain}`,
    };
  })(),
);

const cookieService = {
  // Get a particular cookie.
  //
  // Note: It is not possible to read a particular cookie by passing one of the cookie attributes.
  // The domain and/or path attribute will not have an effect when reading.
  get: (name) => {
    return name ? Cookie.get(name) : undefined;
  },

  // Read all visible cookies of the current domain.
  getAll: () => Cookie.get(),

  // Create a cookie.
  set: Cookie.set,

  // Remove a particular cookie relying on attributes.
  remove: Cookie.remove,

  // Read and remove all visible (ignores http only cookies) cookies.
  removeAll: () => {
    Object.keys(Cookie.get()).forEach(cookieService.remove);
  },

  // Indicates whether cookies are enabled or not.
  isEnabled: () => navigator.cookieEnabled,
};

export default cookieService;
