export enum ReceiptType {
  productSticker,
  priceSticker,
  sale,
  order,
  invoice,
  ordersList,
  assetSticker,
}

export interface Receipt {
  id: number;
  title: string;
  receipt_type: ReceiptType;
  width?: number;
  height?: number;
  included_statuses: number[];
  is_include_for_all: boolean;
  branch_id?: number;
  price_with_coins?: boolean;
}

export interface CreateReceiptArgs
  extends Required<
    Pick<
      Receipt,
      'title' | 'receipt_type' | 'included_statuses' | 'price_with_coins'
    >
  > {
  width: number | null;
  height: number | null;
}

export interface UpdateReceiptArgs extends CreateReceiptArgs {
  id: Receipt['id'];
}
