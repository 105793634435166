import toast from 'react-hot-toast';
import parse from 'date-fns/parse';
import _fromPairs from 'lodash/fromPairs';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isBoolean from 'lodash/isBoolean';
import _toPairs from 'lodash/toPairs';

import SvgChartBars from '@@assets/images/icons/chart_bars.svg';
import SvgChartSparkline from '@@assets/images/icons/chart_sparkline.svg';

import {
  CHART_TYPE,
  DIAGRAM_TYPE,
  LAST_24_MONTHS,
  LAST_24_WEEKS,
  LAST_30_DAYS,
  WIDTHS_BY_AGGREGATES_NAMES,
  WIDTHS_BY_AGGREGATES_TYPES,
} from '@@constants/analytics';
import { TEXTAREA, TEXTFIELD } from '@@constants/fieldTypes';
import {
  getDimensionFieldName,
  getDimensionKey,
  getHierarchyKey,
  getLevelKey,
  getMembersConfig,
  getTrendOptions,
} from '@@helpers/analytics';
import { sleep } from '@@helpers/common/sleep';
import { format, getTimezone } from '@@helpers/format/date';

const getTypes = require('lib/widgets/DateSelector/types');

export const getAggregateRangeCut = (value) => {
  const dateTypes = getTypes();
  const range = Array.isArray(value) ? value : dateTypes[value].range;

  return `created_at@daily:${format(range[0], 'yyyy,MM,dd')}-${format(
    range[1],
    'yyyy,MM,dd',
  )}`;
};

export const getLocationsIntersection = (baseLocations, filterLocations) => {
  const baseLocationsIds = baseLocations.map(({ id }) => id);

  return filterLocations && filterLocations.length > 0
    ? _intersection(baseLocationsIds, filterLocations)
    : baseLocationsIds;
};

export const getAggregateFiltersCut = ({
  config: { filters, object },
  locations,
  membersMap,
  model,
}) => {
  const filtersMap = _fromPairs(filters.map((filter) => [filter.name, filter]));

  const filterLocations = filtersMap['location:location'];
  const { dimensions } = model.data;

  filtersMap['location:location'] = dimensions.location
    ? {
        ...filterLocations,
        value: getLocationsIntersection(
          locations,
          filterLocations && filterLocations.value,
        ),
      }
    : null;

  return _toPairs(filtersMap).map(([name, filter]) => {
    if (
      !filter ||
      !filter.value ||
      (Array.isArray(filter.value) && filter.value.length === 0)
    ) {
      return null;
    }

    const dimensionKey = getDimensionKey(name);
    const hierarchyKey = getHierarchyKey(name);
    const filterName = `${dimensionKey}${
      hierarchyKey !== 'default' ? `@${hierarchyKey}` : ''
    }`;

    const type = _get(dimensions, `${dimensionKey}.info`);

    const isTextFieldType = type === TEXTFIELD || type === TEXTAREA;

    const members = membersMap[JSON.stringify(getMembersConfig(object, name))];

    if (isTextFieldType) {
      return `${filterName}:${filter.value}`;
    }

    if (members && _isBoolean(filter.equal)) {
      const filterValueSet = new Set(filter.value);
      const values = members.reduce((result, { id }) => {
        if (
          (filter.equal && filterValueSet.has(String(id))) ||
          (!filter.equal && !filterValueSet.has(String(id)))
        ) {
          result.push(id);
        }

        return result;
      }, []);

      return values.length ? `${filterName}:${values.join(';')}` : null;
    }

    return `${filterName}:${filter.value.join(';')}`;
  });
};

export const getOrder = (sort) =>
  sort && sort.field ? `${sort.field}:${sort.dir}` : null;

export const createCubePayload = (payload) => {
  const { type, config, range } = payload;
  const { groupBy, segmentedBy, sort } = config;

  const trendOptions = getTrendOptions();
  const filtersCut = getAggregateFiltersCut(payload);

  if (type === DIAGRAM_TYPE) {
    return {
      tz: getTimezone({ isLocal: true }),
      drilldown: [groupBy, segmentedBy].filter(Boolean),
      cut: [getAggregateRangeCut(range), ...filtersCut].filter(Boolean),
      order: [getOrder(sort)].filter(Boolean),
    };
  }

  return {
    tz: getTimezone({ isLocal: true }),
    drilldown: [trendOptions[groupBy].drilldown, segmentedBy].filter(Boolean),
    cut: [...trendOptions[groupBy].cut, ...filtersCut].filter(Boolean),
    order: trendOptions[groupBy].attributes,
  };
};

export const createPositionsPayload = (current, second) => {
  return {
    id: current.id,
    type: current.type,
    config: current.config,
    pos: second.pos,
  };
};

export const getAggregateType = (aggregates, field) => {
  return _get(aggregates, `${field}.info.type`);
};

export const getCellWidthByAggregate = (aggregates, name) => {
  const aggregateType = getAggregateType(aggregates, name);

  const widthByAggregateName = WIDTHS_BY_AGGREGATES_NAMES[name];

  if (widthByAggregateName) {
    return widthByAggregateName;
  }

  const widthByAggregateType = WIDTHS_BY_AGGREGATES_TYPES[aggregateType];

  if (widthByAggregateType) {
    return widthByAggregateType;
  }

  return 100;
};

// eslint-disable-next-line react/function-component-definition
export const createDateGroupKeyGetter = (groupBy) => (cell) => {
  const trendOptions = getTrendOptions();

  const {
    attributes,
    parse: parseTpl,
    format: formatTpl,
  } = trendOptions[groupBy];

  if (groupBy === LAST_30_DAYS || groupBy === LAST_24_MONTHS) {
    const parsedDate = parse(
      attributes
        .map((attr) => {
          const { [attr]: attrCell } = cell;
          return attrCell;
        })
        .join('.'),
      parseTpl,
      new Date(),
    );

    return format(parsedDate, formatTpl);
  }

  if (groupBy === LAST_24_WEEKS) {
    const createAtFirstDay = 'created_at.first_day_of_week';
    const createAtLastDay = 'created_at.last_day_of_week';
    const { [createAtFirstDay]: firstDay, [createAtLastDay]: lastDay } = cell;

    return `${format(firstDay, formatTpl)} - ${format(lastDay, formatTpl)}`;
  }

  return null;
};

export const getInfoFlag = (flag, hFlag) => {
  if (_isBoolean(flag)) {
    return flag;
  }

  if (_isBoolean(hFlag)) {
    return hFlag;
  }

  return true;
};

export const getTrendDefaultConfig = ({
  model,
  cubesMap,
  rangesMap,
  config: { cube, aggregation, cut, drilldown = '' },
}) => {
  const cubeLabel = _get(cubesMap, `${cube}.label`);
  const cutLabel = _get(rangesMap, `${cut}.label`);
  const aggregationLabel = _get(model, `data.aggregates.${aggregation}.label`);

  return {
    icon: { icon: SvgChartSparkline },
    label: `${cubeLabel} — ${aggregationLabel} ${__('per')} ${cutLabel}`,
    value: {
      object: cube,
      value: aggregation,
      groupBy: cut,
      segmentedBy: drilldown,
      chartType: CHART_TYPE.LINE,
      filters: [],
      sort: {},
    },
  };
};

export const getDiagramDefaultConfig = ({
  model,
  cubesMap,
  config: { cube, aggregation, drilldown },
}) => {
  const orderKey = getDimensionFieldName(
    model.data.dimensions,
    drilldown,
    'order',
  );

  const cubeLabel = _get(cubesMap, `${cube}.label`);
  const aggregationLabel = _get(model, `data.aggregates.${aggregation}.label`);

  const dimensionKey = getDimensionKey(drilldown);
  const hierarchyKey = getHierarchyKey(drilldown);
  const levelKey = getLevelKey(drilldown);
  const dimension = model.data.dimensions[dimensionKey];
  const drilldownLabel =
    hierarchyKey === 'default'
      ? _get(dimension, 'label')
      : _get(dimension, `levels.${levelKey}.label`);

  return {
    icon: { icon: SvgChartBars },
    label: `${cubeLabel} — ${aggregationLabel} ${__('by')} ${drilldownLabel}`,
    value: {
      object: cube,
      value: aggregation,
      groupBy: drilldown,
      segmentedBy: '',
      chartType: CHART_TYPE.VERTICAL_BAR,
      filters: [],
      sort: { field: orderKey, dir: 'asc' },
    },
  };
};

export const errorMessage = () => {
  toast.error(__('Error occurred. Please contact technical support'));
};

export const longPool = async (cb, ...rest) => {
  await sleep(5000);
  const promise = await cb(...rest, {
    isRecursive: true,
  });

  return promise;
};

export const setResponseStatus = (param) => (response) => {
  // eslint-disable-next-line no-param-reassign
  param.status = response.status;
};
