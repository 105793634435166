import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isBoolean from 'lodash/isBoolean';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';

import {
  createAnalyticalConfig,
  deleteAnalyticalConfig,
  getAnalyticalAggregate,
  getAnalyticalConfigs,
  getAnalyticalCubes,
  getAnalyticalDefaultConfigs,
  getAnalyticalMembers,
  getAnalyticalModel,
  updateAnalyticalConfig,
} from '@@api/analytics';
import {
  ANALYTICS_LS_NAME,
  CUBES_STATUS,
  DIAGRAM_TYPE,
  DIMENSION_TYPE,
  DIRECTION,
} from '@@constants/analytics';
import {
  getAggregateDataStoreKey,
  getDimensionFieldName,
} from '@@helpers/analytics';
import { convertTimezones, getTimezone } from '@@helpers/format/date';
import { selectDropdownByName } from '@@redux/dropdown/selectors';

import {
  makeAggregatesByCubeSelector,
  makeDimensionsByCubeSelector,
  selectAnalyticsConfigData,
  selectAnalyticsDefaultConfigs,
  selectAnalyticsMembers,
  selectAnalyticsOptimizedModels,
  selectAnalyticsRange,
  selectAnalyticsReportCubes,
  selectModelAggregateInfo,
  selectPreparedMembers,
  selectSortedAnalyticsConfigData,
} from './selectors';
import {
  createCubePayload,
  createPositionsPayload,
  errorMessage,
  longPool,
  setResponseStatus,
} from './utils';

const { get } = require('lib/widgets/DateSelector/storage');

const emptyJsonErrors = [
  'Unexpected end of JSON input',
  'The string did not match the expected pattern.',
];

export const initialState = {
  status: CUBES_STATUS.NONE,
  filters: {
    range: get(ANALYTICS_LS_NAME),
  },
  config: {
    data: [],
    isLoading: false,
    isInitialized: false,
    isRequestFailed: false,
  },
  cubes: {
    data: [],
    isLoading: false,
    isInitialized: false,
    isRequestFailed: false,
  },
  defaultConfigs: {
    data: [],
    isLoading: false,
    isInitialized: false,
    isRequestFailed: false,
  },
  models: {},
  aggregates: {},
  members: {},
};

export const createAnalyticsConfig = createAsyncThunk(
  'analytics/createAnalyticsConfig',
  async ({ type, config }, { getState, rejectWithValue }) => {
    const state = getState();

    const configs = selectSortedAnalyticsConfigData(state);

    const pos = configs.length > 0 ? configs[configs.length - 1].pos + 1 : 0;

    const response = await createAnalyticalConfig(
      {
        type,
        config,
        pos,
      },
      {
        isDuplicated: false,
      },
    );

    if (response.message) {
      errorMessage();

      return rejectWithValue(response.message);
    }

    return response;
  },
);

export const deleteAnalyticsConfig = createAsyncThunk(
  'analytics/deleteAnalyticsConfig',
  async ({ id }, { rejectWithValue }) => {
    const response = await deleteAnalyticalConfig({
      id,
    });

    if (response.message) {
      errorMessage();

      return rejectWithValue(response.message);
    }

    return response;
  },
);

export const updateAnalyticsConfigPosition = createAsyncThunk(
  'analytics/updateAnalyticsConfigPosition',
  async ({ id, direction }, { getState, rejectWithValue }) => {
    const state = getState();

    const configs = selectSortedAnalyticsConfigData(state);
    const currentConfigIndex = configs.findIndex((config) => config.id === id);
    const secondConfigIndex =
      direction === DIRECTION.UP
        ? currentConfigIndex - 1
        : currentConfigIndex + 1;
    const currentConfig = configs[currentConfigIndex];
    const secondConfig = configs[secondConfigIndex];

    const responses = await Promise.all([
      updateAnalyticalConfig(
        createPositionsPayload(currentConfig, secondConfig),
      ),
      updateAnalyticalConfig(
        createPositionsPayload(secondConfig, currentConfig),
      ),
    ]);

    if (
      responses.some(
        (response) =>
          response.message && !emptyJsonErrors.includes(response.message),
      )
    ) {
      errorMessage();

      return rejectWithValue();
    }

    return { currentConfig, secondConfig };
  },
);

export const updateAnalyticsConfig = createAsyncThunk(
  'analytics/updateAnalyticsConfig',
  async ({ id, configField, value }, { getState, rejectWithValue }) => {
    const state = getState();

    const configs = selectAnalyticsConfigData(state);

    const { type, pos, config } = configs.find((item) => item.id === id);

    const requestConfig = {
      ...config,
      [configField]: value,
    };

    if (configField === 'object') {
      const aggregates = makeAggregatesByCubeSelector()(state, value);

      requestConfig.value = aggregates[0].value;
      requestConfig.segmentedBy = '';
      requestConfig.filters = [];

      if (type === DIAGRAM_TYPE) {
        const dimensions = makeDimensionsByCubeSelector()(state, value).filter(
          ({ isDiagram }) => isDiagram,
        );

        requestConfig.groupBy = dimensions[0].value;
        requestConfig.sort = {};
      }
    }

    if (type === DIAGRAM_TYPE && configField === 'value') {
      const { can_segment } = selectModelAggregateInfo(state, requestConfig);

      requestConfig.segmentedBy =
        _isUndefined(can_segment) || can_segment
          ? requestConfig.segmentedBy
          : '';
    }

    if (
      type === DIAGRAM_TYPE &&
      (configField === 'object' || configField === 'groupBy')
    ) {
      const model = selectAnalyticsOptimizedModels(state)[requestConfig.object];
      const orderKey = getDimensionFieldName(
        model.data.dimensions,
        requestConfig.groupBy,
        'order',
      );

      requestConfig.sort = { field: orderKey, dir: 'asc' };
    }

    const response = await updateAnalyticalConfig({
      id,
      type,
      pos,
      config: requestConfig,
    });

    if (response.message && !emptyJsonErrors.includes(response.message)) {
      errorMessage();

      return rejectWithValue(response.message);
    }

    return { id, config: requestConfig };
  },
);

export const fetchAnalyticsConfigs = createAsyncThunk(
  'analytics/fetchAnalyticsConfigs',
  async (_, { rejectWithValue }) => {
    const response = await getAnalyticalConfigs(null, { isDuplicated: false });

    if (response.message) {
      errorMessage();

      return rejectWithValue(response.message);
    }

    return response;
  },
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    migrateDates(state, { payload }) {
      const { prevTimezone, nextTimezone } = payload;

      if (_isArray(state.filters.range)) {
        state.filters.range = state.filters.range.map((date) =>
          convertTimezones(date, prevTimezone, nextTimezone),
        );
      }
    },
    setCubesStatus(state, { payload }) {
      state.status = payload;
    },
    setReportFilter(state, { payload }) {
      state.filters = { ...state.filters, ...payload };
    },
    resetAggregateData(state) {
      state.aggregates = _reduce(
        state.aggregates,
        (res, aggregate, key) => ({
          ...res,
          [key]: {
            ...aggregate,
            data: null,
            isLoading: false,
            isInitialized: false,
          },
        }),
        {},
      );
    },
    fetchAnalyticsAggregateDataPending(state, { payload }) {
      const { storeKey } = payload;

      state.aggregates[storeKey] = {
        data: null,
        isLoading: true,
        isInitialized: false,
        isRequestFailed: false,
      };
    },
    fetchAnalyticsAggregateDataFulfilled(state, { payload }) {
      const { storeKey, data } = payload;

      state.aggregates[storeKey].isLoading = false;
      state.aggregates[storeKey].isInitialized = true;
      state.aggregates[storeKey].data = data;
    },
    fetchAnalyticsAggregateDataRejected(state, { payload }) {
      const { storeKey } = payload;

      state.aggregates[storeKey].isLoading = false;
      state.aggregates[storeKey].isRequestFailed = true;
    },
    fetchAnalyticsCubesPending(state) {
      state.cubes.isLoading = true;
    },
    fetchAnalyticsCubesFulfilled(state, { payload }) {
      state.cubes.data = payload;
      state.cubes.isLoading = false;
      state.cubes.isInitialized = true;
    },
    fetchAnalyticsCubesRejected(state) {
      state.cubes.isLoading = false;
      state.cubes.isRequestFailed = true;
    },
    fetchAnalyticsModelsPending(state) {
      state.models = {
        ...state.models,
        ...state.cubes.data.reduce(
          (result, { name }) => ({
            ...result,
            [name]: {
              data: null,
              isLoading: true,
              isInitialized: false,
              isRequestFailed: false,
            },
          }),
          {},
        ),
      };
    },
    fetchAnalyticsModelsFulfilled(state, { payload }) {
      state.models = {
        ...state.models,
        ...payload.reduce(
          (result, model) => ({
            ...result,
            [model.name]: {
              data: model,
              isLoading: false,
              isInitialized: true,
              isRequestFailed: false,
            },
          }),
          {},
        ),
      };
    },
    fetchAnalyticsModelsRejected(state) {
      state.models = {
        ...state.models,
        ...state.cubes.data.reduce(
          (result, { name }) => ({
            ...result,
            [name]: {
              ...state.models[name],
              isLoading: false,
              isRequestFailed: true,
            },
          }),
          {},
        ),
      };
    },
    fetchAnalyticsMemberPending(state, { payload }) {
      const { storeKey } = payload;

      state.members[storeKey] = {
        data: null,
        isLoading: true,
        isInitialized: false,
        isRequestFailed: false,
      };
    },
    fetchAnalyticsMemberFulfilled(state, { payload }) {
      const { storeKey, data } = payload;

      state.members[storeKey].data = data;
      state.members[storeKey].isLoading = false;
      state.members[storeKey].isInitialized = true;
    },
    fetchAnalyticsMemberRejected(state, { payload }) {
      const { storeKey } = payload;

      state.members[storeKey].isLoading = false;
      state.members[storeKey].isRequestFailed = true;
    },
    fetchDefaultConfigsPending(state) {
      state.defaultConfigs.isLoading = true;
    },
    fetchDefaultConfigsFulfilled(state, { payload }) {
      state.defaultConfigs.data = payload;
      state.defaultConfigs.isLoading = false;
      state.defaultConfigs.isInitialized = true;
    },
    fetchDefaultConfigsRejected(state) {
      state.defaultConfigs.isLoading = false;
      state.defaultConfigs.isRequestFailed = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAnalyticsConfig.fulfilled, (state, { payload }) => {
      state.config.data.push(payload);
    });

    builder.addCase(deleteAnalyticsConfig.fulfilled, (state, { meta }) => {
      const { id } = meta.arg;

      state.config.data = state.config.data.filter((item) => item.id !== id);
    });

    builder.addCase(
      updateAnalyticsConfigPosition.fulfilled,
      (state, { payload }) => {
        const { currentConfig, secondConfig } = payload;

        state.config.data = state.config.data.map((config) => {
          if (config.id === currentConfig.id) {
            return {
              ...config,
              pos: secondConfig.pos,
            };
          }

          if (config.id === secondConfig.id) {
            return {
              ...config,
              pos: currentConfig.pos,
            };
          }

          return config;
        });
      },
    );

    builder.addCase(updateAnalyticsConfig.fulfilled, (state, { payload }) => {
      const { id, config } = payload;

      state.config.data = state.config.data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            config,
          };
        }

        return item;
      });
    });

    builder.addCase(fetchAnalyticsConfigs.pending, (state) => {
      state.config.isLoading = true;
    });

    builder.addCase(fetchAnalyticsConfigs.fulfilled, (state, { payload }) => {
      state.config.data = payload;
      state.config.isLoading = false;
      state.config.isInitialized = true;
    });

    builder.addCase(fetchAnalyticsConfigs.rejected, (state) => {
      state.config.isLoading = false;
      state.config.isRequestFailed = true;
    });
  },
});

export const {
  migrateDates,
  setCubesStatus,
  setReportFilter,
  resetAggregateData,
  fetchAnalyticsAggregateDataPending,
  fetchAnalyticsAggregateDataFulfilled,
  fetchAnalyticsAggregateDataRejected,
  fetchAnalyticsCubesPending,
  fetchAnalyticsCubesFulfilled,
  fetchAnalyticsCubesRejected,
  fetchAnalyticsModelsPending,
  fetchAnalyticsModelsFulfilled,
  fetchAnalyticsModelsRejected,
  fetchAnalyticsMemberPending,
  fetchAnalyticsMemberFulfilled,
  fetchAnalyticsMemberRejected,
  fetchDefaultConfigsPending,
  fetchDefaultConfigsFulfilled,
  fetchDefaultConfigsRejected,
} = analyticsSlice.actions;

export const fetchAnalyticsAggregateData = (payload) => {
  let isRequested = false;

  return async function recursive(dispatch, getState, { isRecursive } = {}) {
    const { config } = payload;
    const { object } = config;

    const state = getState();

    const model = selectAnalyticsOptimizedModels(state)[object];
    const range = selectAnalyticsRange(state);
    const { selected: locations } = selectDropdownByName(
      state,
      ANALYTICS_LS_NAME,
    );

    if (!isRecursive && (!model || !model.isInitialized)) {
      return true;
    }

    const storeKey = getAggregateDataStoreKey(config);

    if (!isRequested) {
      dispatch(fetchAnalyticsAggregateDataPending({ storeKey }));

      isRequested = true;
    }

    const membersMap = selectPreparedMembers(state);

    const response = await getAnalyticalAggregate(
      createCubePayload({
        ...payload,
        range,
        locations,
        membersMap,
        model,
      }),
      {
        params: { object },
        extraResponseData: setResponseStatus(recursive),
        getErrorResponse: setResponseStatus(recursive),
        errorsHandler: () => {},
        isDuplicated: false,
      },
    );

    if (recursive.status === HttpStatusCode.Accepted) {
      const promise = await longPool(recursive, dispatch, getState);

      return promise;
    }

    if (recursive.status >= HttpStatusCode.BadRequest) {
      return dispatch(fetchAnalyticsAggregateDataRejected({ storeKey }));
    }

    return dispatch(
      fetchAnalyticsAggregateDataFulfilled({ storeKey, data: response }),
    );
  };
};

export const fetchAnalyticsCubes = () => {
  let isRequested = false;
  let isCreating = false;

  return async function recursive(dispatch, getState, { isRecursive } = {}) {
    const { isLoading, isInitialized } = selectAnalyticsReportCubes(getState());

    if (!isRecursive && (isLoading || isInitialized)) {
      return true;
    }

    if (!isRequested) {
      dispatch(fetchAnalyticsCubesPending());

      isRequested = true;
    }

    const response = await getAnalyticalCubes(
      {
        tz: getTimezone({ isLocal: true }),
      },
      { extraResponseData: setResponseStatus(recursive), isDuplicated: false },
    );

    if (recursive.status === HttpStatusCode.Accepted) {
      if (!isCreating) {
        dispatch(setCubesStatus(CUBES_STATUS.CREATING));

        isCreating = true;
      }

      const promise = await longPool(recursive, dispatch, getState);

      return promise;
    }

    dispatch(setCubesStatus(CUBES_STATUS.CREATED));

    if (response.message) {
      errorMessage();

      return dispatch(fetchAnalyticsCubesRejected());
    }

    return dispatch(fetchAnalyticsCubesFulfilled(response));
  };
};

const fetchAnalyticsModel = (name, isCreating) => {
  return async function recursive(dispatch) {
    const response = await getAnalyticalModel(
      {
        tz: getTimezone({ isLocal: true }),
      },
      {
        params: { name },
        extraResponseData: setResponseStatus(recursive),
        isDuplicated: false,
      },
    );

    if (recursive.status === HttpStatusCode.Accepted) {
      if (!isCreating.current) {
        dispatch(setCubesStatus(CUBES_STATUS.CREATING));

        // eslint-disable-next-line no-param-reassign
        isCreating.current = true;
      }

      const promise = await longPool(recursive, dispatch);

      return promise;
    }

    return response;
  };
};

export const fetchAnalyticsModels = () => {
  const isCreating = { current: false };

  return async function recursive(dispatch, getState) {
    const { data } = selectAnalyticsReportCubes(getState());

    dispatch(fetchAnalyticsModelsPending());

    const requests = data.map(({ name }) =>
      fetchAnalyticsModel(name, isCreating)(dispatch),
    );

    const responses = await Promise.all(requests);

    dispatch(setCubesStatus(CUBES_STATUS.CREATED));

    if (responses.some((response) => response.message)) {
      errorMessage();

      return dispatch(fetchAnalyticsModelsRejected());
    }

    return dispatch(fetchAnalyticsModelsFulfilled(responses));
  };
};

export const fetchAnalyticsMember = (payload) => {
  let isRequested = false;

  return async function recursive(dispatch, getState, { isRecursive } = {}) {
    const storeKey = JSON.stringify(payload);

    const state = getState();
    const member = selectAnalyticsMembers(state)[storeKey];

    const { object, dimension, hierarchy, level } = payload;

    const model = selectAnalyticsOptimizedModels(state)[object];

    const levels = _get(
      model,
      `data.dimensions.${dimension}.hierarchies.${hierarchy}.levels`,
    );

    if (
      !levels ||
      (!isRecursive && member && (member.isLoading || member.isInitialized))
    ) {
      return true;
    }

    if (!isRequested) {
      dispatch(fetchAnalyticsMemberPending({ storeKey }));

      isRequested = true;
    }

    const depth = levels.findIndex((item) => item === level) + 1;

    const response = await getAnalyticalMembers(
      {
        hierarchy,
        depth,
        tz: getTimezone({ isLocal: true }),
      },
      {
        params: { object, dimension },
        extraResponseData: setResponseStatus(recursive),
        isDuplicated: false,
      },
    );

    if (recursive.status === HttpStatusCode.Accepted) {
      const promise = await longPool(recursive, dispatch, getState);

      return promise;
    }

    if (response.message) {
      errorMessage();

      return dispatch(fetchAnalyticsMemberRejected({ storeKey }));
    }

    if (
      dimension === DIMENSION_TYPE.AUTHOR ||
      dimension === DIMENSION_TYPE.MANAGER
    ) {
      const { EMPLOYEES_HASH } = require('interface/Company');

      const employees = response.data.map((employee) => {
        const id = employee[`${dimension}.id`];

        if (id) {
          return {
            ...employee,
            isDeactive: _isBoolean(EMPLOYEES_HASH[id]?.isActive)
              ? !EMPLOYEES_HASH[id].isActive
              : false,
          };
        }

        return employee;
      });

      return dispatch(
        fetchAnalyticsMemberFulfilled({
          storeKey,
          data: { ...response, data: employees },
        }),
      );
    }

    return dispatch(
      fetchAnalyticsMemberFulfilled({ storeKey, data: response }),
    );
  };
};

export const fetchDefaultConfigs = () =>
  async function recursive(dispatch, getState) {
    const state = getState();

    const { isLoading, isInitialized } = selectAnalyticsDefaultConfigs(state);

    if (isLoading || isInitialized) {
      return true;
    }

    dispatch(fetchDefaultConfigsPending());

    const response = await getAnalyticalDefaultConfigs(
      {
        tz: getTimezone({ isLocal: true }),
      },
      { isDuplicated: false },
    );

    if (response.message) {
      errorMessage();

      return dispatch(fetchDefaultConfigsRejected());
    }

    return dispatch(fetchDefaultConfigsFulfilled(response));
  };

export default analyticsSlice.reducer;
