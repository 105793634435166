/**
 * All legacy files should be removed.
 * */
import {
  completeRequest,
  isDuplicatedRequest,
  registerRequest,
  removeRequest,
  retry,
} from '@@helpers/transport/retry';
import {
  getRefreshToken,
  isTokenExpire,
  setTokens,
} from '@@helpers/transport/tokens';
import { setReloadApp } from '@@redux/appInfo/slice';

import { doLogoutRoutine, mutex } from '../shared/common';
import { getRequestParams } from '../shared/legacy';

import {
  contentTypes,
  errorStatusChecker,
  getGetParams,
  getPostParams,
  getResponseType,
  getUrl,
  isHttpOkRange,
  Methods,
  requestTypes,
  resolveCatch,
} from './utils';

const isTestEnv = process.env.NODE_ENV === 'test';

export const fetchRequest = (params) => {
  const {
    url,
    method = Methods.get,
    payload,
    requestType = requestTypes.json,
    container,
    containerId,
    sccb,
    ercb,
    isApp = true,
    isStaff,
    isChatService,
    getErrorResponse,
    extraResponseData,
    errorsHandler,
    isDuplicated = true,
    shouldCallCallbacks = true,
    withBranch = true,
    retriesCount,
    backoff,
  } = params;

  let extraData = null;
  let needParseResponse = true;
  // check url with queries params
  const splitUrlBySearchParam = url.split('?');
  const realUrl =
    splitUrlBySearchParam.length > 1 ? splitUrlBySearchParam[0] : url;
  const requestId = registerRequest('legacy', params);

  if (isDuplicated && isDuplicatedRequest(requestId)) {
    removeRequest(requestId);
    return Promise.resolve({});
  }

  return fetch(
    getUrl({
      url: realUrl,
      payload,
      isApp,
      isStaff,
      isChatService,
      flatQueries: splitUrlBySearchParam[1],
      isGetMethod: method === Methods.get,
    }),
    method === Methods.get
      ? getGetParams({ withBranch, withCompanyId: isChatService })
      : getPostParams({
          payload,
          method,
          requestType,
          withBranch,
          withCompanyId: isChatService,
        }),
  )
    .then(async (response) => {
      if (
        process.env.VERSION !== response.headers.get('X-Version') &&
        !isChatService
      ) {
        const { store } = require('@@redux/store');

        store.dispatch(setReloadApp());
      }
      retry(fetchRequest, params, response, retriesCount, backoff);

      completeRequest(requestId, response.status, response.statusText);

      if (extraResponseData) {
        extraData = extraResponseData(response);
      }

      if (isHttpOkRange(response)) {
        return Promise.resolve(response);
      }

      if (isTokenExpire(response)) {
        needParseResponse = false;

        // eslint-disable-next-line no-use-before-define
        const requestParams = getRequestParams(requestId);

        if (!mutex.isLocked()) {
          const release = await mutex.acquire();

          try {
            // eslint-disable-next-line no-use-before-define
            const updateTokenResponse = await updateToken();
            // does not allow you to make repeted request if user alredy logouted
            if (!updateTokenResponse.isAuthenticated) {
              return Promise.reject(response);
            }

            return fetchRequest(requestParams);
          } finally {
            release();
          }
        }

        await mutex.waitForUnlock();

        return fetchRequest(requestParams);
      }

      return Promise.reject(response);
    })
    .then((res) => {
      const _cb = (response) => {
        setTokens(response);

        if (shouldCallCallbacks && sccb) {
          sccb(response, extraData);
        }

        return response;
      };

      return needParseResponse
        ? res[getResponseType(res.headers.get('Content-Type'))]().then(_cb)
        : _cb(res);
    })
    .catch((res) => {
      if (getErrorResponse) {
        getErrorResponse(res);
      }

      completeRequest(requestId, res.status, res.statusText);

      if (res[contentTypes.json.cb]) {
        return res[contentTypes.json.cb]()
          .then((response) => {
            return resolveCatch(
              response,
              res,
              ercb,
              container,
              containerId,
              errorsHandler,
              shouldCallCallbacks,
              retriesCount,
            );
          })
          .catch(() => {
            errorStatusChecker({}, res, { retriesCount });

            if (shouldCallCallbacks && ercb) {
              ercb();
            }
          });
      }

      if (!isTestEnv) {
        // eslint-disable-next-line no-console
        console.warn(`Has warn: ${res}`);
      }

      return res;
    });
};

export function doLogout() {
  return fetchRequest({
    url: '/logout',
    sccb: doLogoutRoutine,
  });
}

export async function updateToken() {
  const response = await fetchRequest({
    url: '/token/refresh',
    method: 'POST',
    payload: { refresh_token: getRefreshToken() },
  });

  if (!response.isAuthenticated) {
    doLogoutRoutine();
  }

  return response;
}
