{isMobile} = require "lib/mobile"
{KEY, uniq} = require "lib/helpers"
{isPreventSubmitOnEnter} = require "lib/DOM"

###
    Global events collector.

    consumers =
        <node id>:
            destory: (ev) ->
                Cleanup condition. When it's `true` this key will be cleaned up.

            handler: (ev) ->
                Evaluate this function when global event will be triggered.
###
consumers = {}

handler = (ev) ->
    for own id, method of consumers

        if method.clean(ev, id) is true
            delete consumers[id]
        else
            method.handler ev

# Describe your own global listeners.
jQuery(document.body).on "click", handler


module.exports =
    onEsc: (jnode, handler) -> jnode.on "keyup", (ev) ->
        handler() if ev.which is KEY.ESC

    onEnter: (node, handler) ->
        node.on "keyup.enter", (ev) ->
            handler ev if isPreventSubmitOnEnter(ev)

    offEnter: (node) ->
        node.off "keyup.enter"

    onDblClick: (container, selector, handler, opts={}) ->
        # TODO: if isMobile() then "click" else "dblclick"
        # is a bad logic which has already caused multi-binding bugs that I've fixed.
        # Rework this, leave only `dblclick` event assignment here and
        # assign `click` event manually everywhere where it should be!

        event_name = if isMobile() then "click" else "dblclick"
        container.on event_name, selector, handler

    # Use this function to remove binding assigned by onDblClick()
    # Do not just use container.off("dblclick"), since onDblClick() contains en extra logic
    # (which is also deprecated an must be removed)
    offDblClick: (container, selector) ->
        container.off if isMobile() then "click" else "dblclick"


    preventTab: (jnode, tabHandler) ->
        return if (input = jnode[0]) is undefined
        input.addEventListener "keydown", (e) ->
            if e.which is KEY.TAB
                tabHandler e if tabHandler
                e.preventDefault()

        undefined

    onGlobalClick: (jnode, handler) ->
        jid = jnode.attr "id"

        unless jid
            jid = "Z" + uniq()
            jnode.attr "id", jid

        consumers[jid] =
            clean: (ev, id) -> !document.getElementById id
            handler: (ev) ->
                if jQuery(ev.target).parents("##{jid}").length is 0
                    handler ev
