export const FORMAT = {
  DATE_SIMPLE: 'dd.MM.yyyy',
  DATE_SIMPLE_MINUTES: 'dd.MM.yyyy HH:mm',
  DATE_SIMPLE_MINUTES_SECONDS: 'dd-MM-yyyy HH:mm:ss',
  DATE_EXT: 'dd MMMM yyyy',
  DATE_FULL: 'dd MMM yyyy',
  DATE_TIME_FULL: 'dd MMM yyyy HH:mm',
  MONTH_NAME_FULL: 'MMMM',
  DATE_WITHOUT_YEAR: 'dd MMMM',
  DATE_WITHOUT_DAY: 'MM/yyyy',
  MONTH_YEAR_STANDALONE: 'LLLL yyyy',
  DAY_NAME_FULL: 'eeee',
  CHART: {
    LABEL_DAY_OF_MONTH: 'dd',
  },
};

export const WORK_DAY_HOUR_START = 7;
export const WORK_DAY_HOUR_END = 21;

export const DAYS_IN_WEEK = 7;

export const DEFAULT_TIME_FORMAT = '24H';

export const DAY_NAME = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
};

// Keep these days synced with backend ones.
export const DAYS = {
  [DAY_NAME.SUNDAY]: 0,
  [DAY_NAME.MONDAY]: 1,
  [DAY_NAME.TUESDAY]: 2,
  [DAY_NAME.WEDNESDAY]: 3,
  [DAY_NAME.THURSDAY]: 4,
  [DAY_NAME.FRIDAY]: 5,
  [DAY_NAME.SATURDAY]: 6,
} as const;

export const RANGE_TYPE = {
  ANY: 'ANY',
  HUNDRED_YEARS: 'HUNDRED_YEARS',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  CURRENT_WEEK: 'CURRENT_WEEK',
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_24_WEEKS: 'LAST_24_WEEKS',
  LAST_24_MONTHS: 'LAST_24_MONTHS',
} as const;
