import { EARNING_EVENT } from '@@constants/earnings';
import { INSTANCE_TYPE } from '@@constants/instanceTypes';

export const FIELD_NAMES = {
  COMMISSION_TYPE: 'commissionType',
  EVENT: 'event',
  FORMULAS: 'formulas',
  TYPE_CONDITIONS_IDS: 'typeConditionsIds',
  STATUS_CONDITIONS_IDS: 'statusConditionsIds',
  COMMENT: 'comment',
  RULE_SETTINGS: 'ruleSettings',
  URGENCY: 'urgency',
  CALCULATION_TIME: 'calculationTime',
  CHECK_TYPE: 'checkType',
  REFUND_TYPE: 'refundType',
};

export const RULE_SETTINGS = {
  0: 0,
  1: 1,
  2: 10,
  3: 11,
  4: 20,
  5: 30,
  6: 12,
};

export const CHECK_CONDITION = {
  EQ: 0, // ==
  NE: 1, // !=
  GT: 2, // >
  GE: 3, // >=
  LT: 4, // <
  LE: 5, // <=
};

export const WIDGET_TYPE = {
  TYPE: 0,
  TERM: 1,
  PAYMENT_TRIGGER: 2,
  RULES: 3,
  BONUS: 4,
  REFUND_CALCULATOR: 5,
  DOCUMENT_DELETED: 6,
  DOCUMENT_REOPENED: 7,
  DOCUMENT_OR_ITEM_DELETED: 8,
  ONCE_TRIGGER: 9,
  COST_PRICE: 10,
  DOCUMENT_REFUNDED: 11,
  COMMISSIONS: 12,
};

const ORDER_FIELDS_CONFIG = [
  FIELD_NAMES.TYPE_CONDITIONS_IDS,
  FIELD_NAMES.STATUS_CONDITIONS_IDS,
  FIELD_NAMES.URGENCY,
  FIELD_NAMES.CALCULATION_TIME,
  FIELD_NAMES.COMMENT,
];

const SALE_FIELDS_CONFIG = [FIELD_NAMES.COMMENT];

const TASK_CREATED_FIELDS_CONFIG = [FIELD_NAMES.COMMENT];

const TASK_COMPLETED_FIELDS_CONFIG = [FIELD_NAMES.URGENCY, FIELD_NAMES.COMMENT];

const LEAD_FIELDS_CONFIG = [
  FIELD_NAMES.TYPE_CONDITIONS_IDS,
  FIELD_NAMES.STATUS_CONDITIONS_IDS,
  FIELD_NAMES.URGENCY,
  FIELD_NAMES.CALCULATION_TIME,
  FIELD_NAMES.COMMENT,
];

const REFUND_CREATED_FIELDS_CONFIG = [FIELD_NAMES.COMMENT];

const REFUND_FIELDS_CONFIG = [FIELD_NAMES.REFUND_TYPE, FIELD_NAMES.COMMENT];

export const FIELDS_CONFIG = {
  // Order
  [EARNING_EVENT.ORDER_CREATED]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.ORDER_CLOSED]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.TECHNICIAN_FOR_WORK_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.TECHNICIAN_FOR_DEDUCTED_MATERIAL_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.TECHNICIAN_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]:
    ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.TECHNICIAN_FOR_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.MANAGER_FOR_WORK_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.MANAGER_FOR_DEDUCTED_MATERIAL_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]:
    ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.MANAGER_FOR_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.LEAD_MANAGER_FOR_WORK_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.LEAD_MANAGER_FOR_DEDUCTED_MATERIAL_ORDER]: ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.LEAD_MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]:
    ORDER_FIELDS_CONFIG,
  [EARNING_EVENT.LEAD_MANAGER_FOR_ORDER]: ORDER_FIELDS_CONFIG,

  // Sale
  [EARNING_EVENT.SALE_CREATED]: SALE_FIELDS_CONFIG,
  [EARNING_EVENT.SELLER_WORK_SERVICE]: SALE_FIELDS_CONFIG,
  [EARNING_EVENT.SELLER_MATERIAL]: SALE_FIELDS_CONFIG,
  [EARNING_EVENT.LEAD_MANAGER_FOR_SALE]: SALE_FIELDS_CONFIG,

  // Task
  [EARNING_EVENT.TASK_CREATED]: TASK_CREATED_FIELDS_CONFIG,
  [EARNING_EVENT.TASK_COMPLETED]: TASK_COMPLETED_FIELDS_CONFIG,

  // Lead
  [EARNING_EVENT.LEAD_CREATED]: LEAD_FIELDS_CONFIG,
  [EARNING_EVENT.LEAD_CLOSED]: LEAD_FIELDS_CONFIG,

  // Refund
  [EARNING_EVENT.REFUND_CREATED]: REFUND_CREATED_FIELDS_CONFIG,
  [EARNING_EVENT.ORDER_MANAGER_FOR_REFUND]: REFUND_FIELDS_CONFIG,
  [EARNING_EVENT.ORDER_EMPLOYEE_FOR_REFUND]: REFUND_FIELDS_CONFIG,
  [EARNING_EVENT.ORDER_ENGINEER_FOR_REFUND]: REFUND_FIELDS_CONFIG,
  [EARNING_EVENT.SELLER_FOR_REFUND]: REFUND_FIELDS_CONFIG,
};

export const FORM_CONFIG = {
  // Order
  [EARNING_EVENT.ORDER_CREATED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.ORDER_CLOSED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: true,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: true,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_WORK_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: true,
    [WIDGET_TYPE.COMMISSIONS]: true,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_DEDUCTED_MATERIAL_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: true,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.MANAGER_FOR_WORK_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: true,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.MANAGER_FOR_DEDUCTED_MATERIAL_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.MANAGER_FOR_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_WORK_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: true,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_DEDUCTED_MATERIAL_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_ORDER]: {
    INSTANCE_TYPE: INSTANCE_TYPE.ORDER,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },

  // Sale
  [EARNING_EVENT.SALE_CREATED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.SALE,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.SELLER_WORK_SERVICE]: {
    INSTANCE_TYPE: INSTANCE_TYPE.SALE,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: true,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.SELLER_MATERIAL]: {
    INSTANCE_TYPE: INSTANCE_TYPE.SALE,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: false,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: true,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: true,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_SALE]: {
    INSTANCE_TYPE: INSTANCE_TYPE.SALE,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: true,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },

  // Task
  [EARNING_EVENT.TASK_CREATED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.TASK,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: true,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.TASK_COMPLETED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.TASK,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: true,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: true,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },

  // Lead
  [EARNING_EVENT.LEAD_CREATED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.LEAD,
    CLOSED_STATUSES: false,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: true,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.LEAD_CLOSED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.LEAD,
    CLOSED_STATUSES: true,
    [WIDGET_TYPE.TYPE]: true,
    [WIDGET_TYPE.TERM]: true,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: true,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: true,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: true,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: true,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },

  // Refund
  [EARNING_EVENT.REFUND_CREATED]: {
    INSTANCE_TYPE: INSTANCE_TYPE.REFUND,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: true,
    [WIDGET_TYPE.REFUND_CALCULATOR]: false,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: false,
  },
  [EARNING_EVENT.ORDER_MANAGER_FOR_REFUND]: {
    INSTANCE_TYPE: INSTANCE_TYPE.REFUND,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: true,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: true,
  },
  [EARNING_EVENT.ORDER_EMPLOYEE_FOR_REFUND]: {
    INSTANCE_TYPE: INSTANCE_TYPE.REFUND,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: true,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: true,
  },
  [EARNING_EVENT.ORDER_ENGINEER_FOR_REFUND]: {
    INSTANCE_TYPE: INSTANCE_TYPE.REFUND,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: true,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: true,
  },
  [EARNING_EVENT.SELLER_FOR_REFUND]: {
    INSTANCE_TYPE: INSTANCE_TYPE.REFUND,
    [WIDGET_TYPE.TYPE]: false,
    [WIDGET_TYPE.TERM]: false,
    [WIDGET_TYPE.PAYMENT_TRIGGER]: false,
    [WIDGET_TYPE.RULES]: false,
    [WIDGET_TYPE.BONUS]: false,
    [WIDGET_TYPE.REFUND_CALCULATOR]: true,
    [WIDGET_TYPE.DOCUMENT_DELETED]: true,
    [WIDGET_TYPE.DOCUMENT_REOPENED]: false,
    [WIDGET_TYPE.DOCUMENT_OR_ITEM_DELETED]: false,
    [WIDGET_TYPE.ONCE_TRIGGER]: false,
    [WIDGET_TYPE.COST_PRICE]: false,
    [WIDGET_TYPE.COMMISSIONS]: false,
    [WIDGET_TYPE.DOCUMENT_REFUNDED]: true,
  },
};

export const VALIDATION_TYPES = {
  REQUIRED: 'required',
};

export const MIN_AMOUNT = -999999999;
export const MAX_AMOUNT = 999999999;
