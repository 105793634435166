###
Client fields business-logic.
###


# System fields names.
FIELD_NAME =
    NAME: "name"
    EMAIL: "email"
    PHONE: "phone"
    NOTES: "notes"
    AD_CAMPAIGN: "ad_campaign_id"
    ADDRESS: "address"

CLIENT_TYPE =
    USER: 'user'
    COMPANY: 'company'


module.exports = {
    FIELD_NAME
    CLIENT_TYPE
}
