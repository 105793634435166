###
    TODO: migrate local-storage.js interface to this file.
###

store = require "store"

{apply, assocIn} = require "lib/fp"
{bool, first} = require "lib/helpers"


# Cleanup local storage with the `OLD` keys.
OLD = ["ls-o-autoprint"]

EXPIRED = ["ro.ordersMyOrders", "ro.ordersFilters"]

PREFIX =
    NODE: "ls"

KEY =
    GA: "ro.ga" # Store Google Analytics events for further processing.
    YA: "ro.ya" # Store Yandex Metrika events  for further processing.
    CASHBOX: "ro.cashbox" # Active cashbox.

    FILTERS: "or.filters"

    INPUTS: "ro.inputs" # Hidden inputs stored states.
    TOGGLERS: "ro.togglers" # Buttons with the stored states.
    DROPDOWNS: "ro.dropdowns" # Dropdowns stored states.
    CHECKBOXES: "ro.checkboxes" # Checkboxes stored states.
    BTN_GROUPS: "ro.btnGroups" # Button groups stored states.

    REFERER: "ro.referer"
    SORTING: "ro.sorting"
    ORDERING: "ro.ordering" # Contains sorted array with ids.
    DATE_SELECTORS: "ro.dateSelectors" # DateSelectors stored states.

    DND_TREES: "ro.dndTrees" # Draggable tree table stored states.
    SEARCHABLE_DP: "ro.searchableDP"

    # Split orders config for a better performance.
    COLUMNS: "ro.ordersColumns"

    # Split invoices config for a better performance.
    INVOICES_COLUMNS: "ro.invoicesColumns"

    # Split clients config for a better performance.
    CLIENTS_COLUMNS: "ro.clientsColumns"

    STICKER_SIZE: "ro.stickerSize"

    WORK_SCHEDULE_SETTINGS: 'workScheduleSettings'

    PAYROLL_CALCULATION_DATE_FILTER: 'payrollCalculationDateFilter'


remove = (key) -> store.remove key

set = (key, val) ->
    try
        store.set key, val
    catch e
        # Trying to rewrite after complete cleaning.
        remove key
        store.set key, val

get = (key, _default, isStrongReturn) ->
    item = store.get key

    # Cleanup broken keys.
    if item is undefined
        remove key
        _default
    else
        if isStrongReturn then item else item or _default

###
    Serialized structure:
        => [{id: <node_id>, state: <node_value>}, ...]
###
update = (key, val) ->
    old = get key, []
    stored = old.filter ({id}) -> id not in OLD

    # Find stored item by the id.
    idx = stored.indexOf first stored.filter ({id}) -> id is val.id

    # Update item if it exists or concat a new one.
    data = if idx < 0 then stored.concat val else assocIn stored, [idx, "state"], val.state

    set key, data

# Observe hidden inputs. Saves the state if it changes.
inputs = (container) ->
    container.on "change", "input[id^=#{PREFIX.NODE}][type=hidden]", (ev) ->
        jnode = jQuery ev.currentTarget
        state = jnode.val()
        update KEY.INPUTS, {id: jnode.attr('id'), state: state}

# Observe togglable buttons. Saves the state if it changes.
togglers = (container) ->
    container.on "click", "button[id^=#{PREFIX.NODE}]", (ev) ->
        id = ev.currentTarget.getAttribute 'id'
        stored = getStateOf id, false

        update KEY.TOGGLERS, {
            id: id
            state: not stored
        }

# Observe button groups wich are acting like radio selectors
btnGroups = (container) ->
    container.on "click", "[class^=#{PREFIX.NODE}] button", (ev) ->
        id = ev.currentTarget.parentNode.getAttribute "data-storage-key"
        update KEY.BTN_GROUPS, {
            id: id
            state: ev.currentTarget.getAttribute "data-storage-value"
        }

# Observe container checkboxes. Saves the state if it changes.
checkboxes = (container) ->
    container.on "click", "input[id^=#{PREFIX.NODE}][type=checkbox]", (ev) ->
        jnode = jQuery ev.currentTarget
        state = jnode.is ":checked"
        update KEY.CHECKBOXES, {id: jnode.attr('id'), state: state}

# Observe container dropdowns. Saves the state if it changes.
dropdowns = (container) ->
    container.on "change", "select[id^=#{PREFIX.NODE}]", (ev) ->
        jnode = jQuery ev.currentTarget
        state = jnode.val()
        update KEY.DROPDOWNS, {id: jnode.attr('id'), state: state}

observe = (container) -> apply inputs, togglers, checkboxes, dropdowns, btnGroups, container

# Iterate over stored values and find state of the item with the `id`.
getStateOf = (id, _default) ->
    for own _, key of KEY
        for o in get key, [] when id is o.id
            return o.state

    _default

###
    Concat stored array items.
    Example:
        => concat KEY.GA, ['send', 'event', 'auth', 'sign-in']
            [<stored>, <params>]
###
concat = (key, params) ->
    val = (get key, []).concat [params]
    set key, val if bool val


# Cleanup LocalStorage.
EXPIRED.map remove


getSorting = (gridId) ->
    sorting = get KEY.SORTING, []
    sorting = first sorting.filter ({id}) -> id is gridId
    return {} unless bool sorting

    sorting.state

saveSorting = (gridId, sorted) ->
    return null if sorted is undefined

    {field, dir} = (first sorted) or {}
    update KEY.SORTING, {id: gridId, state: {field, dir}}

ORDERING =
    get: (orderingId) ->
        ordering = get KEY.ORDERING, []
        ordering = first ordering.filter ({id}) -> id is orderingId
        return [] unless bool ordering

        ordering.state

    set: (orderingId, ordering) ->
        return null if ordering is undefined

        update KEY.ORDERING, {id: orderingId, state: ordering}

STICKER_SIZE =
    get: -> get KEY.STICKER_SIZE, {}

    set: (size) ->
        return null if size is undefined

        {width, height} = size
        return null if width is undefined  or height is undefined

        set KEY.STICKER_SIZE, size



module.exports = {
    KEY, ORDERING, STICKER_SIZE, remove, set, get, observe, getStateOf, concat, update,
    getSorting, saveSorting
}
