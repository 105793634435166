{
    now, startOfWeek, startOfMonth,
    nDaysFromNow, nMonthsFromNow, addYears,
    startOfDay, endOfDay, yesterdayStart, yesterdayEnd,
    lastWeekStart, lastWeekEnd, lastMonthStart, lastMonthEnd,
    last30Days, last24Weeks, last24Months,
    addYears, zonedTimeToUtc
} = require "@@helpers/format/date"


module.exports = ->
    TS_NOW = now()
    TS_TODAY_END = endOfDay()

    {
        ANY:
            range: [zonedTimeToUtc(0), TS_TODAY_END]
            title: () -> __("All time")
        HUNDRED_YEARS:
            range: [zonedTimeToUtc(0), addYears 100]
            title: () -> __("All time")
        TODAY:
            range: [startOfDay(), TS_TODAY_END]
            title: () -> __("Today")
        YESTERDAY:
            range: [yesterdayStart(), yesterdayEnd()]
            title: () -> __("Yesterday")
        CURRENT_WEEK:
            range: [startOfWeek(), TS_TODAY_END]
            title: () -> __("Past week")
        CURRENT_MONTH:
            range: [startOfMonth(), TS_TODAY_END]
            title: () -> __("This month")
        LAST_WEEK:
            range: [lastWeekStart(), lastWeekEnd()]
            title: () -> __("Last week")
        LAST_MONTH:
            range: [lastMonthStart(), lastMonthEnd()]
            title: () -> __("Last month")
        WEEK:
            range: [TS_NOW, nDaysFromNow 7]
            title: () -> __("7 days")
        TWO_WEEKS:
            range: [TS_NOW, nDaysFromNow 14]
            title: () -> __("14 days")
        MONTH:
            range: [TS_NOW, nMonthsFromNow 1]
            title: () -> __("1 month")
        TWO_MONTHS:
            range: [TS_NOW, nMonthsFromNow 2]
            title: () -> __("2 months")
        THREE_MONTHS:
            range: [TS_NOW, nMonthsFromNow 3]
            title: () -> __("3 months")
        FOUR_MONTHS:
            range: [TS_NOW, nMonthsFromNow 4]
            title: () -> __("4 months")
        FIVE_MONTHS:
            range: [TS_NOW, nMonthsFromNow 5]
            title: () -> __("5 months")
        SIX_MONTHS:
            range: [TS_NOW, nMonthsFromNow 6]
            title: () -> __("6 months")
        YEAR:
            range: [TS_NOW, addYears(now(), 1)]
            title: () -> __("12 months")
        LAST_30_DAYS: 
            range: [last30Days(), TS_NOW]
            title: () -> __("last 30 days")
        LAST_24_WEEKS: 
            range: [last24Weeks(), TS_NOW]
            title: () -> __("last 24 weeks")
        LAST_24_MONTHS: 
            range: [last24Months(), TS_NOW]
            title: () -> __("last 24 months")
    }