import { WarehouseEntityTypes } from '@@types/warehouse';

export enum UomType {
  QUANTITY = 1,
  LENGTH = 2,
  MASS = 3,
  AREA = 4,
  VOLUME = 5,
  TIME = 6,
}

// uom - units of measurement
export interface Uom {
  id: number;
  uom_type: UomType;
  name: string;
  description: string;
  is_imperial: boolean;
  is_system: boolean;
  title: string;
  entity_types: WarehouseEntityTypes[];
}
