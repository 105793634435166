# Fields cache shared lib.

{error} = require "lib/logger"

# Current package.
{TOrderField} = orderfields = require "lib/forms/order"
{TLeadField} = leadfields = require "lib/forms/lead"
{TClientField} = clientfields = require "lib/forms/client"
{TAssetField} = assetfields = require "lib/forms/assets"
{TProductField} = productfields = require "lib/forms/products"


reCache = ({entity_name}, x) ->
    switch entity_name
        when "juridical"    then clientfields.reCache new TClientField x
        when "ordertype_id" then orderfields.reCache new TOrderField x
        when "leadtype_id" then leadfields.reCache new TLeadField x
        when "asset" then assetfields.reCache new TAssetField x
        when "product" then productfields.reCache new TProductField x
        else error "Unknown entity_name"

    undefined


initCache = ({entity_name}, array) ->
    switch entity_name
        when "juridical"    then clientfields.initCache array
        when "ordertype_id" then orderfields.initCache array
        when "leadtype_id" then leadfields.initCache array
        when "asset" then assetfields.initCache array
        when "product" then productfields.initCache array
        else error "Unknown entity_name"

    undefined


module.exports = {
    initCache
    reCache
}
