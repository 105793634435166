import _every from 'lodash/every';
import _isEqual from 'lodash/isEqual';
import _uniqueId from 'lodash/uniqueId';
import _values from 'lodash/values';

import {
  DEFAULT_BACKOFF_TIME,
  DEFAULT_RETRIES_COUNT,
  JITTER_DELAY_BOUNDARIES,
  RETRY_STATUS_CODES,
} from '@@constants/transport';
import { sleep } from '@@helpers/common/sleep';

/**
 * @type {number} timeout - used for delete completed request.
 * @default 1 min.
 */
const CLEAR_TIMEOUT = 1000 * 60; // 1-min timer timeout for clean completed request.

/**
 * Store the object of request information.
 * @description store object
 * @param {string} method - request function name ['rpc', 'legacy']
 * @param {object} params - original request parameters.
 * @param {number} startTime - timestamp of register request time.
 * @param {number} status - request status code.
 * @param {string} statusText - request status text.
 * @param {boolean} completed - is request currently completed.
 * @param {number} completedTime - timestamp of complete request.
 */
let store = {};

/**
 * Add request to store.
 * @param {string} method - request function name ['rpc', 'legacy']
 * @param {object} params - original request parameters.
 * @returns {string} id - request unique id.
 */
export const registerRequest = (method, params = {}) => {
  const id = _uniqueId();

  store[id] = {
    id,
    method,
    params,
    startTime: Date.now(),
  };

  return id;
};

/**
 * Return request object.
 * @param {string} id - unique request id;
 * @returns {object/undefined} - request object if placed  in store;
 */
export const getRequest = (id) => {
  return store[id];
};

export const removeRequest = (id) => {
  const { [id]: deletedRequest, ...newStore } = store;
  store = newStore;
};

/**
 * We don't need store all requests for all time.
 * Once at a specified time, deletes the completed request from the store.
 */
const clearStorage = () => {
  if (_every(store, ({ completed }) => completed)) {
    store = {};
  }
};

const setClearStoreTimer = () => {
  setInterval(clearStorage, CLEAR_TIMEOUT);
};

setClearStoreTimer();
export const isDuplicatedRequest = (requestId) => {
  const request = getRequest(requestId);
  const duplicated = _values(store).find(({ id, params: { url } }) => {
    return requestId !== id && request.params.url === url;
  });

  if (duplicated) {
    return (
      _isEqual(duplicated.params.payload, request.params.payload) &&
      !duplicated.completed &&
      duplicated.startTime - request.startTime < 1000
    );
  }

  return false;
};

/**
 * Set request to completed status.
 * @param {string} id - unique request id;
 * @param {number} status - request status code.
 * @param {string} statusText - request status text.
 * @returns {undefined/void}
 */
export const completeRequest = (id, status, statusText) => {
  if (!store[id]) {
    return;
  }

  store[id] = {
    ...store[id],
    status,
    statusText,
    completed: true,
    completedTime: Date.now(),
  };
};

// TODO: Wait for previous promise resolving
/**
 * Repeats async call with specified count and delay.
 * @param {Function} requestFunction - request promise based function
 * @param {Object} requestOptions - request parameters
 * @param {Object} response - api response data
 * @param {Number} retriesCount - retries count to execute
 * @param {Number} backoff - delay to the next retry execution
 * @returns {void}
 */
export const retry = async (
  requestFunction,
  requestOptions,
  response,
  retriesCount = DEFAULT_RETRIES_COUNT,
  backoff = DEFAULT_BACKOFF_TIME,
) => {
  if (retriesCount > 0 && RETRY_STATUS_CODES.includes(response.status)) {
    const [minDelay, maxDelay] = JITTER_DELAY_BOUNDARIES;

    const min = Math.ceil(minDelay);
    const max = Math.floor(maxDelay);

    // Jitter will result in a random value between `minDelay` and `maxDelay`.
    // Prevents users retry at the same time.
    // See https://www.awsarchitectureblog.com/2015/03/backoff.html
    const jitterTime = Math.floor(Math.random() * (max - min + 1)) + min;

    await sleep(backoff + jitterTime);

    requestFunction({
      ...requestOptions,
      retriesCount: retriesCount - 1,
      backoff: backoff * 2,
      shouldCallCallbacks: false,
    });
  }
};
