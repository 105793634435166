import { createSlice } from '@reduxjs/toolkit';

export const initialState = {};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResources(state, { payload }) {
      const { data, branchId } = payload;

      state[branchId] = data;
    },
  },
});

export const { setResources } = resourcesSlice.actions;

export default resourcesSlice.reducer;
