import {
  init,
  reactRouterV5Instrumentation,
  replayIntegration,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

export const initSentry = () => {
  const history = createBrowserHistory();

  if (!process.env.SENTRY_DSN) {
    return;
  }

  init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.VERSION,
    integrations: [
      replayIntegration(),
      new BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
    ],
    // Visible redux state context depth
    normalizeDepth: 5,
    // Capture Replay for 0% of all sessions,
    // plus for 10% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.5,
  });
};
