import SvgCancelThin from '@@assets/images/icons/cancel_thin.svg';
import SvgCheckmarkRound from '@@assets/images/icons/checkmark_round.svg';
import SvgCheckmarkThin from '@@assets/images/icons/checkmark_thin.svg';

import { OBJECT_TYPE } from '@@constants/context';
import { ICON_FILL } from '@@constants/images';
import { WAREHOUSE_TYPE } from '@@constants/warehouse';

const ACCESS_LVL = {
  NONE: 0,
  DEFAULT: 10,
  CUSTOM: 20,
  FULL: 30,
};

const CASHBOX_PERMISSION = {
  MAKE_TRANSFER_IN: 0,
  MAKE_TRANSFER_OUT: 1,
  MAKE_INCOME: 2,
  MAKE_OUTCOME: 3,
  VIEW_BALANCE: 4,
  VIEW_CASHFLOW: 5,
};

const PRODUCTS_WAREHOUSE_PERMISSION = {
  VIEW_STOCK: 0,
  MAKE_TRANSFER_IN: 1,
  MAKE_TRANSFER_OUT: 2,
  MAKE_INCOME: 3,
  MAKE_OUTCOME: 4,
  MAKE_RETURN: 5,
  MAKE_RETURN_SUPPLIER: 6,
  ADD_TO_ORDER: 7,
  ADD_TO_SALE: 8,
};

const ASSETS_WAREHOUSE_PERMISSION = {
  VIEW_STOCK: 0,
  MAKE_TRANSFER_IN: 1,
  MAKE_TRANSFER_OUT: 2,
  MAKE_INCOME: 3,
  MAKE_OUTCOME: 4,
  ADD_TO_ORDER: 5,
};

const CASHBOX_ACCESS_LVL_LABELS = {
  [ACCESS_LVL.NONE]: __('No access'),
  [ACCESS_LVL.DEFAULT]: __('Available for cashbox'),
  [ACCESS_LVL.CUSTOM]: null,
  [ACCESS_LVL.FULL]: __('All'),
};

const WAREHOUSE_ACCESS_LVL_LABELS = {
  [ACCESS_LVL.NONE]: __('No access'),
  [ACCESS_LVL.DEFAULT]: __('Available for warehouse'),
  [ACCESS_LVL.CUSTOM]: null,
  [ACCESS_LVL.FULL]: __('All'),
};

const CASHBOX_PERMISSIONS_LABELS = {
  [CASHBOX_PERMISSION.MAKE_TRANSFER_IN]: __('Transfer to the cashbox'),
  [CASHBOX_PERMISSION.MAKE_TRANSFER_OUT]: __('Transfer from the cashbox'),
  [CASHBOX_PERMISSION.MAKE_INCOME]: __('Income'),
  [CASHBOX_PERMISSION.MAKE_OUTCOME]: __('v1 Expense'),
  [CASHBOX_PERMISSION.VIEW_BALANCE]: __('View balance'),
  [CASHBOX_PERMISSION.VIEW_CASHFLOW]: __('View money flow'),
};

const PRODUCTS_WAREHOUSE_PERMISSIONS_LABELS = {
  [PRODUCTS_WAREHOUSE_PERMISSION.VIEW_STOCK]: __('View stock'),
  [PRODUCTS_WAREHOUSE_PERMISSION.MAKE_TRANSFER_IN]: __(
    'Transfer to the warehouse',
  ),
  [PRODUCTS_WAREHOUSE_PERMISSION.MAKE_TRANSFER_OUT]: __(
    'Transfer from the warehouse',
  ),
  [PRODUCTS_WAREHOUSE_PERMISSION.MAKE_INCOME]: __('Posting'),
  [PRODUCTS_WAREHOUSE_PERMISSION.MAKE_OUTCOME]: __('Write-off'),
  [PRODUCTS_WAREHOUSE_PERMISSION.MAKE_RETURN]: __('Return from client'),
  [PRODUCTS_WAREHOUSE_PERMISSION.MAKE_RETURN_SUPPLIER]: __('Return purchase'),
  [PRODUCTS_WAREHOUSE_PERMISSION.ADD_TO_ORDER]: __('Adding to order'),
  [PRODUCTS_WAREHOUSE_PERMISSION.ADD_TO_SALE]: __('Adding to sale'),
};

const ASSET_WAREHOUSE_PERMISSION_LABELS = {
  [ASSETS_WAREHOUSE_PERMISSION.VIEW_STOCK]: __('View stock'),
  [ASSETS_WAREHOUSE_PERMISSION.MAKE_TRANSFER_IN]: __(
    'Transfer to the warehouse',
  ),
  [ASSETS_WAREHOUSE_PERMISSION.MAKE_TRANSFER_OUT]: __(
    'Transfer from the warehouse',
  ),
  [ASSETS_WAREHOUSE_PERMISSION.MAKE_INCOME]: __('Create'),
  [ASSETS_WAREHOUSE_PERMISSION.MAKE_OUTCOME]: __('Write-off'),
  [ASSETS_WAREHOUSE_PERMISSION.ADD_TO_ORDER]: __('Adding to order'),
};

const ACCESS_LVL_ICONS = {
  [ACCESS_LVL.NONE]: {
    image: SvgCancelThin,
    fill: ICON_FILL.RED_100,
  },
  [ACCESS_LVL.DEFAULT]: {
    image: SvgCheckmarkThin,
    fill: ICON_FILL.GREEN_100,
  },
  [ACCESS_LVL.CUSTOM]: {
    image: SvgCheckmarkThin,
    fill: ICON_FILL.ORANGE_100,
  },
  [ACCESS_LVL.FULL]: {
    image: SvgCheckmarkRound,
    fill: ICON_FILL.GREEN_100,
  },
};

const PERMISSIONS_EXTRA = {
  BY_TYPE: {
    [OBJECT_TYPE.CASHBOX]: CASHBOX_PERMISSION,
    [OBJECT_TYPE.WAREHOUSE]: {
      [WAREHOUSE_TYPE.PRODUCT]: PRODUCTS_WAREHOUSE_PERMISSION,
      [WAREHOUSE_TYPE.ASSET]: ASSETS_WAREHOUSE_PERMISSION,
    },
  },
  LABELS_BY_TYPE: {
    [OBJECT_TYPE.CASHBOX]: CASHBOX_PERMISSIONS_LABELS,
    [OBJECT_TYPE.WAREHOUSE]: {
      [WAREHOUSE_TYPE.PRODUCT]: PRODUCTS_WAREHOUSE_PERMISSIONS_LABELS,
      [WAREHOUSE_TYPE.ASSET]: ASSET_WAREHOUSE_PERMISSION_LABELS,
    },
  },
  ACCESS_LVL_LABELS: {
    [OBJECT_TYPE.CASHBOX]: CASHBOX_ACCESS_LVL_LABELS,
    [OBJECT_TYPE.WAREHOUSE]: WAREHOUSE_ACCESS_LVL_LABELS,
  },
  CASHBOX: CASHBOX_PERMISSION,
  WAREHOUSE: {
    PRODUCT: PRODUCTS_WAREHOUSE_PERMISSION,
    ASSET: ASSETS_WAREHOUSE_PERMISSION,
  },
  ACCESS_LVL_ICONS,
  ACCESS_LVL,
  OBJECT_TYPE,
  WAREHOUSE_TYPE,
  FIELD_NAME: 'permissions',
};

export default PERMISSIONS_EXTRA;
