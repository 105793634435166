{toast} = require('@@helpers/toast')

{merge} = require "lib/fp"
{onEnter} = require "lib/listener"
{hideErrors} = require "lib/plugins/Validate"
{isDisabled} = require "lib/DOM"


BODY = jQuery document.body

isClosePrevented = (dialog) -> dialog.data("close-prevented") is true

DEFAULT =
    top: 40
    blurbackground: false
    closeonbackgroundclick: true
    isClosePrevented: isClosePrevented
    onShow: ->

TEXT =
    CLOSE_PREVENTED: __("Unable to close the window")


withDialogModule = (cb) ->
    weak = require.resolveWeak "lib/Dialog"
    module = require.cache[weak]

    cb(module.exports) if module

allowClose = (dialog) -> dialog.data "close-prevented", false

preventClose = (dialog) -> dialog.data "close-prevented", true

getClosePreventedMsg = (dialog) ->
    dialog.data("close-prevented-msg") or TEXT.CLOSE_PREVENTED

setClosePreventedMessage = (dialog, msg) -> dialog.data "close-prevented-msg", msg

closeDialog = (dialog) ->
    if dialog and isClosePrevented dialog
        toast.success getClosePreventedMsg dialog
        return undefined

    # XXX: do not hide scroll when dialogs are visible.
    withDialogModule (module) ->
        if module.getVisibleDialogs().length is 0
            BODY.css "overflow", "auto"

    hideErrors BODY
    (jQuery ".b-modal").trigger "reveal:close"

revealDialog = (html, onSuccess, params) ->
    html = jQuery html
    params = merge DEFAULT, params

    # Cleanup opened dialogs.
    # XXX: support dialogs layouts?
    closeDialog()
    withDialogModule -> BODY.css "overflow", "hidden"

    _onSuccess = (args...) ->
        return true if isDisabled html.find ".js-submit"
        onSuccess? args...

    html
        .appendTo BODY
        .reveal params
        .on "reveal:close", -> closeDialog html
        .on "click", ".js-close", -> closeDialog html
        .on "click", ".js-submit", (ev) ->
            ev.preventDefault()
            ev.stopPropagation()
            _onSuccess html

    params.onShow? html

    unless html.is "form"
        onEnter html.find("input"), -> _onSuccess html

    html.find("input:not([readonly]):not(:hidden):first").focus()

    html


module.exports = {
    revealDialog, closeDialog, allowClose, preventClose, setClosePreventedMessage
}
