# Common markup.

classNames = require "classnames"
assign = require "lodash/assign"
omit = require "lodash/omit"

{range, uniq, toInt, toBool, isArray, copy} = require "lib/helpers"
{mergeWith, concat, partial, each} = require "lib/fp"

# Core entities.
{SIZE} = require "lib/forms/fields"


# Partial for element attributes merge.
pMerge = partial mergeWith, concat


# Mapping contains relation
# between field size and char input class name
# (ci means char_input)
ci__size =
    "#{SIZE.F}": "b-in_size_f"
    "#{SIZE.M}": "b-in_size_m"

# Returns markup: input markup.
# -> object opts: HTML-attribs and special opts.
# -> opts: int size: input size.
char_input = (opts) ->
    options = omit opts, ["customClass"]

    input_cls = [
        "b-in"
        "b-in_type_char"
        ci__size[options.size] or ci__size[SIZE.F]
        opts.customClass or ""
    ].join ' '

    ["div", { "class": "b-in-holder" }
        ["input", pMerge(options, { "class": input_cls })]
    ]


# Returns jsonml: number input markup.
# -> object kwargs: custom HTML-attributes.
# -> kwargs: jsonml template: custom template to display at the right of input.
# -> kwargs: bool small: use small version of input?
# -> kwargs: negative_mr: special flag, which improves UX,
#    by adding negative right margin; usable in tables (like FlTable,
#    to make inputs looks pretty with other elements.)
number_input = (kwargs={}) ->
    {template} = kwargs

    small = toBool kwargs.small, false
    attribs = omit kwargs, ["template", "small"]

    ["div"
        {
            "class": [
                "b-in-holder"
                "la-fx fx-aic" if template
            ].join " "
        }
        # Input.
        ["input"
            pMerge(
                attribs
                class: [
                    "b-in"
                    "b-in_type_num#{if small then '-sm' else ''}"
                    "b-in_with_template" if template
                ].join " "
            )
        ]

        # Custom template.
        template
    ]


# See number_input()
small_number_input = (kwargs={}) ->
    number_input assign small: true, kwargs


# Returns jsonml: money input markup.
# -> object opts: custom HTML-attributes.
# -> opts: jsonml template: custom template to display at the right side of input
#    (company CURRENCY_SYMBOL is displayed by default.)
money_input = (opts={}) ->
    # Getting template to display near input.
    {CURRENCY_SYMBOL} = require "interface/Company"
    template =
        if opts.template is undefined # empty string can be passed
            ["div", { "class": "h-c-muted" }, CURRENCY_SYMBOL]
        else opts.template

    root_cls = [
        "b-in-holder"
        "la-fx fx-aic" if template
    ].join " "

    input_cls = [
        "b-in b-in_type_money"
        "h-mr-5" if template
    ].join " "

    ["div", { "class": root_cls }
        # Input.
        ["input"
            pMerge(
                omit opts, ["template"]
                class: input_cls
            )
        ]

        # Custom template.
        template
    ]

time_input = (kwargs={}) ->
    {timeFormat} = kwargs

    attribs = omit kwargs, ["timeFormat"]

    ["div"
        {
            "class": "b-in-holder"
        }
        ["input"
            pMerge(
                attribs
                class: "b-in b-in_type_num#{if timeFormat == '24H' then '-sm' else '-md'}"
            )
        ]
    ]


###
Date input markup.
-> Object* opts: custom attributes.
Returns Object*: markup object.
###
date_input = (opts) ->
    ["div", { "class": "b-in-holder" }
        ["input", pMerge(opts, { "class": "b-in b-in_type_date h-pl-1ico" })]
        ["div", { "class": "b-in-holder__l-pips h-pe-n" }
            ["div", { "class": "i-calendar" }]]]


###
Date range input markup.
-> Object* opts: custom attributes.
Returns Object*: markup object.
###
date_range_input = (opts) ->
    ["div", { "class": "b-in-holder" }
        ["input", pMerge(opts, { "class": "b-in b-in_type_date_range h-pl-1ico" })]
        ["div", { "class": "b-in-holder__l-pips h-pe-n" }
            ["div", { "class": "i-calendar" }]]]


###
Date-time input markup.
-> Object* opts: custom attributes.
Returns Object*: markup object.
###
datetime_input = (opts = {}) ->
    ["div", { "class": "b-in-holder js-datetime-container" }
        ["input", pMerge(omit(opts, ['iconClassname']), { "class": "b-in b-in_type_datetime_#{require('interface/Company').TIME_FORMAT} h-pl-1ico" })]

        ["div", { "class": "b-in-holder__l-pips h-pe-n #{if opts.iconClassname then opts.iconClassname else ''}" }
            ["div", { "class": "i-calendar" }]
        ]
    ]


# Returns jsonml: fake input markup
# Used for displaying 'x' (or similar characters.)
# Refer to project code to see example usages.
# -> kwargs: jsonml text: inner text/jsonml.
# -> kwargs: bool novp: adds `no vertical paddings` style?
fake_input = (kwargs={}) ->
    base_class = [
        "b-in"
        "b-in_type_fake"
        "b-in_type_fake-pv0" if kwargs.novp
    ].join " "

    ["div"
        pMerge kwargs, class: base_class
        kwargs.text
    ]


###
Text area markup.
Returns Object*: markup object.
###
textarea = (opts) ->
    ["div", { "class": "b-in-holder" }
        ["textarea", pMerge(opts, { "class": "b-textarea" })
            opts.text]]


###
Select markup.
-> array options: array of objects used as options.
-> object|array attribs: custom attribs/opts for select.
-> attribs: string: tag: custom tag for select.

Example usages:

1. With map function: select array.map(func), { "name", "test" }

2. With inline options:
select [["option", { "value": "Nirvana" }, "Nirvana"]
        ["option", { "value": "Joy Division" }, "Joy Division"]
        ["option", { "value": "Radiohead" }, "Radiohead"]],
       { "name": "band", "data-test": "test" }

3. With array of attribs:
select array.map(func), [
       { "test": "test", "foo": "bar" }
       { "disabled" } if something ]
Returns Object*: markup object.
###
select = (options_array, attrs={ }) ->
    # Overload: select(array, array)
    # If attribs is an array (programmer uses conditional attributes),
    # then we applying pMerge to first attribs object.
    a = if isArray attrs then do ->
        (arr = copy attrs)[0] = pMerge arr[0], { "class": "b-sel" }; arr
    else
        [pMerge attrs, { "class": "b-sel" }]

    # This is special opts
    # can be passed via attribs.
    tag = attrs.tag or "select"
    width = attrs.width

    ["div", { "class": ["b-sel-holder", width if width].join(' ') }
        [tag].concat(a).concat options_array
    ]


# DEPRECATED: use lib/widgets/checkbox
checkbox_default =

    # string: checkbox label.
    label: ""

    # bool: make it checked by default.
    checked: false

    # string: checkbox selector (used for events binding.)
    sel: ""

    # string: tag used for label
    # (sometimes you may need to specify 'div' instead of 'label'
    # to make checkbox readonly or so.)
    tag: "label"

    # string: label text style (bold, normal)
    label_style: "bold"

# DEPRECATED: use lib/widgets/checkbox
# Checkbox input.
# See check_default for optional args info.
# Returns object: markup object.
checkbox = (opts) ->
    opts = assign {}, checkbox_default, opts
    if opts.id is undefined then opts.id = uniq()
    {id, label, sel, tag, text_style} = opts

    # HACK: removing 'checked' key, if checked is false;
    # this made only for programmer's convenience while working with jsonml.
    if !opts.checked then delete opts.checked else opts.checked = "checked"

    if "checked" of opts
        if opts.checked is true or opts.checked is "checked"
            opts.checked = "checked"
        else
            delete opts['checked']

    ["div", { "class": "b-checkbox" }
        ["input", pMerge opts, { "class": "b-checkbox__box", "type": "checkbox" }]
        [tag, { "for": id
              , "class": "b-checkbox__label
                          b-checkbox__label_style_#{opts.label_style}
                          b-checkbox__label_order_normal
                          #{sel}" }
            "#{label}"
        ]
    ]


# Submit (main) button markup.
# -> string text: button text.
# -> object opts: button customizations.
# Returns markup: button markup.
submit_button = (text, opts) ->
    # Notice that "submit" type
    # is required to perform submission on enter keypress,
    # if button is used within HTML form element.
    ["button", pMerge(opts, { "type": "submit"
                            , "class": "b-btn b-btn_color_blue" })
        text]

create_button = (opts) -> submit_button __("Create"), opts
create_add_button = (opts) -> submit_button __("Add"), opts
update_button = (opts) -> submit_button __("Save"), opts
print_button = (opts) -> submit_button __("Print"), opts
restore_button = (opts) ->
    submit_button(
        ["span", {"class": "la-fx"}
            ["span", "class": "i-w-restore h-mr-10 h-c-white"]
            __("Restore")
        ]
    , opts)


###
Removal button markup.
-> Object* opts: custom attributes.
Returns Object*: markup object.
###
delete_button = (opts) ->
    ["button", pMerge(opts, { "class": "b-btn b-btn_color_red" }), opts.text or __("Delete")]


delete_ico_button = (opts) ->
    ["button", pMerge(opts, { "class": "b-btn b-btn_type_ico b-btn_color_red" })
        ["span", { "class": "i-trash" }]]


###
Cancelation button markup.
-> Object* opts: custom attributes.
Returns Object*: markup object.
###
cancel_button = (opts)->
    text = opts.text or __("Close")

    ["button", pMerge(opts, {
        "type": "button"
        , "class": "b-btn b-btn_color_trans"
    }), text]


###
Stars widget.
-> int n: starts number.
Returns Object*: markup object.
###
stars = (n) ->
    n = toInt n
    MAX_MARK = 5

    ["ul", {"class": "b-stars"}].concat(range(n).map ->
        ["li", {"class": "b-stars__star b-stars__star_state_active"}]
    ).concat(range(MAX_MARK - n).map ->
        ["li", {"class": "b-stars__star"}])


# const: tagobadge style.
TAGOSTYLE =
    GREY:
        class: "b-tags__tag_color_grey"
        close: "b-close_style_std"
    BLUE:
        class: "b-tags__tag_color_blue"
        close: "b-close_style_std"
    RED:
        class: "b-tags__tag_color_red"
        close: "b-close_style_white"
    YELLOW:
        class: "b-tags__tag_color_yellow"
        close: "b-close_style_white"

# Returns markup: `tag` aka `small badge` element markup.
# -> opts: string ns: desired namespace.
# -> opts: string text: inner text.
# -> opts: object icon: inner icon.
# -> opts: bool close: display close.
# -> opts: string sel: selector of tag.
# -> opts: bool clickable: use pointer cursor.
# -> opts: string cursor_cls: cursor class.
tagobadge = (opts={}) ->
    {text, icon, ns} = opts
    style = opts.style or TAGOSTYLE.GREY
    clickable = toBool opts.clickable, false
    close = toBool opts.close, true
    selector = opts.sel or "tag"

    root_cls = [
        "b-tags__tag js-#{ns}-#{selector}"
        "b-tags__tag_type_clickable" if clickable
        style.class
    ].join " "

    close_cls = ["b-close b-close_size_xs", style.close].join " "

    ["div", { "class": root_cls }
        each opts, {}, (a, k, v) -> a["data-#{selector}-#{k}"] = v

        ["div", { "class": "b-tags__tag-text" }, "#{text}"]

        ["div", { "class": "b-tags__tag-close
                            js-#{ns}-#{selector}-close" }
            ["div", { "class": close_cls }]
        ] if close

        do (icon) ->
            return unless icon
            {name, color, text} = icon

            ["div"
                {
                    "class": classNames(
                        "b-tags__tag-icon #{name}"
                        {
                            "b-tags__tag-icon_color_#{color}": color
                        }
                    )
                }
                ["span", { "class": "jsonml-tooltip" }, text] if text
            ] if name
    ]


# Table header used to display title with money; eg: 'Total Sum, UAH'.
# It's recommended to use this variant of title everywhere in project.
# -> string title: title text.
# Returns string: header text.
th_money = (title) ->
    CURR = require("interface/Company").CURRENCY_SYMBOL
    [title, CURR].join ", "


# Renders list of options for employees select.
# -> opts: bool selCurrent(): should current user being selected by default?
# -> opts: bool display(object): should employee being displayed or skipped?
# Returns IMarkup: markup.
employees = (opts={ }) ->
    selCurrent = opts.selCurrent or -> true
    display = opts.display or -> true

    {EMPLOYEES} = require "interface/Branch"
    {EMPLOYEE_ID} = require "interface/User"

    sel_curr = selCurrent()
    EMPLOYEES.map (employee) ->
        {counterparty, id} = employee
        ["option"
            { value: id }
            { "selected" } if sel_curr and id is EMPLOYEE_ID
            "#{counterparty.fullname}"
        ] if display employee


module.exports = {
    pMerge
    char_input
    number_input
    small_number_input
    money_input
    time_input
    date_input
    datetime_input
    date_range_input
    fake_input
    textarea
    select
    checkbox
    create_button
    create_add_button
    restore_button
    update_button
    delete_button
    delete_ico_button
    cancel_button
    print_button
    submit_button
    stars
    th_money
    employees
    tagobadge
    TAGOSTYLE
}
