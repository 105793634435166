export const PAYROLL_CORRECTION_TYPE = {
  BONUS: 0,
  PENALTY: 1,
} as const;

export const SALARY_TYPE = {
  MONTHLY: 0,
  DAILY: 1,
  HOURLY: 2,
} as const;

export const TIMED_SALARY_DAY_TYPE = {
  WORK: 0,
  DAY_OFF: 1,
} as const;

export const CALCULATION_PERIOD = {
  MONTHLY: -1,
  DAILY: 0,
} as const;

export const DAY_OFF_CALCULATION_PERIOD = {
  MONTHLY: 0,
  DAILY: 1,
} as const;

export const DAY_OFF_CALCULATION_TYPE = {
  DAY_COST: 0,
  FIXED_AMOUNT: 1,
} as const;

export const SALARY_HISTORY_TYPE = {
  ORDER: 0,
  CHANGE_ORDER: 1,
  SALE: 2,
  TASK: 3,
  LEAD: 4,
  PENALTY: 5,
  MANUAL_BONUS: 6,
  TIMED: 7,
  TIMED_DAY_OFF: 8,
  REFUND: 9,
} as const;

export const DEFAULT_TAB = 5;
