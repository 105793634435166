/**
 * All legacy files should be removed.
 * */
import 'isomorphic-fetch';

import toast from 'react-hot-toast';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';

import { DEFAULT_RETRIES_COUNT } from '@@constants/transport';
import cookieService from '@@services/cookie';

const DOM = require('lib/DOM');

const isTestEnv = process.env.NODE_ENV === 'test';

export const Methods = {
  get: 'GET',
  post: 'POST',
};

export const contentTypes = {
  json: {
    cb: 'json',
    type: 'application/json',
  },
  blob: {
    cb: 'blob',
    type: 'application/vnd.ms-excel',
  },
  textHtml: {
    cb: 'text',
    type: 'text/html',
  },
  textPlain: {
    cb: 'text',
    type: 'text/plain',
  },
  pdf: {
    cb: 'blob',
    type: 'application/pdf',
  },
  formData: {
    type: 'application/x-www-form-urlencoded',
  },
  file: {
    type: 'multipart/form-data;',
  },
};

export const requestTypes = {
  formData: 'formData',
  json: 'json',
  file: 'file',
};

export const getMetaBranch = () => {
  const weak = isTestEnv
    ? require.resolve('interface/Branch')
    : require.resolveWeak('interface/Branch');
  const findModule = require.cache[weak];

  if (!findModule) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return findModule.exports.ID;
};

export const isValidValue = (v) => !isUndefined(v) && !isNull(v);

export const omitInvalidValues = (payload) => {
  return [...keys(payload)].reduce(
    (acc, key) => ({
      ...acc,
      ...(isValidValue(payload[key]) && { [key]: payload[key] }),
    }),
    {},
  );
};

const isBlob = (item) => item instanceof Blob;

export const requestFormatMethods = {
  mutatePayload: ({ payload }) => {
    const realPayload = { ...payload };

    return omitInvalidValues(realPayload);
  },
  [requestTypes.formData]: {
    contentType: contentTypes.formData.type,
    cb: ({ payload }) => {
      const data = requestFormatMethods.mutatePayload({ payload });
      return new URLSearchParams([
        ...keys(data).reduce((formData, key) => {
          if (isArray(data[key])) {
            data[key].map((item) => formData.append(`${key}[]`, item));
          } else {
            formData.append(key, data[key]);
          }
          return formData;
        }, new FormData()),
      ]);
    },
  },
  [requestTypes.file]: {
    contentType: contentTypes.file.type,
    cb: ({ payload }) => {
      const data = requestFormatMethods.mutatePayload({ payload });
      return keys(data).reduce((formData, key) => {
        if (isArray(data[key])) {
          data[key].map((item) =>
            isBlob(item)
              ? formData.append(`${key}[]`, item, item.name)
              : formData.append(`${key}[]`, item),
          );
        } else if (isBlob(data[key])) {
          formData.append(key, data[key], data[key].name);
        } else {
          formData.append(key, data[key]);
        }
        return formData;
      }, new FormData());
    },
  },
  [requestTypes.json]: {
    contentType: contentTypes.json.type,
    cb: ({ payload }) => {
      return JSON.stringify(requestFormatMethods.mutatePayload({ payload }));
    },
  },
};

export const returnAccEncodedLine = (acc, key, value) => {
  if (
    (!isEmpty(value) || isNumber(value) || isBoolean(value)) &&
    !isNaN(value)
  ) {
    return `${acc + encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
  }

  return acc;
};

/**
 * Build url query from "dict" or list of (key, value) pair
 *
 *
 * Example:
 *      build_query({"field1": "val1", "field2": "val2", "field3": "val3"})
 *
 *      or
 *
 *      build_query([["field1", "val1"], ["field2", "val2"], ["field3", "val3"]])
 *
 *      or
 *
 *      build_query({"field1": ["val1", "val2"]}, {"field2": ["val3", "val4"]})
 *
 * returns:
 *
 *      "field1=val1&field2=val2&field3=val3&"
 *
 */

export const buildQueries = (payload, { isPure } = {}) => {
  let queries = '';

  if (payload) {
    if (isArray(payload)) {
      queries = payload.reduce(
        (acc, next) => returnAccEncodedLine(acc, next[0], next[1]),
        '',
      );
    } else {
      queries = reduce(
        payload,
        (acc, value, key) => {
          if (isArray(value)) {
            return (
              acc +
              value.reduce(
                (str, next) => returnAccEncodedLine(str, `${key}[]`, next),
                '',
              )
            );
          }

          if (_isObject(value)) {
            return returnAccEncodedLine(acc, key, JSON.stringify(value));
          }

          return returnAccEncodedLine(acc, key, value);
        },
        '',
      );
    }
  }

  return queries && isPure ? `?${queries.slice(0, -1)}` : queries;
};

export const transformQueryParams = ({ payload, isGetMethod, flatQueries }) => {
  if (!isGetMethod) {
    return '';
  }
  let queries = buildQueries(payload);
  if (flatQueries) {
    queries += `${flatQueries}&`;
  }

  return queries ? queries.slice(0, -1) : '';
};

export const getContentType = (requestType) => {
  return requestType
    ? requestFormatMethods[requestType].contentType
    : requestFormatMethods.json.contentType;
};

export const getMetaCompanyId = () => {
  const weak = isTestEnv
    ? require.resolve('interface/Company')
    : require.resolveWeak('interface/Company');

  const findModule = require.cache[weak];

  if (!findModule) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return findModule.exports.ID;
};

export const getMetaCompanyLanding = () => {
  const weak = isTestEnv
    ? require.resolve('app/Env/settings')
    : require.resolveWeak('app/Env/settings');

  const findModule = require.cache[weak];

  if (!findModule) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return findModule.exports.LANDING;
};

export const getMetaCompanyNicheCategory = () => {
  const weak = isTestEnv
    ? require.resolve('app/Env/settings')
    : require.resolveWeak('app/Env/settings');

  const findModule = require.cache[weak];

  if (!findModule) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return findModule.exports.NICHE_CATEGORY;
};

export const getHeaders = ({
  requestType,
  withContentType,
  withBranch,
  withCompanyId,
} = {}) => ({
  'X-CSRFToken': cookieService.get('csrftoken'),
  'X-Revision': process.env.REVISION,
  'X-Version': process.env.VERSION,
  ...(withCompanyId && {
    'X-Company-Id': getMetaCompanyId(),
  }),
  ...(withBranch && {
    'X-Branch-Id': getMetaBranch(),
  }),
  'X-Company-Landing': getMetaCompanyLanding(),
  'X-Company-Niche-category': getMetaCompanyNicheCategory(),
  ...(withContentType && {
    'Content-Type': getContentType(requestType),
  }),
});

export const getGetParams = ({ withBranch, withCompanyId }) => ({
  method: Methods.get,
  credentials: 'include',
  headers: getHeaders({ withBranch, withCompanyId }),
});

export const getPostParams = ({
  payload,
  method,
  requestType,
  withBranch,
  withCompanyId,
}) => ({
  method,
  credentials: 'include',
  body: requestFormatMethods[requestType].cb({ payload }),
  headers: getHeaders({
    requestType,
    withBranch,
    withContentType: requestType !== requestTypes.file,
    withCompanyId,
  }),
});

export const getUrl = ({
  url,
  payload,
  isGetMethod,
  flatQueries,
  isApp,
  isStaff,
  isChatService,
}) => {
  const queries = transformQueryParams({ payload, isGetMethod, flatQueries });
  let realUrl = `${url}${isEmpty(queries) ? '' : '?'}${queries}`;

  if (isChatService) {
    realUrl = `${process.env.CHATS_API_BASE_URL}${realUrl}`;
  } else if (isStaff) {
    realUrl = `/app/staff${realUrl}`;
  } else if (isApp) {
    realUrl = `/app${realUrl}`;
  }

  return realUrl;
};

export const errorStatusChecker = (
  { printable, message, location } = {},
  { status, data = {} } = {},
  { retriesCount } = {},
) => {
  if (retriesCount < DEFAULT_RETRIES_COUNT) {
    return;
  }

  if (printable) {
    const weak = isTestEnv
      ? require.resolve('lib/Dialog')
      : require.resolveWeak('lib/Dialog');
    const module = require.cache[weak];

    if (module) {
      const visibleDialogs = module.exports.getVisibleDialogs();
      if (visibleDialogs.length > 0) {
        const topDialog = visibleDialogs.reverse()[0];

        if (topDialog) {
          DOM.enable(topDialog.find('.js-submit-dialog'));
        }
      }
    }

    toast.error(message);
    return;
  }

  if (status === 'redirect') {
    window.location.href = location || data.Location || '/';
    return;
  }

  if (status >= 500) {
    toast.error(__('Error occurred. Please contact technical support'));
    return;
  }

  if (status === 429) {
    toast.error(
      __(
        'Your account has been temporarily locked due to many requests. Please try again later.',
      ),
    );
    return;
  }

  if (status === 405) {
    toast.error(__('Invalid request type. Contact technical support'));
    return;
  }

  if (status === 403) {
    toast.error(__('You have no access to this resource'));
    return;
  }

  if (status === 401) {
    window.location.reload();
    return;
  }

  if (status === 409) {
    window.location.reload();
    return;
  }

  if (status >= 400) {
    if (!(message && message.validation)) {
      toast.error(__('Resource not found. Please contact technical support'));
    }
    return;
  }

  if (status >= 300) {
    window.location.replace(message);
    return;
  }

  if (status > 0) {
    toast.error(__('Something went wrong. Please try again later'));
  }
};

export const responseErrorHandler = {
  revision: () => window.location.reload(),
  redirect: (redirectValue) => {
    if (redirectValue === '__reload__') {
      window.location.reload();
    } else {
      window.location.replace(redirectValue + window.location.search);
    }
  },
  message: (message, response, { container, containerId, errorsHandler }) => {
    const {
      displayErrors,
      findErrors,
      hideErrors,
      hasErrors,
      backendNotifyKey,
    } = require('lib/plugins/Validate');

    const wrapper =
      container ||
      jQuery(
        containerId ? document.getElementById(containerId) : document.body,
      );

    const hasPrintable = response && response.printable;
    const hasValidation = message && message.validation;

    if (hasValidation && hasErrors(wrapper)) {
      hideErrors(wrapper);
    }

    // INF-20. Backward compatibility with the JSON engine.
    const errorMessage = message[''] || message;
    const errorOptions = {
      message: hasPrintable
        ? { validation: { [backendNotifyKey]: errorMessage } }
        : errorMessage,
    };

    if (errorsHandler) {
      errorsHandler(errorOptions);
    } else {
      displayErrors(wrapper, errorOptions);
    }

    if (hasValidation && hasErrors(wrapper)) {
      DOM.focus(findErrors(wrapper));
    }
  },
};

export const getResponseType = (responseType) => {
  if (!responseType.indexOf(contentTypes.json.type)) {
    return contentTypes.json.cb;
  }
  if (!responseType.indexOf(contentTypes.textHtml.type)) {
    return contentTypes.textHtml.cb;
  }
  if (!responseType.indexOf(contentTypes.textPlain.type)) {
    return contentTypes.textPlain.cb;
  }
  if (!responseType.indexOf(contentTypes.blob.type)) {
    return contentTypes.blob.cb;
  }
  if (!responseType.indexOf(contentTypes.pdf.type)) {
    return contentTypes.pdf.cb;
  }

  return contentTypes.json.cb;
};

export const isHttpOkRange = (response) => {
  return response.status >= 200 && response.status < 300;
};

export const resolveCatch = (
  response,
  res,
  ercb,
  container,
  containerId,
  errorsHandler,
  shouldCallCallbacks,
  retriesCount,
) => {
  // Should not display the same error notify more than 1 time
  if (retriesCount < DEFAULT_RETRIES_COUNT) {
    return response;
  }

  const findCustomKey = find(
    keys(response),
    (key) => responseErrorHandler[key],
  );

  if (findCustomKey) {
    responseErrorHandler[findCustomKey](response[findCustomKey], response, {
      container,
      containerId,
      errorsHandler,
    });
  }

  if (shouldCallCallbacks && ercb) {
    ercb(response);
  }

  if (!findCustomKey) {
    errorStatusChecker(response, res, { retriesCount });
  }

  return response;
};
