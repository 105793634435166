export interface AdCampaign {
  id?: number;
  name?: string;
  description?: string;
  branches_ids?: number[];
  start_at?: number;
  finish_at?: number;
}

export enum AdCampaignClientType {
  all = '0',
  new = '1',
  regular = '2',
}
