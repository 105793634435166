// For ios safari < 13.4. We should support. https://remonline.atlassian.net/browse/DEV-5146
import 'resize-observer-polyfill/dist/ResizeObserver.global';

import { memo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { DndProvider } from '@@contexts/DndContext';
import { initSentry } from '@@helpers/log';
import Router from '@@router';
import historyConfig from '@@router/history';

initSentry();

function ModuleApp() {
  return (
    <Provider store={historyConfig.store}>
      <PersistGate persistor={historyConfig.persistor}>
        <DndProvider>
          <Router history={historyConfig.history} />
        </DndProvider>
      </PersistGate>
    </Provider>
  );
}

export default memo(ModuleApp);
