import { createApi } from '@reduxjs/toolkit/query/react';

import { chatServiceInstance } from '@@services/transport/axios';

import type { AxiosBaseQuery, BaseQueryError } from '@@types/api';

const axiosBaseQuery = (): AxiosBaseQuery => async (axiosConfig) => {
  try {
    const { data, ...meta } = await chatServiceInstance(axiosConfig);

    return { data, meta };
  } catch (axiosError) {
    return {
      error: axiosError as BaseQueryError,
    };
  }
};

const api = createApi({
  reducerPath: 'chatService/api',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ClientConversations'],
  endpoints: () => ({}),
});

export default api;
