# ENVIRONMENT VARIABLES FROM BACKEND

SYMBOLS =
    EUR: "€",
    USD: "$"

setState = (state, cb) ->
    state.HOSTNAME = if state.IS_ORDERRY then "orderry.com" else "remonline.app"

    state.getBillingCurrency = () ->
        return {symbol: SYMBOLS[state.BILLING_CURRENCY] or SYMBOLS.EUR}

    for own k, v of state
        module.exports[k] = v

    cb and cb()

PAGE_SIZE = 50

LANGUAGES = [
    {"value": "CS", "title": "Čeština"}
    {"value": "ET", "title": "Eesti"}
    {"value": "EN", "title": "English"}
    {"value": "EN-GB", "title": "English (UK)"}
    {"value": "ES", "title": "Español"}
    {"value": "HR", "title": "Hrvatski"}
    {"value": "LV", "title": "Latvijā"}
    {"value": "LT", "title": "Lietuvos"}
    {"value": "HU", "title": "Magyar"}
    {"value": "PL", "title": "Polski"}
    {"value": "PT", "title": "Português"}
    {"value": "RO", "title": "Română"}
    {"value": "SK", "title": "Slovenský"}
    {"value": "FI", "title": "Suomi"}
    {"value": "BE", "title": "Беларуская"}
    {"value": "BG", "title": "Български"}
    {"value": "KY", "title": "Кыргыз"}
    {"value": "KK", "title": "Қазақша"}
    {"value": "RU", "title": "Русский"}
    {"value": "TG", "title": "Тоҷикӣ"}
    {"value": "UK", "title": "Українська"}
    {"value": "AZ", "title": "Azərbaycan"}
    {"value": "UZ", "title": "O'zbek"}
    {"value": "TR", "title": "Türkçe"}
    {"value": "EL", "title": "Ελληνικά"}
    {"value": "KA", "title": "ქართული ენა"}
    {"value": "HY", "title": "Հայերէն"}
    {"value": "DE", "title": "Deutsch"}
    {"value": "DA", "title": "Dansk"}
    {"value": "NL", "title": "Nederlands"}
    {"value": "NN", "title": "Norsk"}
    {"value": "FR", "title": "Français"}
    {"value": "IT", "title": "Italiano"}
    {"value": "SV", "title": "Svenska"}
].sort((a, b) -> a.title.localeCompare(b.title, 'en'))


LANGUAGES_ORDERRY = [
    {"value": "DA", "title": "Dansk"}
    {"value": "DE", "title": "Deutsch"}
    {"value": "EN", "title": "English (US)"}
    {"value": "EN-GB", "title": "English (UK)"}
    {"value": "ES", "title": "Español"}
    {"value": "FR", "title": "Français"}
    {"value": "IT", "title": "Italiano"}
    {"value": "NL", "title": "Nederlands"}
    {"value": "NN", "title": "Norsk"}
    {"value": "PL", "title": "Polski"}
    {"value": "PT", "title": "Português"}
    {"value": "SL", "title": "Slovenski"}
    {"value": "FI", "title": "Suomi"}
    {"value": "SV", "title": "Svenska"}
    {"value": "RU", "title": "Русский"}
    {"value": "UK", "title": "Українська"}
].sort((a, b) -> a.title.localeCompare(b.title, 'en'))

module.exports = {
    PAGE_SIZE, LANGUAGES, LANGUAGES_ORDERRY, setState
}
