{toBool} = require "lib/helpers"
{isChecked, check, uncheck, enable, disable} = require "lib/DOM"


# Must be synced with `JSONSerializer.py`.
UNSET = "__unset"
LIST_ENDING = "[]"


cleanValue = (value) ->
    return null if value is UNSET

    value

disableField = (form, name) ->
    disable form.find "[name=\"#{name}\"]"

enableField = (form, name) ->
    enable form.find "[name=\"#{name}\"]"


field = (form, name) ->
    e = form.find "[name=#{name}]"
    return null if e.length is 0

    if e.is ":checkbox" then isChecked e else e.val()


setField = (form, name, val) ->
    e = form.find "[name=#{name}]"
    return null if e.length is 0

    # Default case.
    return e.val cleanValue val unless e.is ":checkbox"

    # Checkbox case.
    if toBool val, false then check e else uncheck e


serialize = (form) ->
    result = {}

    form.find("input,textarea,select").map (i, node) ->
        return undefined unless node.name

        val = switch
            when node.value is "undefined"
                undefined

            when node.value is "null" or node.value is UNSET
                null

            when node.type is "checkbox"
                node.checked

            else
                node.value

        if node.name.slice(- LIST_ENDING.length) is LIST_ENDING
            result[node.name] = [] unless node.name of result
            result[node.name].push val if val
        else
            result[node.name] = val

    result

###
    `form` - jQuery DOM node.
    `fields` - object with the next structure: { 'name': 'value' }.
        Where `name` is value of the DOM node `name` attribute.
###
fill = (form, fields) ->
    setField form, name, value for name, value of fields

# Returns [string]: array of form fields names.
# -> IContainer form: form container/root element.
fieldsNames = (form) ->
    # Getting names of all field inputs;
    # inputs without names are excluded.
    form.find("input,select,checkbox,textarea").toArray()
        .map (input) -> input.name
        .filter (name) -> name


module.exports = {
    field
    serialize
    fill
    setField
    fieldsNames
    enableField
    disableField
}
