###
Client form fields library.
###

assign = require "lodash/assign"
omit = require "lodash/omit"

{error} = require "lib/logger"
{sortBy, only} = require "lib/fp"
{isArray, bool, toInt, toBool, values} = require "lib/helpers"

# Core libs.
{FIELD_NAME} = require "lib/forms/client/bl/fields"
{TField, IFieldSvc, onlyVisible} = require "lib/forms/fields"
{JURIDICAL_NONE} = require "lib/client"
{reload, get, create, bind} = require "lib/forms/transport"

# Relation between clientfield and juridical value
# (also known as TClientFormEntry.)
class TClientFormEntry

    # bool: juridical value.
    juridical: null

    # int: context position.
    pos: 0

    # bool: is field required in context of clienttype?
    required: false

    # string: default value.
    default_value: ""

    # bool: visible in order field?
    # Shoulb be the same as in ClientFormEntry on backend.
    card_visible: false

    # Ctor.
    constructor: (o) ->
        @juridical = toBool o.juridical, @juridical
        @required = toBool o.required, @required
        @card_visible = toBool o.card_visible, @card_visible
        @default_value = o.default_value or @default_value
        @pos = toInt o.pos, @pos


# Clientfield.
class TClientField extends TField

    # TClientFormEntry[]:
    # array of clientform entries.
    types: [ ]

    # Ctor.
    constructor: (o) ->
        super o
        @types = (
            if isArray o.types
                o.types.map (item) ->
                    new TClientFormEntry item
            else @types
        )


# Fields cache.
# Only for internal usage!

cache = [ ]
sorted = { }

sortCache = ->
    sorted = { }
    cache.map (field) ->
        field.types.map ({juridical, pos}) ->
            key = "#{juridical}"
            sorted[key] = [ ] if sorted[key] is undefined
            sorted[key].push {field, pos}

        for key, value of sorted
            sorted[key] = sortBy "pos", sorted[key]


initCache = (objects) ->
    cache = objects.map (obj) -> new TClientField obj
    sortCache()


# Updates cache value.
# -> TClientField field1: new field data.
# Returns TClientField: field1.
reCache = (field1) ->
    # Getting field index.
    index = -1
    for field, i in cache
        index = i if field.id is field1.id

    # Updating cache.
    if index is -1 then cache.push field1
    else cache[index] = field1

    # Sorting cache.
    # NOTE this is slow operation,
    # I recommend to add some opt like no_sort: true
    # to skip re-sorting when it's not needed.
    sortCache()

    # Returning passed field.
    field1

# Clientfield service.
class IClientFieldSvc extends IFieldSvc

    # const string: API base URL.
    URL_BASE = "/forms/clients"

    # const int: backend field type.
    fieldtype = "client"

    # Reloads fields; sometimes it's useful.
    # Returns void.
    reload: (cbs) ->
        reload {fieldtype}, {
            sccb: (res) ->
                initCache res
                cbs?.sccb()
        }

    # Creates new field.
    # -> TClientField field: source data.
    # -> opts: bool first: insert field first or last?
    # -> opts: void sccb, ercb: callbacks.
    # Returns void.
    create: (field, opts={}) ->
        {types, book_id} = field

        # Transforming field to payload.
        # Field is binided to first juridical in types array.
        binding = types[0]
        field_data = omit field, [
            "types"
            "book_id" unless book_id
        ]

        payload = only {
            fieldtype
            field: JSON.stringify field_data
            place: if binding then JSON.stringify {
                first: opts.first or false
                card_visible: binding.card_visible
                entity_id: binding.juridical
                required: binding.required
            }
        }, bool

        create payload, assign {}, opts, {
            sccb: (res) -> opts.sccb reCache new TClientField res
        }

    # Retrieves field.
    # -> int id: field id.
    # Returns void.
    get: (id, {sccb}) ->
        get {id, fieldtype}, {
            sccb: (res) -> sccb reCache new TClientField res if sccb
        }

    # Binds field to juridical.
    # -> int id: field id.
    # -> bool juridical: juridical value.
    # -> opts: bool first: insert field first or last?
    # -> opts: void sccb, ercb: callbacks.
    # Returns void.
    bind: (id, juridical, opts={ }) ->
        payload = {
            id
            fieldtype
            place: JSON.stringify {
                first: opts.first or false
                entity_id: juridical
                required: false
            }
        }

        bind payload, {
            sccb: (field) -> opts.sccb reCache new TClientField field
        }

    # Returns [TClientField]: visible client fields.
    # -> bool juridical: client juridical value.
    getVisFields: (juridical) ->
        # Returning cached unordered fields,
        # if specified juridical is none.
        if juridical is JURIDICAL_NONE
            return sortBy "id", onlyVisible cache

        # Getting fields related to requested juridical.
        # If fields is undefined, this means 2 cases:
        # MAYBE specified juridical is not exists;
        # MAYBE specified juridical is newly created and cache is outdated;
        # MAYBE specified juridical is exists, but hasn't any mapped fields.

        # In any of this cases we can do nothing and should return empty array;
        # anyway this not breaks application logic, since user will see new fields
        # once he restarts an application.
        entry = sorted["#{juridical}"]
        return [ ] if entry is undefined
        onlyVisible entry.map ({field}) -> field

    # Returns int: index of relation object between field and juridical value.
    # Undefined is returned if relation is not found.
    # -> TClientField field: context field.
    # -> bool juridical: juridical value to check.
    findRelIndex: ({types}, juridical) ->
        return i for item, i in types when item.juridical is juridical

    # See testRel() also.
    # See findRelIndex().
    # This one returns relation object instead of index.
    # RAISES exception if relation is not found.
    findRel: (field, juridical) =>
        i = @findRelIndex field, juridical
        msg = "Field #{field.id} isn't binded to juridical '#{juridical}'"
        if i is undefined then error msg else field.types[i]

    # See findRel() also.
    # See findRelIndex().
    # This one only tests relation object existence.
    # DOES NOT raises exception if relation is not found.
    testRel: (field, juridical) =>
        undefined isnt @findRelIndex field, juridical

    # Returns bool: is given field is a client name field?
    # -> TClientField field: field to check.
    isNameField: (field) -> field.name is FIELD_NAME.NAME

    # Returns string: title for 'name' field,
    # which is context-sensitive.
    # -> bool juridical: juridical value.
    getNameFieldLabel: (juridical) =>
        if juridical then __("Company name") else __("Client name")

    isBookAvailable: (field) ->
        # You can't attach book to fields,
        # that are implements special business logic.
        field.name not in values FIELD_NAME

    isAdCampaignExist: (fields) ->
        fields.some (field) -> field.name is FIELD_NAME.AD_CAMPAIGN


# Stateless shared clientfield service instance.
clientFieldService = new IClientFieldSvc


module.exports = {
    initCache
    reCache

    TClientField
    TClientFormEntry
    IClientFieldSvc
    clientFieldService
}
