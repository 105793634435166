import toast from 'react-hot-toast';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _keys from 'lodash/keys';

import { NOTIFY_PREFIX_REGEXP } from '@@constants/transport';

import { REG_EXP } from './constants';

export { ERROR_MESSAGES, FIELD_MAX_SYMBOLS, REG_EXP, RULES } from './constants';

// Legacy. For RTK Query use hooks/form/useSetFormErrors.ts
export const formErrorsHandler = (
  errors = {},
  handler,
  keyMap = (key) => key,
) => {
  if (_isEmpty(errors) || !handler) return;

  _keys(errors.message.validation).forEach((key) => {
    const entity = errors.message.validation[key];

    // Handle embedded errors in django-password-validators
    if (_isString(entity)) {
      toast.error(entity);
    } else {
      entity.forEach((message) => {
        if (NOTIFY_PREFIX_REGEXP.test(key)) {
          toast.error(message);
        }

        handler(keyMap(key), { message });
      });
    }
  });
};

export const validateEmail = (email) => {
  const pattern = new RegExp(REG_EXP.EMAIL, 'gi');

  return pattern.test(email);
};

export const validateMultyEmail = (emails) => {
  if (!emails) {
    return true;
  }

  const pattern = new RegExp(REG_EXP.EMAIL, 'gi');
  const emailsList = emails.split(',').map((email) => email.trim());
  const checks = emailsList.map((email) => {
    pattern.lastIndex = 0;

    return pattern.test(email);
  });

  return checks.every(Boolean);
};

export const validateImei = (imei) => {
  // http://www.macrumors.com/2010/04/16/apple-tweaks-serial-number-format-with-new-macbook-pro/
  // Standard serial number length is 15, but Apple has 11 (old models) or
  //  12 numbers (new models).

  return /^\d{8}\w{3,7}$/.test(imei);
};
