export const EARNING_TYPE = {
  ORDER: 0,
  SALE: 1,
  TASK: 2,
  LEAD: 3,
  REFUND: 4,
} as const;

export const DAY_TYPE = {
  DEFAULT: 0,
  SICK: 1,
  VACATION: 2,
  WEEKEND: 3,
};

export const EARNING_EVENT = {
  ORDER_CREATED: 0,
  ORDER_CLOSED: 1,
  TECHNICIAN_FOR_WORK_ORDER: 2,
  TECHNICIAN_FOR_DEDUCTED_MATERIAL_ORDER: 3,
  TECHNICIAN_FOR_ORDER: 4,
  MANAGER_FOR_WORK_ORDER: 5,
  MANAGER_FOR_DEDUCTED_MATERIAL_ORDER: 6,
  MANAGER_FOR_ORDER: 7,
  LEAD_MANAGER_FOR_WORK_ORDER: 8,
  LEAD_MANAGER_FOR_DEDUCTED_MATERIAL_ORDER: 9,
  LEAD_MANAGER_FOR_ORDER: 10,
  SALE_CREATED: 11,
  LEAD_MANAGER_FOR_SALE: 12,
  TASK_CREATED: 13,
  TASK_COMPLETED: 14,
  LEAD_CREATED: 15,
  LEAD_CLOSED: 16,
  REFUND_CREATED: 17,
  ORDER_MANAGER_FOR_REFUND: 18,
  ORDER_EMPLOYEE_FOR_REFUND: 19,
  ORDER_ENGINEER_FOR_REFUND: 20,
  SELLER_FOR_REFUND: 21,
  SELLER_WORK_SERVICE: 22,
  SELLER_MATERIAL: 23,
  TECHNICIAN_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER: 24,
  MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER: 25,
  LEAD_MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER: 26,
};

export const EARNING_PAYLOAD = {
  [EARNING_EVENT.ORDER_CREATED]: {
    ID: 0,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.ORDER_CLOSED]: {
    ID: 1,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_WORK_ORDER]: {
    ID: 2,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_DEDUCTED_MATERIAL_ORDER]: {
    ID: 3,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_ORDER]: {
    ID: 4,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.MANAGER_FOR_WORK_ORDER]: {
    ID: 5,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.MANAGER_FOR_DEDUCTED_MATERIAL_ORDER]: {
    ID: 6,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.MANAGER_FOR_ORDER]: {
    ID: 7,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_WORK_ORDER]: {
    ID: 8,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_DEDUCTED_MATERIAL_ORDER]: {
    ID: 9,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_ORDER]: {
    ID: 10,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.TECHNICIAN_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]: {
    ID: 11,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]: {
    ID: 12,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_MATERIAL_WITHOUT_DEDUCTION_ORDER]: {
    ID: 13,
    TYPE: EARNING_TYPE.ORDER,
  },
  [EARNING_EVENT.SALE_CREATED]: {
    ID: 0,
    TYPE: EARNING_TYPE.SALE,
  },
  [EARNING_EVENT.LEAD_MANAGER_FOR_SALE]: {
    ID: 1,
    TYPE: EARNING_TYPE.SALE,
  },
  [EARNING_EVENT.TASK_CREATED]: {
    ID: 0,
    TYPE: EARNING_TYPE.TASK,
  },
  [EARNING_EVENT.TASK_COMPLETED]: {
    ID: 1,
    TYPE: EARNING_TYPE.TASK,
  },
  [EARNING_EVENT.LEAD_CREATED]: {
    ID: 0,
    TYPE: EARNING_TYPE.LEAD,
  },
  [EARNING_EVENT.LEAD_CLOSED]: {
    ID: 1,
    TYPE: EARNING_TYPE.LEAD,
  },
  [EARNING_EVENT.REFUND_CREATED]: {
    ID: 0,
    TYPE: EARNING_TYPE.REFUND,
  },
  [EARNING_EVENT.ORDER_MANAGER_FOR_REFUND]: {
    ID: 1,
    TYPE: EARNING_TYPE.REFUND,
  },
  [EARNING_EVENT.ORDER_EMPLOYEE_FOR_REFUND]: {
    ID: 2,
    TYPE: EARNING_TYPE.REFUND,
  },
  [EARNING_EVENT.ORDER_ENGINEER_FOR_REFUND]: {
    ID: 3,
    TYPE: EARNING_TYPE.REFUND,
  },
  [EARNING_EVENT.SELLER_FOR_REFUND]: {
    ID: 4,
    TYPE: EARNING_TYPE.REFUND,
  },
  [EARNING_EVENT.SELLER_WORK_SERVICE]: {
    ID: 2,
    TYPE: EARNING_TYPE.SALE,
  },
  [EARNING_EVENT.SELLER_MATERIAL]: {
    ID: 3,
    TYPE: EARNING_TYPE.SALE,
  },
} as const;

export const EXECUTION_TERM = {
  ANY: 0,
  IN_TIME: 1,
  OVERDUE: 2,
  URGENT_IN_TIME: 3,
  URGENT_OVERDUE: 4,
} as const;

export const BONUS_TYPE = {
  FIX_AMOUNT: 0,
  PERCENT: 1,
} as const;

export const BONUS_TYPE_NAMES = {
  [BONUS_TYPE.FIX_AMOUNT]: 'earningsSum',
  [BONUS_TYPE.PERCENT]: 'earningsPct',
};

export const CHECK_TYPE = {
  NONE: 0,
  AMOUNT: 1,
  PROFIT: 2,
} as const;

export const CALCULATION_TIME = {
  IMMEDIATELY: 0,
  STATUS_CHANGED_TO: 1,
} as const;

export const EXEPTIONAL_COMMISSION_TYPE = {
  CATEGORY_PRODUCTS: 0,
  CATEGORY_SERVICES: 1,
  SERVICE: 2,
  PRODUCT: 3,
} as const;

export const REFUND_TYPE = {
  REVERT_COMMISSION: 0,
  SUBTRACT: 1,
} as const;
