# Dialog shared lib.

{hideWsp, showWsp} = require "lib/widgets/Layout/wsp"
{iOS} = require "lib/mobile"

# const string: app global namespace.
NS = "dialog"


# Class name applied
# to dialog submission button.
SUBMIT_CNAME = "js-#{NS}-submit"


# Class name applied
# to dialog removal button.
REMOVE_CNAME = "js-#{NS}-remove"


# Common logic used to hide
# workspace when dialog is displayed.
# Returns void.
hideWorkspace = ->
    hideWsp() if iOS()
    undefined


# Common logic used to show
# workspace when dialog is displayed.
# Returns void.
showWorkspace = ->
    showWsp() if iOS()
    undefined


# Dialog size.
SIZE =
    TINY: "b-ddd__face_size_xs"
    SMALL: "b-ddd__face_size_sm"
    MEDIUM:  "b-ddd__face_size_md"
    STANDARD: "b-ddd__face_size_st"
    LARGE: "b-ddd__face_size_lg"


module.exports = {
    NS
    SIZE
    SUBMIT_CNAME
    REMOVE_CNAME
    hideWorkspace
    showWorkspace
}
