REG =
    DECIMAL: /%\((\w+)\)(\d+)d/g # "%(name)05d"
    STRING: /%\((\w+)\)s/g # "%(name)s"


String.prototype.format = (o) ->
    output = @

    output = output
        .replace REG.DECIMAL, (match, name, padding) ->
            return match unless name of o

            param = o[name]
            len = parseInt(padding) - param.toString().length

            return "#{param}" if len < 1

            (("0" for [1..len]).join "") + param
        .replace REG.STRING, (match, name) ->
            return match unless name of o

            o[name]


String.prototype.insertML = (k, v) ->

    splitted = @.split "%(#{k})s"

    for i in [1...splitted.length]
        splitted.splice i, 0, v

    return splitted


String.prototype.formatML = (schema) ->
    result = []
    str = @

    while str.length > 0 and (parsed = REG.STRING.exec str) isnt null
        [rawVariable, variable] = parsed  # Example: ['%(warehouse_title)s', 'warehouse_title']

        # Split text by parsed label.
        parts = str.split rawVariable

        # Concat first part of description and modified label to result.
        result = result.concat [
            parts.shift(),
            if variable of schema then schema[variable] else rawVariable
        ]

        # Unite the remaining parts with parsed label.
        str = parts.join rawVariable
        REG.STRING.lastIndex = 0

    result.push str if str.length > 0

    result


module.exports = {}
