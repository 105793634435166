import { MARGIN_TYPES } from '@@constants/margin';
import { ORDER_WARRANTY_FROM } from '@@constants/settings';

import { Employee } from '@@types/employee';
import { EventContextType } from '@@types/events';
import { Merchant } from '@@types/psp';
import { RelationType } from '@@types/relation';
import { CashTypeRounding } from '@@types/rounding';
import { Status } from '@@types/status';
import { AssigneeType } from '@@types/task';
import { RecalculateTypes } from '@@types/taxes';

export enum ContextType {
  ORDER = EventContextType.ORDER,
  LEAD = EventContextType.LEAD,
}

export interface CallSettingTask {
  company: number;
  isActive: boolean;
  deadlineMinutes: number;
  assigneeType: AssigneeType.SAME_TASK | AssigneeType.UNIQUE_TASK;
  contextTypes: ExtractEnumNumberValues<ContextType>[];
  employees: number[];
  employeesData: Employee[];
}

export interface DeactiveEmployeeArgs {
  id: Employee['id'];
  isActive: Employee['isActive'];
}

export interface DeactiveEmployeeData {
  id: Employee['id'];
  isActive: Employee['isActive'];
  isDeleted: Employee['isDeleted'];
  counterpartyId: number;
  createdAt: Date;
  language: string;
}

export interface GetActiveAdCampaignsArgs {
  branch_id: number;
}

export interface EmployeeProfile {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  phone: string;
  telegram_chat_id?: string;
  drop_session_daily?: boolean;
  has_xero_connection?: boolean;
  has_quick_books_connection?: boolean;
}

export interface GetEmployeeProfileArgs {
  id: number;
}

export type UpdateEmployeeProfileArgs = Pick<
  EmployeeProfile,
  'id' | 'email' | 'first_name' | 'last_name' | 'phone'
>;

export interface UpdatePasswordArgs {
  password?: string;
  new_password: string;
}

export interface InventorySettings {
  warehouse_cells_enabled: boolean;
  asset_sernum_template: string;
  asset_sernum_counter: number;
  sernum_template: string;
  sernum_counter: number;
  unique_identifier: number;
}

export interface UpdateSudoSuAllowedSettings {
  isSudoSuAllowed: boolean;
}

export interface UpdateTaxUsageSettings {
  enabled: boolean;
}

export interface SetTaxesRecalculationSettings {
  recalculateType: RecalculateTypes;
}

export type OrderWarrantyFromType = ExtractValues<typeof ORDER_WARRANTY_FROM>;

export interface OrdersSettings {
  whPlanningEnabled: boolean;
  warrantyStartingPoint: OrderWarrantyFromType;
  hasOrderOneNonClosedAsset: boolean;
}

export interface SetPriceRoundBaseArgs {
  cashType: CashTypeRounding;
  documentTypes: RelationType[];
  roundBase: number;
}

export enum PublicApprovalLogoSize {
  small = 'small',
  big = 'big',
}

export interface AppearanceSettings {
  logoSize: PublicApprovalLogoSize;
  isLogo: boolean;
  isName: boolean;
  isAddress: boolean;
  isEmail: boolean;
  isDetails: boolean;
  isNote: boolean;
  isRecommendations: boolean;
  isLanguage: boolean;
}

export interface ApprovalSettings {
  acceptedStatus: Status['id'] | null;
  declinedStatus: Status['id'] | null;
  displayStatuses: Status['id'][];
  isActive: boolean;
  isSignature: boolean;
}

export interface OrderPaymentSettings {
  toStatus: Status['id'] | null;
  merchant: Merchant['id'] | null;
  fromStatuses: Status['id'][];
  isActive: boolean;
}

export interface PublicPageSettings {
  lookAndFeel: AppearanceSettings;
  estimateApproval: ApprovalSettings;
  orderApproval: ApprovalSettings;
  orderPayment: OrderPaymentSettings;
}

export type MarginTypes = ExtractValues<typeof MARGIN_TYPES>;

export interface SetDefaultMarginsArgs {
  repair_default_good: number;
  shop_default_good: number;
  invoice_default_good: number;
  repair_default_service: number;
  shop_default_service: number;
  invoice_default_service: number;
}

export interface BranchData {
  id: number;
  uuid: string;
  company_id: number;
  created_at: number;
  name: string;
  address: string;
  color: number;
  icon: number;
  orders_prefix: string;
  orders_type_strategy: string;
  documents_prefix: string;
  timezone: string;
  archived: boolean;
  virtual: boolean;
  orders_counter: number;
  default_margins: Record<string, number>;
}
