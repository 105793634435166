import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setBranch as setBranchAPI } from '@@api/branch';
import HeaderBranchStream from '@@streams/HeaderBranchStream';

const { setState: setBranchState } = require('interface/Branch');
const { setState: setUserState } = require('interface/User');

export const setBranch = createAsyncThunk(
  'branches/setBranch',
  async ({ branchId }, { rejectWithValue }) => {
    const response = await setBranchAPI(
      { branch_id: branchId },
      {
        sccb: ({ branch, user }) => {
          setBranchState(branch);
          setUserState(user);
          HeaderBranchStream.next(branch.ID);
        },
        ercb: rejectWithValue,
      },
    );

    if (response.message) {
      return rejectWithValue(response.message);
    }

    return response;
  },
);

const branchesSlice = createSlice({
  name: 'branches',
  initialState: {
    activeId: null,
    loading: false,
    data: {},
  },
  extraReducers: (builder) => {
    builder.addCase(setBranch.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(setBranch.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.activeId = payload.branch.ID;
    });

    builder.addCase(setBranch.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default branchesSlice.reducer;
