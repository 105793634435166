import { createSlice } from '@reduxjs/toolkit';

import { OrderState } from './types';

export const initialState: OrderState = {
  isUpdating: false,
  refetchCount: 0,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setIsOrderUpdating(state, { payload }) {
      state.isUpdating = payload;
    },
    incrementRefetchCount(state) {
      state.refetchCount += 1;
    },
  },
});

export const { setIsOrderUpdating, incrementRefetchCount } = orderSlice.actions;

export default orderSlice.reducer;
