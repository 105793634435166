filter = require "lodash/filter"
{hashTable} = require "lib/fp"
{toInt, copy} = require "lib/helpers"
{fetchRequest} = require("@@services/transport/fetch")


UNLIMITED = -1

MAX_LIMIT = 10000

PAYMENT_URL = "/app/billing/new-payment"

PLAN_TYPES =
    TRIAL: 0
    TEST: 1
    HOBBY_OLD: 15 # Unlimited sales
    HOBBY_2019: 16 # Limited sales
    STARTUP_2019: 17
    BUSINESS_2019: 18
    MAXIMUM_2019: 19
    HOBBY: 20 # Limited sales
    STARTUP: 21
    BUSINESS: 22
    ENTERPRISE: 23

PERIODS = [1, 3, 12]

# CACHED PLANS
CASHED_PLANS = null
CBS_FOR_PLANS = null
# CASHED_PLANS_BY_TYPE PLANS
CASHED_PLANS_BY_TYPE = null


getCurrentPlan = -> require("interface/Company").LICENSE.TYPE

isCurrentPlan = (type) -> type is getCurrentPlan()

isActiveTempDiscount = -> not isTrial() and 1548115200000 < Date.now() < 1548979200000

isTrial = (plan) ->
    plan or= getCurrentPlan()
    plan is PLAN_TYPES.TRIAL

isTest = (plan) ->
    plan or= getCurrentPlan()
    plan is PLAN_TYPES.TEST

isHobby = (plan) ->
    plan or= getCurrentPlan()
    plan is PLAN_TYPES.HOBBY_OLD or plan is PLAN_TYPES.HOBBY_2019 or plan is PLAN_TYPES.HOBBY

isStartup = (plan) ->
    plan or= getCurrentPlan()
    plan is PLAN_TYPES.STARTUP_2019 or plan is PLAN_TYPES.STARTUP

isBusiness = (plan) ->
    plan or= getCurrentPlan()
    plan is PLAN_TYPES.BUSINESS_2019 or plan is PLAN_TYPES.BUSINESS

isMaximum = (plan) ->
    plan or= getCurrentPlan()
    plan is PLAN_TYPES.MAXIMUM_2019 or plan is PLAN_TYPES.ENTERPRISE

getPeriods = -> copy PERIODS

getPlans = ({isCashed, cb, isHashed} = {}) ->
    if isCashed and CASHED_PLANS and not isHashed
        cb(CASHED_PLANS)
    else
        if isCashed and CBS_FOR_PLANS
            CBS_FOR_PLANS.push({cb, isHashed})
            return

        CBS_FOR_PLANS = [] if isCashed
        fetchPlans
            sccb: (response) ->
                CASHED_PLANS = response
                CASHED_PLANS_BY_TYPE = hashTable "plan", response
                [{cb, isHashed}].concat(CBS_FOR_PLANS or []).map (item) ->
                    return null if not item.cb
                    result = if item.isHashed then CASHED_PLANS_BY_TYPE else response
                    item.cb(result)
                CBS_FOR_PLANS = null if isCashed

getDiscount = (price, months) ->
    switch parseInt months, 10
        when 6
            price * 0.05

        when 12
            price * 0.1

        when 24
            price * 0.15

        else
            undefined

canEnableWarehouseCells = (plan) ->
    plan or= getCurrentPlan()
    !isHobby(plan) and !isStartup(plan)

hasFeatureDiff = (nextPlan, usedFutures) ->
    filteredUsedFeature = filter usedFutures, (feature) -> feature
    return true unless filteredUsedFeature.length
    not (filter usedFutures, (featureValue, featureName) -> featureValue and nextPlan.available_features.indexOf(featureName) < 0).length

canChange = (nextPlan, usedFutures) ->
    {ACTIVE_EMPLOYEES, ACTIVE_BRANCHES} = require "interface/Company"
    {type, max_branch_seats, max_employees_seats} = nextPlan

    isBranchesLimitValid = max_branch_seats is UNLIMITED or ACTIVE_BRANCHES.length <= max_branch_seats
    isEmployeesLimitValid = max_employees_seats is UNLIMITED or ACTIVE_EMPLOYEES.length <= max_employees_seats
    allowedPlanForExtraPermissions = !isHobby(type) and !isStartup(type)
    canChangeByCashboxPermissions = (usedFutures and !usedFutures.cashbox_permissions) or allowedPlanForExtraPermissions
    canChangeByWarehousePermissions = (usedFutures and !usedFutures.warehouse_permissions) or allowedPlanForExtraPermissions
    featureDiff = hasFeatureDiff(nextPlan, usedFutures)

    return (
        canChangeByCashboxPermissions and
        canChangeByWarehousePermissions and
        isBranchesLimitValid and
        isEmployeesLimitValid and
        featureDiff
    )

isUnlimited = (value) ->
    UNLIMITED is toInt(value)

getPaymentsCards = ({sccb = (()-> {}), ercb = (()-> {})}) ->
    fetchRequest({
        url: '/payment_gateway/get-cards',
        sccb,
        ercb,
    })

fetchPlans = ({sccb = (()-> {}), ercb = (()-> {})}) ->
    fetchRequest({
        url: '/billing/get-plans',
        sccb,
        ercb,
    })

getUsedFeatures = ({sccb, ercb = (()-> {})}) ->
    fetchRequest({
        url: "/billing/get-used-features",
        sccb,
        ercb
    })

module.exports = {
    PAYMENT_URL, MAX_LIMIT,
    getPeriods, getDiscount, getCurrentPlan, isCurrentPlan, getUsedFeatures,
    isHobby, isTrial, isTest, canChange, getPlans,
    isStartup, isBusiness, isMaximum, isUnlimited, isActiveTempDiscount,
    canEnableWarehouseCells, getPaymentsCards
}
