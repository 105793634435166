import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _isArray from 'lodash/isArray';

import { DAY_TYPE } from '@@constants/workSchedule';
import {
  convertTimezones,
  eachDayOfInterval,
  isSameDay,
  toTimestamp,
} from '@@helpers/format/date';
import { fetchRequest } from '@@services/transport/fetch';

export const fetchOrderFields = createAsyncThunk(
  'schedule/fetchOrderFields',
  async ({ requestUrl, queries, extraCbs }, { rejectWithValue }) => {
    const response = await fetchRequest({
      url: requestUrl,
      payload: queries,
      ercb: rejectWithValue,
    });

    if (response.message) {
      return rejectWithValue(response.message);
    }

    if (extraCbs && extraCbs.length) {
      return extraCbs.reduce((acc, next) => next(acc), response);
    }

    return response;
  },
);

export const fetchWorkScheduleData = createAsyncThunk(
  'schedule/fetchWorkScheduleData',
  async ({ requestUrl, queries, extraCbs }, { rejectWithValue }) => {
    const response = await fetchRequest({
      url: requestUrl,
      payload: queries,
      ercb: rejectWithValue,
    });

    if (response.message) {
      return rejectWithValue(response.message);
    }

    if (extraCbs && extraCbs.length) {
      return extraCbs.reduce((acc, next) => next(acc), response);
    }

    return response;
  },
);

export const fetchScheduleData = createAsyncThunk(
  'schedule/fetchScheduleData',
  async ({ requestUrl, queries, extraCbs }, { rejectWithValue }) => {
    const response = await fetchRequest({
      url: requestUrl,
      method: 'POST',
      payload: queries,
      ercb: rejectWithValue,
    });

    if (response.message) {
      return rejectWithValue(response.message);
    }

    if (extraCbs && extraCbs.length) {
      return extraCbs.reduce((acc, next) => next(acc), response);
    }

    return response;
  },
);

const initialState = {
  loading: false,
  error: false,
  workSchedule: [],
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    migrateDates: (state, { payload }) => {
      const { prevTimezone, nextTimezone } = payload;

      if (_isArray(state.date)) {
        state.date = state.date.map((date) =>
          convertTimezones(date, prevTimezone, nextTimezone),
        );
      }

      if (_isArray(state.nextDate)) {
        state.nextDate = state.nextDate.map((date) =>
          convertTimezones(date, prevTimezone, nextTimezone),
        );
      }
    },
    initSchedule: (state, { payload }) => {
      return { ...state, ...payload };
    },

    // payload: { resourceId: number, branchId: number }
    checkResource: (state, { payload }) => {
      const { resourceId, branchId } = payload;
      const { checkedResources = {} } = state;
      let checkedResourcesOnBranch =
        checkedResources[branchId]?.[state.executor] || [];

      if (checkedResourcesOnBranch.includes(resourceId)) {
        checkedResourcesOnBranch = checkedResourcesOnBranch.filter(
          (id) => id !== resourceId,
        );
      } else {
        checkedResourcesOnBranch.push(resourceId);
      }

      state.checkedResources[branchId][state.executor] = [
        ...checkedResourcesOnBranch,
      ];
    },

    // payload: { branchId: number }
    checkResourceAll: (state, { payload: { branchId } }) => {
      state.checkedResources[branchId][state.executor] = state[
        state.executor
      ].map((item) => item.id);
    },

    // payload: { branchId: number }
    checkResourceNone: (state, { payload: { branchId } }) => {
      state.checkedResources[branchId][state.executor] = [];
    },

    removeSchedule: (state) => {
      return { ...state, ...initialState };
    },
    changeSettings: (state, { payload: settings }) => {
      return { ...state, settings };
    },
    changeScale: (state, { payload }) => {
      return { ...state, ...payload };
    },
    changeDayCustom: (state, { payload }) => {
      state.nextDate = payload.date;
    },
    changeRangeView: (state, { payload }) => {
      state.rangeView = payload;
    },
    changeExecutor: (state, { payload }) => {
      state.executor = payload;
    },
    externalReloadSchedule(state, { payload }) {
      state.shouldUpdate = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderFields.fulfilled, (state, { payload }) => {
      state.orderTypes = payload;
    });

    builder.addCase(
      fetchWorkScheduleData.fulfilled,
      (state, { payload, meta }) => {
        const { data } = payload;
        const { externalData } = meta.arg;

        state.workSchedule = data.map(({ employee_id, day_schedules }) => {
          let copySchedule = day_schedules.slice();

          return {
            employee_id,
            schedule: eachDayOfInterval({
              start: externalData.begin_time,
              end: externalData.end_time,
            }).map((date) => {
              const [daySchedule] = copySchedule;

              if (
                daySchedule &&
                isSameDay(daySchedule.schedule[0].begin_time, date)
              ) {
                copySchedule = copySchedule.slice(1);

                return {
                  date: toTimestamp(date),
                  type: daySchedule.type,
                  schedule: daySchedule.schedule,
                };
              }

              return {
                date: toTimestamp(date),
                type: DAY_TYPE.NON_WORK,
                schedule: [],
              };
            }),
          };
        });
      },
    );

    builder.addCase(fetchScheduleData.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(fetchScheduleData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false,
        date: state.nextDate || state.date,
      };
    });

    builder.addCase(fetchScheduleData.rejected, (state) => {
      state.loading = false;
      state.error = true;

      if (state.nextDate) {
        state.nextDate = state.date;
      }
    });
  },
});

export const {
  migrateDates,
  initSchedule,
  checkResource,
  checkResourceAll,
  checkResourceNone,
  removeSchedule,
  changeSettings,
  changeScale,
  changeDayCustom,
  changeRangeView,
  changeExecutor,
  externalReloadSchedule,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
