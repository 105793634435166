Cookie = require "js-cookie"

# https://web-analytics.me/4_ways_for_take_clientid
getCID = ->
    match = document.cookie.match "(?:^|;)\\s*_ga=([^;]*)"
    raw = if match then decodeURIComponent(match[1]) else null
    if raw
        match = raw.match /(\d+\.\d+)$/

    if match then match[1] else null


firstSignInHandler = ->
    {DOMAIN} = require("interface/System")

    if window.dataLayer and Cookie.get "ro.first.signin"
        Cookie.remove "ro.first.signin", { domain: "#{if __DEV__ then '' else '.'}#{location.hostname}" }
        window.dataLayer.push({'event': 'registration', 'reg-step': '3', domain: DOMAIN});


module.exports = {
    cid: getCID(),
    firstSignInHandler: firstSignInHandler
}
