import { fetchRequest } from '@@services/transport/fetch';

export const setAllowSupportChat = (payload, options = {}) => {
  return fetchRequest({
    url: '/user/set-allow-support-chat',
    method: 'POST',
    payload,
    ...options,
  });
};

export const setTraining = (payload, options = {}) => {
  return fetchRequest({
    url: '/user/set-training',
    method: 'POST',
    payload,
    ...options,
  });
};
