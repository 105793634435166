interface PrepareMoneySymbolPositionArgs {
  formattedNumber: number | string;
  symbol: string;
  symbolSeparator?: string;
  symbolPosition?: 'left' | 'right';
}

export const prepareMoneySymbolPosition = ({
  formattedNumber,
  symbol,
  symbolSeparator = ' ',
  symbolPosition = 'right',
}: PrepareMoneySymbolPositionArgs) => {
  if (symbolPosition === 'left') {
    return `${symbol}${symbolSeparator}${formattedNumber}`;
  }

  return `${formattedNumber}${symbolSeparator}${symbol}`;
};
