{pMerge, cancel_button} = require "lib/layout"
classNames = require "classnames"

spinner = ["div", {"class": "h-spinner"}]

dialogSpinner = ["div", {"class": "col-sm-12 h-p-r h-p-0 h-tper-30"}, spinner]

offer = ["div"
    ["label", {"class": "h-d-ib h-c-muted h-m-0 h-fw-n"}
              {"for": "js-agreement"}
        ["input", {"id": "js-agreement", "type": "checkbox"}]
        ["span", {"class": "h-checkbox"}]].concat __("I read %(offer)s and accept its terms"
        ).insertML(
            "offer",
            ["a", {"href": "//#{(require "app/Env/settings").HOSTNAME}/agreement/", "target": "_blank"}
                __("contract of a public offer")]
        )]

low_plan_alert = (title, opts={}, containerOpts = {}) ->
    link_opts = pMerge(
        opts
        "href": ROUTES.SETTINGS.LICENSE
        "class": "b-link b-link_color_white h-ml-5 h-td-ul"
    )

    container_opts = pMerge(
        containerOpts
        "class": classNames("b-info h-c-white h-bc-red", containerOpts.className or '')
    )

    ["div", container_opts
        ["span", "#{title}"
            "."
            ["a", link_opts, __("Upgrade to the greater Plan")]
        ]
    ]


# Used with the CRUD.gridForm.
grid = (cls, title, opts={}) ->
    ["div", { "class": cls or "" }

        ["div", { "class": "la-grid" }

            ["div", { "class": "la-grid__lb
                                la-grid__lb_style_delim" }

                ["div", { "class": "la-grid__item" }
                    ["button", { "class": "b-btn b-btn_color_green
                                           js-add-button" }
                        "#{title}"]
                ]

            ]

            ["div", { "class": "la-grid__lb
                                la-grid__lb_style_delim" }

                ["div", { "class": "la-grid__item" }
                    ["button", { "class": "b-btn
                                           b-btn_color_white
                                           b-btn_type_ico
                                           js-import" }
                        ["span", { "class": "jsonml-tooltip" }
                            __("Upload from file")
                        ]
                        ["span", { "class": "i-import h-mr-7" }]
                        ["span", __("v1 Import")]
                    ]
                ] if opts.import

                ["div", { "class": "la-grid__item" }
                    ["button",  { "class": "b-btn
                                            b-btn_color_white
                                            b-btn_type_ico
                                            js-export"
                                , "data-bind": "click: doExport" }
                        ["span", { "class": "jsonml-tooltip" }
                            __("Upload file")
                        ]
                        ["span", { "class": "i-export h-mr-7" }]
                        ["span", __("Export")]
                    ]
                ] if opts.export

            ]

            ["div", { "class": "la-grid__grow
                                la-grid--swap-row_if-geq-desktop" }

                ["div", { "class": "la-grid__lb
                                    la-grid__lb_style_delim" }
                    ["div", { "class": "la-grid__item
                                        js-grid-search" }
                    ] if opts.search
                ]

            ]

        ]

        ["div", { "class": "la-twr la-twr_on_always js-grid light-grid" }]
        ["div", { "class": "b-space-15" }]

        ["div", { "class": "la-grid
                            js-control-buttons" }

            ["div", { "class": "la-grid__grow
                                la-grid--swap-row_if-geq-desktop" }

                ["div", { "class": "la-grid__lb" }
                    ["div", { "class": "la-grid__item" }
                        ["button",  { "class": "b-btn
                                                b-btn_type_ico
                                                b-btn_color_trans
                                                js-edit-button"
                                    , "disabled" }
                            ["span", { "class": "i-edit h-mr-7" }]
                            __("Edit")]
                    ]

                    ["div", { "class": "la-grid__item" }
                        ["button",  { "class": "b-btn
                                                b-btn_type_ico
                                                b-btn_color_trans
                                                js-remove-button"
                                    , "data-bind": "click: remove"
                                    , "disabled" }
                            ["span", { "class": "i-trash h-mr-7" }]
                            __("Delete")]
                    ]
                ]
            ]
        ]

    ]

warehouse = (opts={}) ->
    ["div", {"class": "col-lg-12 h-p-0"}
        ["div", {"class": "la-fx h-mb-15"}
            if opts.warehouse
                ["div", {"class": "h-mr-10"}
                    ["label", {"class": "h-mb-5 h-d-b"}, __("Warehouse")]
                    ["div", {"class": "js-warehouses"}]]

            if opts.category
                ["div", {"class": "h-mr-10"}
                    ["label", {"class": "h-mb-5 h-d-b"}, __("Category")]
                    ["div", {"class": "js-c-categories"}]]

            if opts.price
                ["div", {"class": "h-mr-10"}
                    ["label", {"class": "h-mb-5 h-d-b"}, __("Price")]
                    ["div", {"class": "js-wh-prices"}]]

            if opts.search
                ["div"
                    ["label", { "class": "b-label b-label_style_empty" }]
                    ["div", {"class": "js-wh-search"}]]
        ]

        ["div", {"class": "col-lg-12 h-p-0 js-grid light-grid"}]]

warehouse_dialog_buttons = (main_button_text) ->
    ["div", { "class": "la-fx" }
        ["button", { "class": "b-btn b-btn_color_blue h-mr-10 js-submit-dialog" }
            main_button_text]

        cancel_button { "class": "js-close-dialog" }]

chart = (opts={}) ->
    ["div", {"class": "b-chart__canvas_wrapper"},
                ["canvas", {width: opts.width, height: opts.height}]
                ["div", {"class": "b-chart__legend"}]]


module.exports = {
    grid
    chart
    offer
    spinner
    low_plan_alert
    warehouse
    warehouse_dialog_buttons
    dialogSpinner
}
