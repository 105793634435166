import { memo } from 'react';
import PropTypes from 'prop-types';

import Image from '@@components/Image';
import SvgCaretLeft from '@@assets/images/icons/caret_left.svg';
import SvgMaintenance from '@@assets/images/pictures/maintenance.svg';

import { ICON_FILL } from '@@constants/images';
import historyConfig from '@@router/history';

import styles from './Fallback.module.scss';

function Fallback(props) {
  const { resetError } = props;

  const handleClick = () => {
    resetError();
    historyConfig.history.goBack();
  };

  return (
    <div className={styles.wrapper}>
      <Image image={SvgMaintenance} className={styles.maintenance} />

      <div className={styles.content}>
        <div className={styles.title}>{__('Oh no :(')}</div>

        <div className={styles.description}>
          {__(
            'We do our best to make the app work all the time smoothly, but sometimes stuff happens. We’ve already received a notification about the error and will fix it as soon as possible.',
          )}
        </div>

        <div className={styles.stepBack} onClick={handleClick}>
          <Image image={SvgCaretLeft} fill={ICON_FILL.BLUE_100} size={16} />

          {__('Take one step back')}
        </div>
      </div>
    </div>
  );
}

Fallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default memo(Fallback);
