# Responsible workspace.

# const int: workspace scroll state.
scroll_mem = 0


# Hides workspace
# with remembering of scroll position.
# Returns void.
hideWsp = ->
    scroll_mem = window.pageYOffset
    undefined


# Shows workspace
# with remembering of scroll position.
# Returns void.
showWsp = ->
    window.scrollTo 0, scroll_mem
    scroll_mem = 0
    undefined


module.exports = {
    hideWsp
    showWsp
}
