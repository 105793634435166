import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _map from 'lodash/map';

// Delete and Replace objects findConfig CASES:
//   1) 24 (string or number) - delete object with id === 24
//   2) [24, 36] (arr[string] or arr[number]) - delete objects with id === 24 and id === 36
//   3) {'meta': 24} (arr[string] || arr[number]) - delete objects with field meta === 24
//   4) {'meta.project': [24, 36], 'meta.dirty': true} ({ string: arr[string] || arr[number] }) - delete objects with meta[project] === (24 and 36) and meta[dirty] === true

export const findRowData = (row, findConfig, replaceValue) => {
  const isTrueCondition =
    // eslint-disable-next-line no-nested-ternary
    _isString(findConfig) || _isNumber(findConfig)
      ? row.id === findConfig
      : _isArray(findConfig)
        ? findConfig.filter((valueForDelete) => row.id === valueForDelete)
            .length
        : _map(findConfig, (value, key) => {
            if (_isArray(value)) {
              return value.filter(
                (valueForDelete) => _get(row, key) === valueForDelete,
              ).length;
            }

            return _get(row, key) === value;
          }).filter((a) => a).length;

  if (!replaceValue) {
    return isTrueCondition;
  }

  if (isTrueCondition) {
    if (_isObject(findConfig)) {
      return Object.keys(findConfig).length === isTrueCondition
        ? { ...row, ...replaceValue }
        : row;
    }

    return { ...row, ...replaceValue };
  }

  return row;
};

export const getPath = ({ name, relativeValue }) => {
  return relativeValue ? `${name}.${relativeValue}` : name;
};

export const getPayloadData = (data) => {
  if (data) {
    if (_isArray(data)) {
      return data;
    }

    return [data];
  }

  return [];
};
