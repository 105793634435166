import { createSlice } from '@reduxjs/toolkit';

import { AdCampaignsState } from './types';

export const initialState: AdCampaignsState = {
  data: [],
};

const adCampaignsSlice = createSlice({
  name: 'adCampaigns',
  initialState,
  reducers: {
    setActiveAdCampaigns(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { setActiveAdCampaigns } = adCampaignsSlice.actions;

export default adCampaignsSlice.reducer;
