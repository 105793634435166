// should be synced with @@common/variables.scss ($mobile-header-height)
export const MOBILE_HEADER_HEIGHT = 58;

export const HUGE_BREAKPOINT = 1920;
export const LARGE_BREAKPOINT = 1366;
export const DESKTOP_BREAKPOINT = 1280;
export const INTERMEDIATE_BREAKPOINT = 1024;
export const MEDIUM_BREAKPOINT = 992;
export const INTERMEDIATE_MEDIUM_BREAKPOINT = 800;
export const SMALL_BREAKPOINT = 768;
export const INTERMEDIATE_SMALL_BREAKPOINT = 667;
export const EXTRA_SMALL_BREAKPOINT = 480;
export const TINY_BREAKPOINT = 360;
