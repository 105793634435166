import type { AxiosRequestConfig } from 'axios';

import api from '@@api/rtk';

import { DeleteEventLogFileArgs } from '@@types/eventLog';

export const eventLogApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteEventLogFile: build.mutation<
      number,
      AxiosRequestConfig<DeleteEventLogFileArgs>
    >({
      query: (config) => ({
        url: '/event-log/file/delete',
        method: 'post',
        ...config,
      }),
    }),
  }),
});

export const { useDeleteEventLogFileMutation } = eventLogApi;

export * from './legacy';

export default eventLogApi;
