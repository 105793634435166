import { createSlice } from '@reduxjs/toolkit';
import _omit from 'lodash/omit';

const omitExtraFields = (payload) => {
  return _omit(payload, [
    'accessToken',
    'refreshToken',
    'company',
    'branch',
    'fields',
    'user',
  ]);
};

const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState: {
    loading: false,
    isStaff: false,
    isInUser: false,
    isAuthenticated: false,
    company_id: null,
    signupStep: null,
    shouldReload: false,
    isPartner: false,
  },
  reducers: {
    setAppInfo(state, { payload }) {
      return { ...state, ...omitExtraFields(payload) };
    },
    startUpdateAppInfo(state, { payload }) {
      return {
        ...state,
        ...omitExtraFields(payload),
        loading: true,
      };
    },
    finishUpdateAppInfo(state) {
      state.loading = false;
      state.company_id = null;
    },
    setReloadApp(state) {
      state.shouldReload = true;
    },
  },
});

export const {
  startUpdateAppInfo,
  finishUpdateAppInfo,
  setReloadApp,
  setAppInfo,
} = appInfoSlice.actions;

export default appInfoSlice.reducer;
