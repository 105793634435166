###
Template app libraty.
To work correctly, your app must inherits TTplApp.
###

{isString} = require "lib/helpers"
{jctx} = require "lib/DOM"


# Returns string: full selector text.
# -> TTplApp: app instance.
# -> string s: selector text.
sel = (self, s) ->
    ns = if isString self then self else self.ns
    [".js", ns, s].join '-'


# Returns IContainer: app root container.
# -> TTplApp: app instance.
root = (self) ->
    jctx self.container.find sel self, "root"


# Query selector shortcut.
# -> TTplApp: app instance.
# -> string s: selector text.
# -> opts: bool first: only first result?
qs = (self, s, {first}={ }) ->
    # Select all or only first?
    (if first is true or undefined then jctx else (v) -> v)(
        root(self).find sel self, s
    )

# Click shortcut.
# -> TTplApp: app instance.
# -> string s: selector text.
# -> void fn(ev): click handler.
click = (self, s, fn) ->
    root(self).on "click", sel(self, s), fn


# Change shortcut.
# -> TTplApp: app instance.
# -> string s: selector text.
# -> void fn(ev): click handler.
change = (self, s, fn) ->
    root(self).on "change", sel(self, s), fn


module.exports = {
    sel
    root
    qs
    click
    change
}
