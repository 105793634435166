###
Lead form fields.
###

assign = require "lodash/assign"
omit = require "lodash/omit"

{error} = require "lib/logger"
{merge, sortBy, only} = require "lib/fp"
{isArray, copy, bool, toInt, toBool} = require "lib/helpers"

# Core libs.
{TField, IFieldSvc, onlyVisible} = require "lib/forms/fields"
{reload, get, bind, create} = require "lib/forms/transport"

# const int: 'none' type lead.
NONE_TYPE = -1


# Relation between lead field and leadtype.
class TLeadTypeRel

    # int: lead type id.
    leadtype_id: NONE_TYPE

    # int: context position.
    pos: 0

    # bool: is field required in context of leadtype?
    required: false

    # string: default value.
    default_value: ""

    # Ctor.
    constructor: (o) ->
        @leadtype_id = toInt o.leadtype_id, @leadtype_id
        @default_value = o.default_value or @default_value
        @required = toBool o.required, @required
        @pos = toInt o.pos, @pos


# Lead form field.
class TLeadField extends TField

    # TLeadTypeRel[]: array of lead types
    # to which current field is related.
    types: [ ]

    # Ctor.
    constructor: (o) ->
        super o
        @types = if isArray o.types
                    new TLeadTypeRel item for item in o.types
                 else @types


# Fields cache.
# Only for internal usage!

cache = [ ]
sorted = { }

sortCache = ->
    sorted = { }
    cache.map (field) ->
        field.types.map ({leadtype_id, pos}) ->
            key = "#{leadtype_id}"
            sorted[key] = [ ] if sorted[key] is undefined
            sorted[key].push {field, pos}

        for key, value of sorted
            sorted[key] = sortBy 'pos', sorted[key]


initCache = (objects) ->
    cache = objects.map (obj) -> new TLeadField obj
    sortCache()


# Updates cache value.
# -> TLeadField field1: new field data.
# Returns TLeadField: field1.
reCache = (field1) ->
    # Getting field index.
    index = -1
    for field, i in cache
        index = i if field.id is field1.id

    # Updating cache.
    if index is -1 then cache.push field1
    else cache[index] = field1

    # Sorting cache.
    # NOTE this is slow operation,
    # I recommend to add some opt like no_sort: true
    # to skip re-sorting when it's not needed.
    sortCache()

    # Returning passed field.
    field1


# ----


# Lead form field service.
class ILeadFieldSvc extends IFieldSvc

    # const string: API base URL.
    URL_BASE = "/forms/leads"

    # const int: backend field type.
    fieldtype = "lead"

    # Reloads fields; sometimes it's useful.
    # Returns void.
    reload: (cbs) ->
        reload {fieldtype}, {
            sccb: (res) ->
                initCache res
                cbs?.sccb()
        }
    # Creates new field.
    # -> TLeadField field: source data.
    # -> opts: bool first: insert field first or last?
    # -> opts: void sccb, ercb: callbacks.
    # Returns void.
    create: (field, opts={ }) ->
        # Transforming field to payload.
        # Field is binided to first leadtype in types array.
        binding = field.types[0]
        field_data = omit field, [
            "types"
            "book_id" unless field.book_id
        ]

        payload = only {
            fieldtype
            field: JSON.stringify field_data
            place: if binding then JSON.stringify {
                first: opts.first or false
                entity_id: binding.leadtype_id
                required: binding.required
            }
        }, bool

        create payload, assign {}, opts, {
            sccb: (res) -> opts.sccb reCache new TLeadField res
        }

    # Retrieves field.
    # -> int id: field id.
    # Returns void.
    get: (id, {sccb}) ->
        get {id, fieldtype}, {
            sccb: (res) -> sccb reCache new TLeadField res if sccb
        }

    # Binds field to lead type.
    # -> int id: field id.
    # -> int leadtype_id: lead type.
    # -> opts: bool first: insert field first or last?
    # -> opts: void sccb, ercb: callbacks.
    # Returns void.
    bind: (id, leadtype_id, opts={ }) ->
        payload = {
            id
            fieldtype
            place: JSON.stringify {
                first: opts.first or false
                entity_id: leadtype_id
                required: false
            }
        }

        bind payload, {
            sccb: (field) -> opts.sccb reCache new TLeadField field
        }

    # Acquires VISIBLE fields of given lead type.
    # Fields are sorted by position.
    # -> int type: lead type.
    # Returns [TLeadField]: fields.
    getVisFields: (type) ->

        # Returning cached unleaded fields,
        # if specified type is none.
        return sortBy "id", onlyVisible cache if type is NONE_TYPE

        # Getting fields of requested lead type.
        # If fields is undefined, this means 3 cases:
        # MAYBE lead type is not exists;
        # MAYBE lead type is newly created and cache is outdated;
        # MAYBE lead type is exists, but hasn't any mapped fields.

        # In any of this cases we can do nothing and should return empty array;
        # anyway this not breaks application logic, since user will see new fields
        # once he restarts an application.
        entry = sorted[type]
        return [] if entry is undefined
        onlyVisible entry.map ({field}) -> field

    # Returns int: index of relation object between field and lead type.
    # Undefined is returned if relation is not found.
    # -> TLeadField field: context field.
    # -> int leadtype_id: lead type to check.
    findRelIndex: ({types}, leadtype_id) ->
        return i for item, i in types when item.leadtype_id is leadtype_id

    # See testRel() also.
    # See findRelIndex().
    # This one returns relation object instead of index.
    # RAISES exception if relation is not found.
    findRel: (field, leadtype_id) =>
        i = @findRelIndex field, leadtype_id
        msg = "Field #{field.id} isn't binded to leadtype #{leadtype_id}"
        if i is undefined then error msg else field.types[i]

    # See findRel() also.
    # See findRelIndex().
    # This one only tests relation object existence.
    # DOES NOT raises exception if relation is not found.
    testRel: (field, leadtype_id) =>
        undefined isnt @findRelIndex field, leadtype_id


# Stateless leadfield service instance.
# Use it everywhere in project.
leadfield_svc = new ILeadFieldSvc()


module.exports = {
    initCache
    reCache

    NONE_TYPE
    TLeadTypeRel
    TLeadField
    ILeadFieldSvc
    leadfield_svc
}
