export const OBJECT_TYPE = {
  BRANCH: 0,
  CASHBOX: 1,
  WAREHOUSE: 2,
};

export const NOTIFICATION_TYPE = {
  NOTIFICATION: 0,
  REMINDER: 1,
};

export const CREATE_OPERATION_SOURCE_TYPE = {
  SERVICES_AND_LABOR_LIST: 0,
  WORK_ORDER: 1,
  INVOICE: 2,
};
