IUser = require "interface/User"
{fetchRequest} = require("@@services/transport/fetch")

{toInt} = require "lib/helpers"


haveAccess = (allowed = [], id) ->
    allowed.indexOf(toInt id) > -1


module.exports =
    haveAccess: haveAccess

    havePermission: (permission) ->
        IUser.PERMISSIONS.indexOf(permission) > -1

    haveBranchAccess: (id) -> haveAccess IUser.ACCESS.branch, id

    haveCashboxAccess: (id) -> 
        PERMISSIONS_EXTRA = require("@@constants/permissions/extra").default

        allowedCashboxesIds = IUser.ACCESS.cashbox
            .filter(({access_lvl}) -> access_lvl != PERMISSIONS_EXTRA.ACCESS_LVL.NONE)
            .map(({id}) -> id)

        haveAccess allowedCashboxesIds, id

    haveCashboxExtraPermission: (id, permission) ->
        PERMISSIONS_EXTRA = require("@@constants/permissions/extra").default

        cashbox = IUser.ACCESS.cashbox.find((item) -> item.id == id)

        cashbox and cashbox.access_lvl != PERMISSIONS_EXTRA.ACCESS_LVL.NONE and haveAccess(cashbox.permissions, permission)

    haveWarehouseAccess: (id) ->
        PERMISSIONS_EXTRA = require("@@constants/permissions/extra").default

        allowedWarehouseIds = IUser.ACCESS.warehouse
            .filter(({access_lvl}) -> access_lvl != PERMISSIONS_EXTRA.ACCESS_LVL.NONE)
            .map(({id}) -> id)

        haveAccess allowedWarehouseIds, id
    
    haveWarehouseExtraPermission: (id, permission) ->
        PERMISSIONS_EXTRA = require("@@constants/permissions/extra").default

        warehouse = IUser.ACCESS.warehouse.find((item) -> item.id == id)

        warehouse and warehouse.access_lvl != PERMISSIONS_EXTRA.ACCESS_LVL.NONE and haveAccess(warehouse.permissions, permission)

    haveWarehouseAccessAsync: (payload, {sccb, ercb}) ->
        fetchRequest({
            url: "/access/warehouse",
            payload,
            sccb,
            ercb
        })

    update: (cb) ->
        fetchRequest({
            url: "/access/state"
            sccb: (state) ->
                IUser.setState {'ACCESS': state}
                cb state
        })
