/**
 * Selects the target table name without a fletcherUid.
 * @param {string} name - name of table from fletcher in format `name-fletcherUid`.
 * @returns {string} name of table without `fletcherUid`.
 */
export const getBaseFletcherTableName = (name: string): string => {
  const lastIndex = name.lastIndexOf('-');

  return name.substring(0, lastIndex);
};
