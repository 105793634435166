import { createSlice } from '@reduxjs/toolkit';
import _isArray from 'lodash/isArray';
import _omit from 'lodash/omit';

const FLETCHER_NAMES = [
  'products',
  'service',
  'service-simple',
  'print-stickers',
  'order-products',
  'feature-flag',
  'move-assets',
  'sales',
  'write-off',
  'bundle',
  'client-refund',
];

export const initialState = FLETCHER_NAMES.reduce(
  (acc, name) => ({
    ...acc,
    [name]: {
      dropdownData: {},
      additionalData: {},
      tableData: {},
      fletcherIds: [],
    },
  }),
  {},
);

const fletcherSlice = createSlice({
  name: 'fletcher',
  initialState,
  reducers: {
    setFletcherAdditionalData(state, { payload }) {
      const { name, uid, data } = payload;
      const fletcherUid = uid || state[name].fletcherIds[0];

      state[name].additionalData[fletcherUid] = {
        ...state[name].additionalData[fletcherUid],
        ...data,
      };
    },
    setFletcherTableData(state, { payload }) {
      const { name, uid, data } = payload;
      const fletcherUid = uid || state[name].fletcherIds[0];

      state[name].tableData[fletcherUid] = data;
    },
    addFletcherTableItem(state, { payload }) {
      const { name, uid, data } = payload;
      const fletcherUid = uid || state[name].fletcherIds[0];
      const preparedData = _isArray(data) ? data : [data];

      state[name].tableData[fletcherUid] = [
        ...preparedData,
        ...state[name].tableData[fletcherUid],
      ];
    },
    updateFletcherTableItem(state, { payload }) {
      const { name, uid, id, data } = payload;
      const fletcherUid = uid || state[name].fletcherIds[0];

      state[name].tableData[fletcherUid] = state[name].tableData[
        fletcherUid
      ].map((item) => {
        const itemId = item._uniqueId || item.id;

        if (itemId) {
          return itemId === id ? data : item;
        }

        return item.entity === data.entity ? data : item;
      });
    },
    removeFletcherTableItem(state, { payload }) {
      const { name, uid, data } = payload;

      const fletcherUid = uid || state[name].fletcherIds[0];

      state[name].tableData[fletcherUid] = state[name].tableData[
        fletcherUid
      ].filter((item) => {
        const itemId = item._uniqueId || item.id;

        return itemId !== data;
      });
    },
    setFletcherUid(state, { payload }) {
      const { name, id } = payload;

      if (!state[name].fletcherIds.includes(id)) {
        state[name].fletcherIds.push(id);
        state[name].additionalData[id] = {};
        state[name].tableData[id] = [];
      }
    },
    removeFletcherUid(state, { payload }) {
      const { name, id } = payload;

      state[name].fletcherIds = state[name].fletcherIds.filter(
        (uid) => uid !== id,
      );
      state[name].additionalData = _omit(state[name].additionalData, [id]);
      state[name].tableData = _omit(state[name].tableData, [id]);
    },
  },
});

export const {
  setFletcherAdditionalData,
  setFletcherTableData,
  addFletcherTableItem,
  updateFletcherTableItem,
  removeFletcherTableItem,
  setFletcherUid,
  removeFletcherUid,
} = fletcherSlice.actions;

export default fletcherSlice.reducer;
