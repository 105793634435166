import i18next from 'i18next';
import _intersection from 'lodash/intersection';

export const setGlobalTranslateFn = () => {
  window.__ = i18next.t;
};

export const getNamespaces = ({
  baseNs,
  nicheNs,
  landingNs,
  existNamespaces,
}) => {
  if (existNamespaces.length) {
    const possibleNamespaces = [
      nicheNs && landingNs && `${nicheNs}_${landingNs}`,
      nicheNs,
      landingNs,
      baseNs,
    ];

    return _intersection(possibleNamespaces, existNamespaces);
  }

  return [baseNs];
};
