import { fetchRequest } from '@@services/transport/fetch';

export const checkAuth = (options = {}) => {
  return fetchRequest({
    url: '/check-auth',
    ...options,
  });
};

export const requestRecovery = (payload, options) => {
  return fetchRequest({
    url: '/request-recovery',
    method: 'POST',
    payload,
    ...options,
  });
};

export const recoverPassword = (payload, options) => {
  return fetchRequest({
    url: '/recover-password',
    method: 'POST',
    payload,
    ...options,
  });
};

export const signupStep1 = (payload, options) => {
  return fetchRequest({
    url: '/signup/step1',
    method: 'POST',
    payload,
    ...options,
  });
};

export const finishRegistration = (payload, options) => {
  return fetchRequest({
    url: '/settings/finish-registration',
    method: 'POST',
    payload,
    ...options,
  });
};

export const getCompanyNiches = (payload, options) => {
  return fetchRequest({
    url: '/get-company-niches',
    method: 'POST',
    payload,
    ...options,
  });
};

export const getCompanyTemplates = (payload, options) => {
  return fetchRequest({
    url: '/get-company-templates',
    method: 'POST',
    payload,
    ...options,
  });
};

export const signupStep3 = (payload, options) => {
  return fetchRequest({
    url: '/signup/step3',
    method: 'POST',
    payload,
    ...options,
  });
};

export const refreshToken = (payload, options) => {
  return fetchRequest({
    url: '/token/refresh',
    method: 'POST',
    payload,
    ...options,
  });
};

export {
  useDoLoginGoogleMutation,
  useDoLoginMutation,
  useSignUpGoogleMutation,
} from './auth';
