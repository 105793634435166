{fetchRequest} = require("@@services/transport/fetch")

{merge} = require "lib/fp"
{enable, disable} = require "lib/DOM"
{render, count} = require "lib/helpers"
{serialize} = require "lib/form"
{validate, required, email} = require "lib/plugins/Validate"
{revealDialog} = require "interface/IReveal"
{isMobile} = require "lib/mobile"
{tpl, renderEmailBlock, renderRoleBlock, renderValidationError} = require "app/Onboarding/views/addingEmployees"

# Key should be identical with the server side key name.
MAX_COUNT_EMAILS = 30

DEFAULT =
    closeonesc: false
    blurbackground: true
    closeonbackgroundclick: false
    top: if isMobile() then 10 else 40


getValidationSchema = (fields, data) ->
    VALIDATION_SCHEMA = []
    count = 0
    fields.map (index) ->
        VALIDATION_SCHEMA.push(
          {"email-#{index}": [email]}
        )
        if data["email-#{index}"]
            count += 1
    if !count
        VALIDATION_SCHEMA.push(
          {"email-0": [required]}
        )
    VALIDATION_SCHEMA

TRANSPORT =
    sendInvitation: (payload, {sccb, ercb}) ->
        fetchRequest({
            url: "/settings/invite-group-employees"
            method: "POST"
            payload
            sccb
            ercb
        })


onAccept = (dialog) ->
    fields = dialog.find(".js-emails").find('input')
    dialog_data = merge serialize(dialog)
    isValid = validate dialog, getValidationSchema(fields, dialog_data)
    return undefined unless isValid

    disable dialog.find "button"

    ercb = ->
        enable dialog.find "button"

    sccb = ->
        # Restarting app to retrieve new settings.
        window.location.replace("/")

    invites = []
    fields.map (index) ->
        invites.push({
            "email": dialog_data["email-#{index}"],
            "role_id": dialog_data["role_id-#{index}"]
        }) if dialog_data["email-#{index}"]

    TRANSPORT.sendInvitation {invites}, {sccb, ercb}

widgets = (dialog, ROLES) ->

    appendTpl = (selector, template) -> dialog.find(selector).append render template

    dialog.find(".js-add-more").on "click", ->
        dialog.find("#more-valid-error").remove()
        nodes_count = dialog.find('input').length
        filled_nodes_count = (dialog.find('input').filter (i, node) -> node.value).length
        if nodes_count < MAX_COUNT_EMAILS
            if filled_nodes_count < nodes_count
                appendTpl ".js-emails", renderValidationError
                dialog.find('input').on "click", ->
                    dialog.find("#more-valid-error").remove()
            else
                appendTpl '.js-email-roles', ['div', {'class': 'emailRolesRow'},
                    renderEmailBlock nodes_count
                    renderRoleBlock nodes_count, ROLES
                ]

    dialog.find(".js-do-later").on "click", ->
        (jQuery ".b-modal").trigger "reveal:close"
        window.location.replace("/")

invitationModal = ({ROLES}) ->
    dialog = jQuery render tpl, isMobile(), ROLES
    revealDialog dialog, onAccept, DEFAULT
    widgets dialog, ROLES


module.exports = {invitationModal}