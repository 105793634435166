import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import { persistor, store } from '@@redux/store';

export default {
  store,
  persistor,
  history: syncHistoryWithStore(createBrowserHistory(), store),
};
