import _isFunction from 'lodash/isFunction';

const intercom = {
  // Check intercom snippet availability in global scope.
  intercom(event, params) {
    if (_isFunction(window.Intercom)) {
      window.Intercom(event, params);
    }
  },

  // Clears user conversation anytime he's logout.
  clearChat() {
    this.intercom('shutdown');
  },

  // Triggers the JavaScript with some new user data to look for new messages that should
  // be displayed to the current user and show them.
  updateChat(options) {
    this.intercom('update', options);
  },

  // This will open the Messenger as if a new conversation was just created.
  initChat(options) {
    this.intercom('showNewMessage', options);
  },

  // You can trigger a tour based on an action a user or visitor takes.
  startTour(tourId) {
    this.intercom('startTour', tourId);
  },
};

export default intercom;
