export const CHECKBOX = 0;
export const TEXTFIELD = 1;
export const TEXTAREA = 2;
export const SELECT = 3;
export const DATETIME = 4;
export const DATE = 5;
export const NUMBER = 6;
export const AD_CAMPAIGN = 7;
export const HEADER = 8;
export const COMPOSITE = 9;
export const DURATION = 10;
export const ASSET_HIERARCHY = 11;
export const CLIENT = 12;
export const HIERARCHY_TEXTFIELD = 13;
export const MONEY = 14;
export const COUNT = 15;
export const SEARCHABLE_CATEGORY = 18;
export const ASSET = 19;
export const RESOURCE = 20;
export const PAYER = 21;
export const PRODUCT_UOM = 22;

export const NUMBER_FIELDS = [DATETIME, DATE, NUMBER, PRODUCT_UOM];

export const ALL_FIELDS = [
  CHECKBOX,
  TEXTFIELD,
  TEXTAREA,
  SELECT,
  DATETIME,
  DATE,
  NUMBER,
  AD_CAMPAIGN,
  HEADER,
  COMPOSITE,
  DURATION,
  ASSET_HIERARCHY,
  CLIENT,
  HIERARCHY_TEXTFIELD,
  MONEY,
  COUNT,
  SEARCHABLE_CATEGORY,
  ASSET,
  RESOURCE,
  PAYER,
  PRODUCT_UOM,
] as const;
