{fetchRequest} = require("@@services/transport/fetch")

# Updates global app state related to branch config.
# -> object state: new branch state.
# Returns object: new branch state.
setState = (state) ->
    # Updating module state.
    module.exports[k.toUpperCase()] = v for own k, v of state

    # Returning value.
    module.exports

update = (cb) ->
    fetchRequest({
        url: "/settings/branch"
        sccb: ({branch}) -> 
            newState = setState(branch)
            
            cb(newState) if cb
    })

module.exports = {
    setState,
    update
}
