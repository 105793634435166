export const CATEGORY_TYPES = {
  ALL: -1,
  PRODUCTS: 0,
  SERVICES: 1,
  BUNDLES: 2,
} as const;

export const PRODUCTS_CATEGORY_ROOT_ID = -1;
export const SERVICES_CATEGORY_ROOT_ID = -2;
export const BUNDLES_CATEGORY_ROOT_ID = -3;

export const DEFAULT_CATEGORY_ID = -1;
