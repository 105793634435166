# Marketing source.

assign = require "lodash/assign"

{toInt, toBool} = require "lib/helpers"
{plain} = require "lib/reflection"
{fetchRequest} = require("@@services/transport/fetch")
{now} = require("@@helpers/format/date")


# TODO: rework in general case.
# The reason for this solution is multiple calls in the cycle during rendering the Order widgets.
class ICache
    data: undefined

    version: undefined

    timeout: 2000  # 5 Seconds

    constructor: ->
        @set undefined

    set: (data) ->
        @data = data
        @version = now()

    get: (handler) ->
        diff = now() - @version
        if diff < @timeout
            return @data


Cache = new ICache()


# Marketing source.
class AdCampaign

    # int source id.
    id: 0

    # string: friendly name.
    name: ""

    description: ""

    branches_ids: []

    start_at: null

    finish_at: null

    # static prop: title max length.
    @title_max: -> 100

    # bool: deleted?
    deleted: false

    # Ctor.
    constructor: (kwargs={}) ->
        @id = toInt kwargs.id, @id
        @name = kwargs.name or @name
        @description = kwargs.description or @description
        @branches_ids = kwargs.branches_ids or @branches_ids
        @start_at = kwargs.start_at or @startDate
        @finish_at = kwargs.finish_at or @endDate
        @deleted = toBool kwargs.deleted, @deleted


# Marketing sources interface.
class IAdCampaign

    BASE_URL = "/settings"

    # Returns string: marketing sources list URL.
    @urlList: -> "#{BASE_URL}/get-ad-campaigns"

    # Returns void: retrieves marketing sources list.
    # -> kwargs: void sccb: success callback.
    # -> kwargs: void ercb: error callback.
    @listVisible: (kwargs={}) ->
        fetchRequest(assign {}, kwargs, {
            url: @urlList()
            payload: {_only: ["id", "name"]}
            sccb: (response) ->
                data = response.data.map (x) -> new AdCampaign x
                kwargs.sccb assign {}, response, {data}
        })

    # Returns void: loads marketing source.
    # -> int id: target marketing source id.
    # -> kwargs: void sccb: success callback.
    # -> kwargs: void ercb: error callback.
    @get: (id, kwargs={}) ->
        fetchRequest(assign {}, kwargs, {
            url: "#{BASE_URL}/get-ad-campaign"
            payload: {id}
            sccb: (x) -> kwargs.sccb new AdCampaign x
        })

    @getActiveCampaigns: (sccb, payload = {}) ->
        data = Cache.get()
        if data isnt undefined
            return sccb data

        fetchRequest({
            url: "/settings/get-active-ad-campaigns",
            payload,
            sccb: (response) ->
                Cache.set(response)
                sccb response
        })

    # Returns void: creates marketing source.
    # -> AdCampaign ad_campaign: new marketing source data.
    # -> kwargs: void sccb: success callback.
    # -> kwargs: void ercb: error callback.
    @create: (ad_campaign, kwargs={}) ->
        fetchRequest(assign {}, kwargs, {
            url: "#{BASE_URL}/create-ad-campaign"
            method: "POST"
            payload: plain(ad_campaign)
            sccb: ({id}) -> kwargs.sccb toInt id
        })

    # Returns void: updates marketing source.
    # -> AdCampaign ad_campaign: new marketing source data.
    # -> kwargs: void sccb: success callback.
    # -> kwargs: void ercb: error callback.
    @update: (ad_campaign, kwargs={}) ->
        fetchRequest(assign {}, kwargs, {
            url: "#{BASE_URL}/update-ad-campaign"
            method: "POST"
            payload: plain(ad_campaign)
        })

    # Returns void: removes marketing source.
    # -> [int] ids: target marketing source ids.
    # -> kwargs: void sccb: success callback.
    # -> kwargs: void ercb: error callback.
    @remove: (ids, kwargs={}) ->
        fetchRequest(assign {}, kwargs, {
            url: "#{BASE_URL}/delete-ad-campaign"
            method: "POST"
            payload: {ids}
        })


module.exports = {
    AdCampaign
    IAdCampaign
}
