import _isArray from 'lodash/isArray';
import _isDate from 'lodash/isDate';
import _isNumber from 'lodash/isNumber';
import _reduce from 'lodash/reduce';

import { convertTimezones } from '@@helpers/format/date';
import { migrateDates as migrateAnalyticDates } from '@@redux/analytics/slice';
import { migrateDates as migrateScheduleDates } from '@@redux/schedule/slice';
import { store } from '@@redux/store';
import { storageGet, storageSet } from '@@services/storage';

const { KEY } = require('interface/IStorage');

export const migrateDateSelectors = (
  dateSelectors,
  prevTimezone,
  nextTimezone,
) => {
  return _reduce(
    dateSelectors,
    (res, range, key) => {
      if (_isArray(range)) {
        res[key] = range.map((date) => {
          return _isDate(date) || _isNumber(date)
            ? convertTimezones(date, prevTimezone, nextTimezone)
            : date;
        });
      }

      return res;
    },
    dateSelectors,
  );
};

export const migrateOrdersFilters = (
  ordersFiltes,
  prevTimezone,
  nextTimezone,
) => {
  return _reduce(
    ordersFiltes,
    (result, company, companyKey) => ({
      ...result,
      [companyKey]: _reduce(
        company,
        (res, filter, filterKey) => {
          if (_isArray(filter?.filters)) {
            res[filterKey] = {
              ...filter,
              filters: filter.filters.map((item) => {
                if (_isArray(item?.created_at)) {
                  return {
                    ...item,
                    created_at: item.created_at.map((date) => {
                      if (_isDate(date) || _isNumber(date)) {
                        return convertTimezones(
                          date,
                          prevTimezone,
                          nextTimezone,
                        );
                      }

                      return date;
                    }),
                  };
                }

                return item;
              }),
            };
          }

          return res;
        },
        company,
      ),
    }),
    ordersFiltes,
  );
};

export const migrateWorkScheduleSettings = (
  settings,
  prevTimezone,
  nextTimezone,
) => {
  if (_isArray(settings?.dates)) {
    return {
      ...settings,
      dates: settings.dates.map((date) => {
        return _isDate(date) || _isNumber(date)
          ? convertTimezones(date, prevTimezone, nextTimezone)
          : date;
      }),
    };
  }

  return settings;
};

const migratePayrollCalculationDateFilter = (
  dateFilter,
  prevTimezone,
  nextTimezone,
) => {
  if (_isArray(dateFilter?.dateRange)) {
    return {
      ...dateFilter,
      dateRange: dateFilter.dateRange.map((date) => {
        return convertTimezones(date, prevTimezone, nextTimezone);
      }),
    };
  }

  return dateFilter;
};

export const migrateSchedule = (prevTimezone, nextTimezone) => {
  store.dispatch(migrateScheduleDates({ prevTimezone, nextTimezone }));
};

export const migrateAnalytics = (prevTimezone, nextTimezone) => {
  store.dispatch(migrateAnalyticDates({ prevTimezone, nextTimezone }));
};

export const migrateLocalStorageDates = (prevTimezone, nextTimezone) => {
  migrateSchedule(prevTimezone, nextTimezone);
  migrateAnalytics(prevTimezone, nextTimezone);

  if (prevTimezone !== nextTimezone) {
    const dateSelectors = storageGet(KEY.DATE_SELECTORS);

    if (dateSelectors) {
      const migratedData = migrateDateSelectors(
        dateSelectors,
        prevTimezone,
        nextTimezone,
      );

      storageSet(KEY.DATE_SELECTORS, migratedData);
    }

    const orderFiltes = storageGet(KEY.FILTERS);

    if (orderFiltes) {
      const migratedData = migrateOrdersFilters(
        orderFiltes,
        prevTimezone,
        nextTimezone,
      );

      storageSet(KEY.FILTERS, migratedData);
    }

    const workScheduleSettings = storageGet(KEY.WORK_SCHEDULE_SETTINGS);

    if (workScheduleSettings) {
      const migratedData = migrateWorkScheduleSettings(
        workScheduleSettings,
        prevTimezone,
        nextTimezone,
      );

      storageSet(KEY.WORK_SCHEDULE_SETTINGS, migratedData);
    }

    const payrollCalculationDateFilter = storageGet(
      KEY.PAYROLL_CALCULATION_DATE_FILTER,
    );

    if (payrollCalculationDateFilter) {
      const migratedData = migratePayrollCalculationDateFilter(
        payrollCalculationDateFilter,
        prevTimezone,
        nextTimezone,
      );

      storageSet(KEY.WORK_SCHEDULE_SETTINGS, migratedData);
    }
  }
};
