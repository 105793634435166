# NOTE this is very legacy realization.
# Should be removed from project and replaced by media-queries.

Android = -> navigator.userAgent.match(/Android/i) isnt null

BlackBerry = -> navigator.userAgent.match(/BlackBerry/i) isnt null

iOS = -> navigator.userAgent.match(/iPhone|iPad|iPod/i) isnt null

Opera = -> navigator.userAgent.match(/Opera Mini/i) isnt null

Windows = -> navigator.userAgent.match(/IEMobile/i) isnt null

isMobile = -> Android() or BlackBerry() or iOS() or Opera() or Windows()


module.exports = {Android, BlackBerry, iOS, Opera, Windows, isMobile}
