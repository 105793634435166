import { fetchRequest } from '@@services/transport/fetch';

export const getFeatureFlag = (id, options = {}) => {
  return fetchRequest({
    url: `/feature-flags/${id}/get`,
    payload: id,
    isStaff: true,
    ...options,
  });
};

export const updateFeatureFlag = (payload, options = {}) => {
  const { id, ...rest } = payload;

  return fetchRequest({
    url: `/feature-flags/${id}/update`,
    payload: rest,
    method: 'PATCH',
    isStaff: true,
    ...options,
  });
};

export const getAllFeatureFlags = (options = {}) => {
  return fetchRequest({
    url: '/settings/feature-flags',
    ...options,
  });
};
