isUndefined = require "lodash/isUndefined"

{merge} = require "lib/fp"
{HOSTNAME} = require "app/Env/settings"
{first, bool} = require "lib/helpers"
{bool} = require "lib/helpers"

{history} = require("@@router/history").default


_navigate = (params) ->
    history.push
        pathname: window.location.pathname,
        search: paramifySearch params

###
    Build an absolute url based on the given `part`. This function will ignore
        starting slash in the given `part`.

    Example:
        => absoluteURL("/blog/my-super-post")
        //orderry.com/blog/my-super-post

        => absoluteURL("next-page/resource")
        //orderry.com/next-page/resource
###
absoluteURL = (part) ->
    part = if part[0] is "/" then part else "/" + part
    "//" + HOSTNAME + part

sanitizeURL = (url) ->
    return "" unless url
    first(url.split("#").slice 1) or ""


###
    Example:
        => paramifyURL("/my/url", {'arg': 1, 'type': 'b'})
        "/my/url?arg=1&type=b"
###
paramifyURL = (url, params) ->
    return url unless bool params
    url + "?" + jQuery.param params

buildFilterURL = (url, query, page) ->
    pred = if url.indexOf('?') < 0 then "?" else "&"
    url + pred + jQuery.param {q: query, page}

buildOnlyURL = (url, fields=[]) ->
    fields = fields.filter (f) -> !!f

    return url if fields.length is 0

    pred = if url.indexOf('?') < 0 then "?" else "&"
    url + pred + jQuery.param {_only: fields}

toQueryString = (paramMap) ->
      qStrPairs = []
      for own key, value of paramMap
          qStrPairs.push encodeURIComponent(key) + "=" + encodeURIComponent value

      if bool qStrPairs then return qStrPairs.join '&' else return ""

qsToParamMap = (queryPlace) ->
    idx = queryPlace.indexOf "?"
    paramStr = if idx > -1
        queryPlace.slice queryPlace.indexOf("?") + 1
    else
        ""

    paramMap = {}

    if paramStr.length > 0
        params = paramStr.split '&'
        for p in params
            p = p.split '='
            value = decodeURIComponent p[1]
            if value is "true" or value is "false"
                value = JSON.parse value

            paramMap[decodeURIComponent p[0]] = value

    paramMap

querySearchToParamMap = (query) ->
    q = if !isUndefined(query) then query else window.location.search
    qsToParamMap(q)

getUrlParam = (key) -> querySearchToParamMap()[key]

paramifyAny = (paramMap, queryPlace) ->
    params = toQueryString paramMap
    beforeSearch = first queryPlace.split '?'
    return "" unless params

    beforeSearch + "?" + params

paramifySearch = (paramMap) ->
    paramifyAny(paramMap, window.location.search)

updateUrlParam = (param) ->
    paramMap = merge querySearchToParamMap(), param
    _navigate paramMap

delUrlParam = (key) ->
    paramMap = querySearchToParamMap()
    delete paramMap[key]
    _navigate paramMap


module.exports = {
    buildFilterURL, buildOnlyURL, getUrlParam, updateUrlParam, delUrlParam,
    sanitizeURL, absoluteURL, paramifyURL, querySearchToParamMap
}
