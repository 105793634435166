import { sleep } from '@@helpers/common/sleep';
import {
  hideAllCallCard,
  hideCallCard,
  setCallCardShow,
  setCallCardShowAll,
  showCallCard,
} from '@@redux/callCards/slice';

const setCallCardShowAsync = (dispatch, id) => {
  return new Promise((resolve) => {
    resolve(
      dispatch(
        id
          ? setCallCardShow({
              isShow: false,
              id,
            })
          : setCallCardShowAll({ isShow: false }),
      ),
    );
  });
};

const makeDelayedHideCallCardAction = (callback, payload) => {
  return async (dispatch) => {
    await setCallCardShowAsync(dispatch, payload);
    await sleep(350);
    callback();
  };
};

export const callCardAsyncAnimationMiddleware = (store) => (next) => {
  let hideCallCardPromise = Promise.resolve();

  return async (action) => {
    const { dispatch, getState } = store;

    if (
      action.type === hideCallCard.type ||
      action.type === hideAllCallCard.type
    ) {
      const { callCards } = getState();

      hideCallCardPromise = callCards.length
        ? dispatch(
            makeDelayedHideCallCardAction(() => next(action), action.payload),
          )
        : hideCallCardPromise;

      return hideCallCardPromise;
    }

    if (action.type === showCallCard.type) {
      await hideCallCardPromise;

      return next(action);
    }

    return next(action);
  };
};
