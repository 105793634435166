isArray = require "lodash/isArray"
sortBy = require "lodash/sortBy"
assign = require "lodash/assign"
omit = require "lodash/omit"

{toInt, toBool, bool} = require "lib/helpers"
{only} = require "lib/fp"

{TField, IFieldSvc, onlyVisible} = require "lib/forms/fields"
{reload, get, bind, create} = require "lib/forms/transport"


# Relation between product field and response from backend
class TProductFormEntry

  # int: context position.
  pos: 0

  # bool: is field required in context of product type?
  required: false

  # string: default value.
  default_value: ""

  # bool: Forbidden editing after creation
  is_forbidden_after_creation: false

  # bool: Forbidden deleting
  is_forbidden_for_deletion: false

  constructor: (o) ->
    @required = toBool o.required, @required
    @is_forbidden_after_creation = toBool o.is_forbidden_after_creation, @is_forbidden_after_creation
    @is_forbidden_for_deletion = toBool o.is_forbidden_for_deletion, @is_forbidden_for_deletion
    @default_value = o.default_value or @default_value
    @pos = toInt o.pos, @pos


# Product form field.
class TProductField extends TField

  types: [ ]

  constructor: (o) ->
    super o
    @types = (
      if isArray(o.types)
        o.types.map (item) ->
          new TProductFormEntry item
      else @types
    )


# Fields cache.
# Only for internal usage!
cache = []

initCache = (objects = []) ->
  return objects if not objects.length
  cache = objects.map (obj) -> new TProductField obj

# Updates cache value.
# -> TProductField field1: new field data.
# Returns TProductField: field1.
reCache = (field1) ->
  # Getting field index.
  in_composite = []

  index = -1
  for field, i in cache
    index = i if field.id is field1.id
    if field1.values
      in_composite.push(i) if field.id.toString() in field1.values.split(",")

  # Updating cache.
  if index is -1 then cache.push field1
  else cache[index] = field1
  if field1.types.length
    for i in in_composite
      cache[i].types[0].is_forbidden_after_creation = field1.types[0].is_forbidden_after_creation

  # Returning passed field.
  field1


# Product form field service.
class IProductFieldSvc extends IFieldSvc

  # const string: API base URL.
  URL_BASE = "/forms/products"

  # const int: backend field type.
  fieldtype = "product"

  reload: (cbs) ->
    reload {fieldtype}, {
      sccb: (res) ->
        initCache res
        cbs?.sccb()
      }

  # -> opts: bool first: insert field first or last?
  # -> opts: void sccb, ercb: callbacks.
  create: (field, opts={ }) =>
    # Transforming field to payload.
    # Field is binided to first type in types array.
    {book_id, types} = field
    binding = types[0]

    field_data = omit field, [
      "types"
      "book_id" unless book_id
    ]

    # set entity_id = -1 as NONE_TYPE
    payload = only {
      fieldtype
      field: JSON.stringify field_data
      place: if binding then JSON.stringify {
        first: opts.first or false
        card_visible: binding.card_visible
        is_forbidden_after_creation: binding.is_forbidden_after_creation
        is_forbidden_for_deletion: binding.is_forbidden_for_deletion
        required: binding.required or false
        entity_id: -1
      }
    }, bool

    create payload, assign {}, opts, {
      sccb: (res) => opts.sccb reCache new TProductField res
    }

  # -> int id: field id.
  get: (id, {sccb}) ->
    get {id, fieldtype}, {
      sccb: (res) -> sccb reCache new TProductField res if sccb
    }

  # -> int id: field id.
  # -> opts: bool first: insert field first or last?
  # -> opts: void sccb, ercb: callbacks.
  bind: (id, entity_id, opts={}) ->
    payload = {
      id
      fieldtype
      place: JSON.stringify {
        first: opts.first or false
        entity_id
        required: false
      }
    }

    bind payload, {
      sccb: (field) -> opts.sccb reCache new TProductField field
    }

  isExist: ({types}) -> !!types.length

  getVisFields: (isForm = true) ->
    sorted = []
    visibleFields = onlyVisible(cache)

    visibleFields.map (field) ->
      field.types.length && field.types.map ({pos}) ->
        sorted.push({field, pos})

    if isForm and sorted.length
      sortBy(sorted, 'pos').map ({field}) -> field
    else
      sortBy(visibleFields, 'id')


# Use it everywhere in project.
productfield_svc = new IProductFieldSvc()


module.exports = {
  initCache
  reCache
  productfield_svc
  TProductField
  TProductFormEntry
}