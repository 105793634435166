import { fetchRequest } from '@@services/transport/fetch';

export const createAnalyticalConfig = (payload, options = {}) => {
  return fetchRequest({
    url: `/reports/analytical/config/create`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const deleteAnalyticalConfig = (payload, options = {}) => {
  return fetchRequest({
    url: `/reports/analytical/config/delete`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const updateAnalyticalConfig = (payload, options = {}) => {
  return fetchRequest({
    url: `/reports/analytical/config/update`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const getAnalyticalConfigs = (payload, options = {}) => {
  return fetchRequest({
    url: `/reports/analytical/configs`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const getAnalyticalAggregate = (payload, { params, ...options }) => {
  const { object } = params;

  return fetchRequest({
    url: `/reports/analytical/cube/${object}/aggregate`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const getAnalyticalCubes = (payload, options = {}) => {
  return fetchRequest({
    url: `/reports/analytical/cubes`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const getAnalyticalModel = (payload, { params, ...options }) => {
  const { name } = params;

  return fetchRequest({
    url: `/reports/analytical/cube/${name}/model`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const getAnalyticalMembers = (payload, { params, ...options }) => {
  const { object, dimension } = params;

  return fetchRequest({
    url: `/reports/analytical/cube/${object}/members/${dimension}`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const getAnalyticalDefaultConfigs = (payload, options = {}) => {
  return fetchRequest({
    url: `/reports/analytical/default-configs`,
    method: 'POST',
    payload,
    ...options,
  });
};
