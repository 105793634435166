{select} = require "lib/layout"

renderEmailBlock = (count, type = '2') ->
  ["div", {"class": "col-sm-7 h-p-0 pull-left js-emails"}
    ["label", {"class": "b-modal__label #{if count > 0 then 'labelShowOnMobile' else ''}", "for": "l-email-0"}
      __("Email")]
    ["input"
      {"id": "l-email-#{type}", "class": "form-control"}
      {"type": "text", "name": "email-#{count}"}]
  ]
  

renderRoleBlock = (count, ROLES) ->
  ["div", {"class": "col-sm-5 h-p-0 pull-right js-roles"}
    ["label", {"class": "b-modal__label #{if count > 0 then 'labelShowOnMobile' else ''}", "for": "l-auth-country"}
      __("Role")]
    select( ROLES.map ({id, name}) -> ["option", { "value": id}, "#{name}"]
    { "class": "js-me-role-id b-sel_size_sm"
    , "name": "role_id-#{count}"})
  ]

renderValidationError = () ->
  ["span"
    ,{"class": "b-link_color_red"}
    , {"id": "more-valid-error"}
    , __("You have unfilled fields")
  ]


tpl = (isMobile, ROLES) ->
  ["div", {"class": "b-modal b-modal_type_registration #{if isMobile then "b-modal_type_registration_mobile" else ""}"}
    ["h2", {"class": "h-ta-c"}
      __("Invite colleagues to work together")]

    ["p", {"class": "h-ta-c h-mt-15 h-fs-12"}
      __("Together, you will learn much more quickly and discover the possibilities of {{_brand_}}")]

    ["div", {"class": "#{if isMobile then "h-mt-15" else "h-mt-25"} clearfix js-email-roles"}
      ['div', {'class': 'emailRolesRow'}
        renderEmailBlock(0, 0)
        renderRoleBlock(0, ROLES)
      ]
    ]
    ["div", {"class": "b-link b-link_color_blue js-add-more"}
      __("+ Another employee")
    ]

    ["div", {"class": "h-mt-15 clearfix"}
        ["p", {"class": "h-fs-12 h-c-muted"}
          __("Each employee will receive an invitation to this e-mail. You can change the assigned role or edit it at any time after it is registered")
        ]

        ["div", {"class": "h-ta-c h-mt-25"}
          ["button", {"class": "b-button b-button_type_cta b-button_color_green js-submit"}
            __("Send and get started")
          ]
        ]

        ["div", {"class": "h-ta-c h-mt-5"}
          ["button", {"class": "b-button b-button_type_cta b-button_type_green_text js-do-later"}
            __("I'll do it later")
         ]
        ]
      ]
  ]


module.exports = {tpl, renderEmailBlock, renderRoleBlock, renderValidationError}
