export const IMAGE_OBJECT_TYPE = {
  ENTITY: 0,
  BOOK: 1,
  ASSET: 2,
};

export const WAREHOUSE_TYPE = {
  PRODUCT: 0,
  ASSET: 1,
  SERVICE: 2,
  WORK: 3,
} as const;

// Must be synced with backend. See: packages/backend/remonline/core/constants.py
export const WAREHOUSE_ENTITY_TYPE = {
  ASSET: 1,
  SERVICE: 2,
  WORK: 3,
  SERIAL: 4,
  NON_SERIAL: 5,
  BUNDLE: 6,
} as const;

export const WAREHOUSE_STORAGE_TYPE = {
  WAREHOUSE: 0,
  EMPLOYEE: 1,
  CLIENT: 2,
};

export const LOCATION_TYPES = {
  ALL: 'all',
  COMPANY: 'company',
  BRANCH: 'branches',
  EMPLOYEE: 'employees',
  CLIENT: 'client',
  CLIENTS: 'clients',
};

export const WARRANTY_UNIT = {
  DAYS: 0,
  MONTHS: 1,
} as const;

export const WAREHOUSE_UUID_TYPE = {
  NONE: 0,
  NAME: 1,
  SKU: 2,
  CODE: 3,
};
