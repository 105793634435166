assign = require "lodash/assign"

{fetchRequest} = require("@@services/transport/fetch")

URL =
  RELOAD: "/forms/reload"
  GET: "/forms/get"
  BIND: "/forms/bind"
  CREATE: "/forms/create"
  SET_FORBIDDEN: "/forms/set-is-forbidden-after-creation"

reload = (payload, opts = {}) ->
  fetchRequest(assign {}, opts, {
    url: URL.RELOAD
    payload
  })

get = (payload, opts = {}) ->
  fetchRequest(assign {}, opts, {
    url: URL.GET
    payload
  })

bind = (payload, opts = {}) ->
  fetchRequest(assign {}, opts, {
    url: URL.BIND
    method: "POST"
    payload
})

create = (payload, opts = {}) ->
  fetchRequest(assign {}, opts, {
    url: URL.CREATE
    method: "POST"
    payload
  })

setForbiddenAfterCreation = (payload, opts = {}) ->
  fetchRequest(assign {}, opts, {
    url: URL.SET_FORBIDDEN
    method: "POST"
    payload
  })


module.exports = {
  reload, get, bind, create, setForbiddenAfterCreation
}