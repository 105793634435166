import { fetchRequest } from '@@services/transport/fetch';

export const resendEmailConfirmation = (options) => {
  fetchRequest({
    url: '/resend-email-confirmation',
    method: 'POST',
    payload: {},
    ...options,
  });
};

export const getMarkNotification = (type, options) => {
  fetchRequest({
    url: '/notifications/json/mark-notification',
    method: 'POST',
    payload: { type },
    ...options,
  });
};

export const getSystemNotification = () => {
  return fetchRequest({
    url: '/settings/system-notifications',
  });
};
