###
Standard dialog app.
###

{merge} = require "lib/fp"
{error} = require "lib/logger"
{root, qs, click} = require "lib/tplapp"
{ render, uniq, copy, bool, isjQuery
, isString, toBool } = require "lib/helpers"
{focus, disable, enable} = require "lib/DOM"
{hasErrors} = require "lib/plugins/Validate"
{atom, deref, swap} = require "lib/atom"
{spinner} = require "lib/Layouts"
{offEnter} = require "lib/listener"
{store} = require("@@redux/store")

# Current app.
{THeader, IHeader} = require "lib/widgets/Dialog/header"
{TFooter, IFooter} = require "lib/widgets/Dialog/footer"
{NS, hideWorkspace, showWorkspace} = require "lib/widgets/Dialog/lib"


# const int: dialog ease animation duration;
# should be synced with CSS $b-ddd-fade-dur.
EASE_ANIM_DURATION = 200

# const string: dialog fade-out animation class.
ANIM_OUT_CLASS = "b-ddd_anim_out"

DIALOG_CLS = {
    NEW: "b-ddd"
    OLD: "b-dialog"
}

# {string: TDialog}: open dialogs.
db = atom { }

# Adds new dialog to database.
# -> TDialog self: dialog instance.
# Returns void.
dbRegister = (self) -> swap db, (curr) ->
    (cpy = copy curr)[self.id] = self; cpy


# Returns TDialog: dialog by id.
# -> string id: dialog id.
dbGet = (id) -> deref(db)[id]


# Removes dialog from database.
# -> TDialog self: dialog instance.
# Returns void.
dbFree = (self) -> swap db, (curr) ->
    delete (cpy = copy curr)[self.id]; cpy


# Returns bool: any active dialogs?
dbAny = -> 
    {modals: {modals}} = store.getState()
    bool(deref db) or getVisibleDialogs().length > 0 or modals.length > 0


# LEGACY dialogs db.
# Should be removed with lib/Dialog.
INSTANCES = { }
getVisibleDialogs = -> o.dialog for _, o of INSTANCES when o._visible is true
hasVisibleDialogs = -> dbAny()

isLastVisibleDialog = (id) ->
    allVisibleDialogs = (jQuery document.body).find ".#{DIALOG_CLS.OLD}, .#{DIALOG_CLS.NEW}"
    return id is (allVisibleDialogs.last().attr "id")

# Dialog content.
class TContent

    # bool: see renderContent() step.
    manual_render: false

    # markup|jquery: shortcut for header title.
    # See header prefabs for more info.
    title: null

    # Header opts.
    # See THeader.opts for more info.
    header: {}

    # markup|jquery: shortcut for footer submit button.
    # See footer prefabs for more info.
    submit: null

    # markup|jquery: shortcut for removal button.
    # See footer prefabs for more info.
    remove: null

    # string: `cancel` control selector.
    cancel: ""

    # string: `cancel` control id.
    cancel_id: ""

    # see THeader.tabs
    tabs: []

    withReactTabs: false

    # Footer opts.
    # See TFooter.opts for more info.
    footer: {}

    # Ctor.
    constructor: (o={}) ->
        @manual_render = toBool o.manual_render, @manual_render
        @header = o.header or @header
        @submit = o.submit or @submit
        @remove = o.remove or @remove
        @cancel = o.cancel or @cancel
        @cancel_id = o.cancel_id or @cancel_id
        @tabs = o.tabs or @tabs
        @footer = o.footer or @footer
        @title = o.title or @title
        @withReactTabs = o.withReactTabs or @withReactTabs


# Dialog app.
class TDialog

    # static object: default opts.
    @default:

        # string: dialog custom id
        # (will be set to TDialog.id)
        id: null

        # string: dialog custom class.
        cls: null

        # string: component id.
        cid: null

        # int: dialog min-width;
        # useful to avoid dialog blinking
        # when content is placed within.
        # NOT MOBILE-FRIENDLY - USE CAREFULLY!
        # USE `size` INSTEAD.
        width: undefined

        # SIZE: dialog size.
        size: ""

        # string: face custom class.
        face: ""

        # bool: hide on ESC keypress?
        hideOnEsc: true

        # bool: hide on mask click?
        hideOnMask: true

        # bool: apply default focusing?
        focus: true

        # bool: should dialog being locked on submit?
        autolock: true

        # bool: use b-ddd-body__content_pad_zero?
        content_pad_zero: false

        # Returns void: fires when dialog is displayed.
        # -> IContainer content_root: dialog content root.
        # -> object args: event callback args.
        onShow: (content_root, self, args) ->

        # Returns void: fires when dialog
        # closing/cancel action is invoked by user
        # (but, NOT PROGRAMMATICALLY via IDialog.hide())
        onCancel: (self) ->

        # Returns void: fires when dialog is hidden.
        onHide: (self) ->

    # string: dialog id.
    id: null

    # IContainer: app container.
    container: null

    # object: opts (see @default)
    opts: null

    # string: required for TTplApp realization.
    ns: NS

    # TContent: dialog content.
    content: null

    # bool: is dialog in locked state?
    is_locked_state: false


    # THeader: header reference
    # (assigned on instance init)
    header: null

    # TFooter: footer reference
    # (assigned on instance init)
    footer: null

    # Ctor.
    constructor: (@container, opts={}) ->
        @opts = merge TDialog.default, opts
        @id = opts.id or "d" + uniq()
        @content = new TContent opts


# Dialog app template.
class IDialogTpl

    # Returns markup: dialog body.
    @body: (self) ->
        {content_pad_zero} = self.opts

        ["div", { "class": "b-ddd-body
                            b-ddd-body_fix_no-js-padding" }

            ["div"
                {
                    "class": [
                        "b-ddd-body__content"
                        "b-ddd-body__content_pad_zero" if content_pad_zero
                        "js-#{NS}-content"
                    ].join " "
                }

                # Will be removed by programmer,
                # when he puts some content to body.
                ["div", { "class": "b-ddd__spinner" }
                    spinner
                ]

            ]
        ]

    # Returns markup: template markup.
    # -> TDialog: app instance.
    @init: (self) ->
        {id, opts} = self
        {cls, hideOnMask} = opts
        {face, size} = opts

        # Root, face and mask classes.
        root_cls = [
            "b-ddd "
            "js-", NS, "-root "
            cls if cls
        ].join ''

        face_cls = [
            "b-ddd__face"
            "b-ddd__face_fix_js-mw"
            size if size # size class
            face # face custom class
            "js-#{NS}-face"
        ].join " "

        face_min_width = if opts.width
            "style": ["min-width:", opts.width, "px"].join ''

        mask_cls = [
            "b-ddd__mask "
            "b-ddd__mask_type_inactive " unless hideOnMask
            "js-", NS, "-mask"
        ].join ''

        ["div", { "class": root_cls , id
                , "data-cid": opts.cid }
            ["div", { "class": mask_cls }]
            ["div", { "class": face_cls }
                    face_min_width

                # Displayed on manual_render is true,
                # until programmer will call renderContent().
                ["div", { "class": "la-fx fx-aic h-hper-100" }, spinner]

                # Header, body, footer
                # will be placed here.

            ]
        ]


# Dialog app interface.
class IDialog

    # Returns bool: is any visible dialogs?
    @isAny: -> dbAny()

    # Registers given dialog.
    # Returns void.
    @register: (self) -> dbRegister self

    # Returns TDialog: dialog instance.
    # -> string|IContainer: dialog identifier.
    @get: (object) ->
        # Is dialog id passed?
        return dbGet object if isString object

        # Is dialog root element passed?
        return dbGet object.attr "id" if isjQuery object

        # Bad argument.
        error "IDialog.get() expects dialog id
               or dialog root node as argument"

    # Hides current dialog.
    # Returns void.
    @hide: (self) ->
        {opts, container} = self

        opts.onPreHide?()

        offEnter container
        # Let the hiding animation complete.
        root(self).addClass ANIM_OUT_CLASS
        setTimeout (=>
            # Destroying dialog.
            container.remove()
            dbFree self

            # Firing dialog hidden event.
            opts.onHide self

            # Releasing DOCUMENT body scroll,
            # if there is no opened dialogs remain.
            unless dbAny()
                jQuery(document.body).css "overflow", "auto"
                showWorkspace()

        ), EASE_ANIM_DURATION

        undefined

    # Locks dialog inputs.
    # Returns void.
    @lock: (self) ->
        # Disabling this buttons by default.
        if @shouldAutolock self
            disable qs self, "submit"
            disable qs self, "remove"

        # Updating state.
        self.is_locked_state = true
        undefined

    # Unlocks dialog inputs.
    # Returns void.
    @unlock: (self) ->
        # Disabling this buttons by default.
        if @shouldAutolock self
            enable qs self, "submit"
            enable qs self, "remove"

        # Updating state.
        self.is_locked_state = false
        undefined

    # Returns bool: should dialog autolock being applied?
    @shouldAutolock: (self) ->
        # Dialog should be locked,
        # if autolock is enabled and unless any errors.
        self.opts.autolock and not hasErrors root self

    # Returns T_IDialogTpl: dialog template class.
    @template: -> IDialogTpl

    # Renders dialog template.
    @render: (self) ->
        self.container.html(
            render @template().init self
        )

        self

    # Fires when dialog
    # is being closed by user.
    # Returns void.
    @onDialogClose: (self) ->
        return if self.opts.onCancel(self) is false
        @hide self
        undefined

    # Fires when user performs submission.
    # Returns void.
    @onSubmitClick: (self) ->
        @lock self if @shouldAutolock self
        undefined

    # Fires when user performs removal action.
    # Returns void.
    @onRemoveClick: (self) ->
        @lock self if @shouldAutolock self
        undefined

    # Performs dialog footer initialization.
    # -> TContent content: dialog content.
    # Returns THeader: footer instance.
    @initHeader: (self, content) ->
        {header, title, cancel, cancel_id, tabs, withReactTabs} = content

        # Does header has customized left-side?
        lside =
            if header.lside
                # Yes.
                # Is there dialog title specified?
                # Placing dialog title at the first place, if yes
                # in other case title is placed respectively to lside definition.
                if title then [title].concat header.lside else header.lside
            else
                # No.
                # In this case - only title is displayed, if specified;
                # in other case, left-side is totally empty.
                if title then [title] else undefined

        # Creating header instance.
        self.header = IHeader.init new THeader qs(self, "face"), {
            onClose: => @onDialogClose self
            rside: header.rside
            lside
            cancel_id
            cancel
            tabs
            withReactTabs
        }

    # Performs dialog footer initialization.
    # -> TContent content: dialog content.
    # Returns TFooter: footer instance.
    @initFooter: (self, content) ->
        {footer, submit, remove} = content

        # Does footer has customized left-side?
        lside =
            if footer.lside
                # Yes.
                # If submit button is specified,
                # it will be placed as very last item of left-side;
                # in other case, position of submit button is also customized
                # and it will be placed respectively to lside definition.
                if submit then footer.lside.concat [submit] else footer.lside
            else
                # No.
                # If submit button is specified,
                # this is all we see in dialog's left-side;
                # in other case, left-side is totally empty.
                if submit then [submit] else undefined

        # Does footer has customized right-side?
        rside =
            if footer.rside
                # Yes.
                # If removal button is specified,
                # it will be placed as very last item of left-side;
                # in other case, position of removal button is also customized
                # and it will be placed respectively to rside definition.
                if remove then footer.rside.concat [remove] else footer.rside
            else
                # No.
                # If removal button is specified,
                # this is all we see in dialog's right-side;
                # in other case, right-side is totally empty.
                if remove then [remove] else undefined

        # Creating footer instance.
        self.footer = IFooter.init new TFooter(
            qs(self, "face"), {lside, rside}
        )

    # Performs dialog body initialization.
    # Returns IContainer: body root element.
    @initBody: (self) ->
        jQuery render @template().body self
            .appendTo qs self, "face"

    # Performs dialog content rendering routine.
    # -> TContent content: dialog content.
    # Returns void.
    @renderContent: (self, content) ->
        # Header, body, footer;
        # init order is important!
        @initHeader self, content
        @initBody self
        @initFooter self, content

        undefined

    # Performs dialog config
    # after template render.
    @config: (self) ->
        {opts, content} = self

        # Mask.
        if opts.hideOnMask then click self, "mask", =>
            @onDialogClose self unless self.is_locked_state

        # Configuring dialog buttons.
        click self, "submit", => @onSubmitClick self
        click self, "remove", => @onRemoveClick self

        # When events initialization has finished,
        # let's continue to content rendering step
        # (unless manual mode flag is set in opts)
        unless content.manual_render
            qs(self, "face").html "" # kick-out global spinner
            @renderContent self, self.content

        self

    # Returns IContainer: dialog body container element.
    # -> TDialog|jquery: dialog instance or container.
    @getContentBody: (object) ->
        return (
            if isjQuery object
                jQuery object.find ".js-#{NS}-content"
            else qs object, "content"
        )

    # Returns float: dialog header height.
    @getHeaderHeight: (self) -> IHeader.root(self.header).height()

    # Performs post-config routines.
    @start: (self) ->
        {opts, content} = self

        # Hiding workspace, to prevent scrolling.
        jQuery(document.body).css "overflow", "hidden"
        hideWorkspace()

        # Focusing.
        focus root self if opts.focus

        # Firing `display` event.
        # renderContent() function is passed as option,
        # if manual content rendering mode is specified.
        renderContent =
            if content.manual_render
                (content) =>
                    qs(self, "face").html "" # kick-out global spinner
                    @renderContent self, new TContent content
            else ->

        # onShow() must be executed after animation has finished;
        # this helps to prevent browser bugs like one with focus()
        # on elements who are currently within CSS-animation state.
        # It's really not recommended to do anything with elements
        # which are currently animated!

        # This constant adds extra time,
        # to ensure that onShow() fires after animation is complete.
        EASE_SAFE_TIME = 100

        setTimeout ( =>
            opts.onShow self, {renderContent}

            focus self.container if opts.focus
        ), EASE_ANIM_DURATION + EASE_SAFE_TIME

        self

    # Entry point.
    # -> TDialog: app instance.
    @init: (self) -> @start @config @render self


module.exports = {
    IDialogTpl
    IDialog
    TDialog

    # Required for legacy dialog.
    INSTANCES
    getVisibleDialogs
    hasVisibleDialogs
    isLastVisibleDialog
}
