import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchRequest } from '@@services/transport/fetch';

export const fetchDropdownData = createAsyncThunk(
  'dropdown/fetchDropdownData',
  async (
    { requestUrl, requestMethod, requestPayload, sccb, extraCbs = [] },
    { rejectWithValue },
  ) => {
    const response = await fetchRequest({
      url: requestUrl,
      payload: requestPayload,
      method: requestMethod || 'GET',
      sccb,
      ercb: rejectWithValue,
    });

    if (response.message) {
      return rejectWithValue(response.message);
    }

    if (extraCbs && extraCbs.length) {
      return extraCbs.reduce((acc, next) => next(acc), response);
    }

    return response;
  },
);

const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState: {},
  reducers: {
    setSelectedItems: (state, { payload }) => {
      const { name, selected } = payload;

      state[name] = {
        ...state[name],
        selected,
      };
    },
    setDropdownData: (state, { payload }) => {
      const { name, data, total } = payload;

      state[name] = {
        ...state[name],
        data,
        total,
      };
    },
    deleteDropdownData: (state, { payload }) => {
      const { name } = payload;

      delete state[name];
    },
    setValidationData: (state, { payload }) => {
      const { name, data } = payload;

      state[name] = {
        ...state[name],
        isValid: data.isValid,
        errorMessage: data.errorMessage,
      };
    },
    setLoading: (state, { payload }) => {
      const { name, loading } = payload;

      state[name] = {
        ...state[name],
        loading,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDropdownData.pending, (state, { meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        loading: true,
        error: false,
      };
    });

    builder.addCase(fetchDropdownData.fulfilled, (state, { payload, meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        ...payload,
        loading: false,
        error: false,
      };
    });

    builder.addCase(fetchDropdownData.rejected, (state, { meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        data: [],
        total: 0,
        loading: false,
        error: true,
      };
    });
  },
});

export const {
  setSelectedItems,
  setDropdownData,
  deleteDropdownData,
  setValidationData,
  setLoading,
} = dropdownSlice.actions;

export default dropdownSlice.reducer;
