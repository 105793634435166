import { createApi } from '@reduxjs/toolkit/query/react';

import { appInstance } from '@@services/transport/axios';

import type { AxiosBaseQuery, BaseQueryError } from '@@types/api';

const axiosBaseQuery = (): AxiosBaseQuery => async (axiosConfig) => {
  try {
    const { data, ...meta } = await appInstance(axiosConfig);

    return { data, meta };
  } catch (axiosError) {
    return {
      error: axiosError as BaseQueryError,
    };
  }
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Order',
    'Book',
    'NovapostAccounts',
    'Taxes',
    'TaxGroups',
    'OrderSaleTaxRules',
    'PostingTaxRules',
    'OrdersSettings',
    'PublicPageSettings',
  ],
  endpoints: () => ({}),
});

export default api;
