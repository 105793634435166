import toast from 'react-hot-toast';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getProductTours as getProductToursAPI,
  getTrialTours as getTrialToursAPI,
} from '@@api/engagement';
import { setAllowSupportChat, setTraining } from '@@api/user';

const IUser = require('interface/User');

export const getTrialTours = createAsyncThunk(
  'onboarding/getTrialTours',
  async () => {
    const response = await getTrialToursAPI();

    return response;
  },
);

export const getProductTours = createAsyncThunk(
  'onboarding/getProductTours',
  async () => {
    const response = await getProductToursAPI();

    return response;
  },
);

export const toggleAllowSupportChat = createAsyncThunk(
  'onboarding/toggleAllowSupportChat',
  async (isAllowChat) => {
    await setAllowSupportChat(
      { allow_support_chat: isAllowChat },
      {
        sccb: () => {
          IUser.setState({ ALLOW_SUPPORT_CHAT: isAllowChat });
        },
      },
    );

    return isAllowChat;
  },
);

export const updateTrainMode = createAsyncThunk(
  'onboarding/updateTrainMode',
  async (isTraining) => {
    await setTraining(
      { is_training: isTraining },
      {
        sccb: () => {
          toast.success(
            isTraining
              ? __('Training mode enabled')
              : __('Training mode disabled'),
          );
          IUser.setState({ IS_TRAINING: isTraining });
        },
      },
    );

    return isTraining;
  },
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    trainMode: false,
    allowChat: true,
    productTours: undefined,
    trialTours: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getTrialTours.fulfilled, (state, { payload }) => {
      state.trialTours = payload;
    });

    builder.addCase(getProductTours.fulfilled, (state, { payload }) => {
      state.productTours = payload;
    });

    builder.addCase(toggleAllowSupportChat.fulfilled, (state, { payload }) => {
      state.allowChat = payload;
    });

    builder.addCase(updateTrainMode.fulfilled, (state, { payload }) => {
      state.trainMode = payload;
    });
  },
});

export default onboardingSlice.reducer;
