import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  updateCount: 0,
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    increaseUpdateCount(state) {
      state.updateCount += 1;
    },
  },
});

export const { increaseUpdateCount } = tasksSlice.actions;

export default tasksSlice.reducer;
