import {
  ButtonHTMLAttributes,
  CanvasHTMLAttributes,
  ChangeEventHandler,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  MouseEventHandler,
  MutableRefObject,
  ReactNode,
  RefObject,
  SVGProps,
} from 'react';
import { PatternFormatProps } from 'react-number-format';
import type SignaturePad from 'signature_pad';
import { Options } from 'signature_pad';

import { RANGE_TYPE } from '@@constants/date';
import { ICON_FILL } from '@@constants/images';

import { MimeType } from '@@types/file';
import { PaymentMethods } from '@@types/finance';

type FillKeys = keyof typeof ICON_FILL;

export type ImageFills = (typeof ICON_FILL)[FillKeys];

export type ImagePositions =
  | 'left_2'
  | 'left_4'
  | 'left_8'
  | 'left_10'
  | 'left_12'
  | 'left_16'
  | 'right_2'
  | 'right_4'
  | 'right_8'
  | 'right_12'
  | 'right_16';

export type ImageSize =
  | 6
  | 8
  | 10
  | 12
  | 14
  | 16
  | 20
  | 24
  | 26
  | 28
  | 32
  | 36
  | 48;

export interface ImageProps extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  image?: SvgComponent | null;
  fill?: ImageFills;
  className?: string;
  dataCid?: string | null;
  position?: ImagePositions;
  cursor?: 'default' | 'pointer' | 'not-allowed' | 'wait';
  size?: ImageSize;
}

export interface ButtonsGroupTab {
  label: string;
  value: string | number;
  route?: string;
  icon?: SvgComponent;
  disabled?: boolean;
  tooltip?: Node;
  iconPosition?: ImagePositions;
  iconFill?: ImageFills;
  clickHandler?: (id: string | number) => () => void;
  available?: () => void;
  dataCid?: string;
}

export interface DropdownSearchableRow<Value = number | string> {
  id: Value;
  title: string;
  lineThrough?: boolean;
  positionId?: number;
  branch?: number;
  is_global?: boolean;
  icon?: ImageProps;
  isHidden?: boolean;
  items?: {
    [key: string]: DropdownSearchableRow<Value>;
  };
}

export interface DropdownSearchableSelected<Value = number | string>
  extends DropdownSearchableRow<Value> {
  path: string;
  hasChildren: boolean;
}

export interface DropdownSearchableConfig {
  data?:
    | {
        [key: string]: DropdownSearchableRow;
      }
    | DropdownSearchableRow[];
  sortKey?: keyof DropdownSearchableRow;
  canSelectTreeParent?: boolean;
  withTree?: boolean;
  selectAllText?: string;
  maxHeight?: number;
  isDefaultCollapsed?: boolean;
  width?: number;
  widthContent?: number;
  preData?: {
    [key: string]: DropdownSearchableRow;
  } | null;
  withCheckbox?: boolean;
  actionBarType?: string;
  name?: string;
  childrenKeyName?: string;
  persistToLs?: boolean;
  initialIds?: (string | number)[] | number | string;
}

export interface DropdownMenuItem<Value = string | number | boolean> {
  label: string;
  value?: Value;
  disabled?: boolean;
  dataCid?: string;
  icon?: ImageProps;
  onChange?: (value?: Value) => void;
}

export interface DndListItem<Value = string | number | boolean> {
  id: string | number;
  index: number;
  value: Value;
}

export interface Tab {
  title: string;
  id: number | string;
  handler: () => void;
  innerRef?: RefObject<HTMLDivElement>;
  dataCid?: string;
  path?: string;
  permission?: () => boolean;
  menu?: Tab[];
  render?: () => JSX.Element;
  component?: () => JSX.Element;
  containerSelector?: string;
}

export interface SelectOption {
  value: string | number | boolean;
  label: string;
  hidden?: boolean;
  items?: Omit<SelectOption, 'items'>[];
}

export type PatternFormatSubsetProps = Pick<
  PatternFormatProps,
  'id' | 'value' | 'mask' | 'format' | 'allowEmptyFormatting'
>;

export type DateRangeTypeUnion = ExtractValues<typeof RANGE_TYPE>;

export type TimestampDateRange = [number, number];

export type DateRanges = Record<
  DateRangeTypeUnion,
  {
    value: DateRangeTypeUnion;
    label: string;
    range: () => TimestampDateRange;
  }
>;

export interface TreeNode<
  Value = unknown,
  ContentProps = Record<string, unknown>,
> {
  value: Value;
  label?: string;
  children?: TreeNode<Value>[];
  contentProps?: ContentProps;
  disableSelectNode?: boolean;
}

export type TreeSelectedNode<Value = unknown> = Pick<
  TreeNode<Value>,
  'value'
> & {
  path: string;
  // shouldn`t use children
  // TreeSelectedNode save to local storage
  // children cause cyclic error
  hasChildren: boolean;
};

export interface ComponentTreeData<Value = unknown> extends TreeNode<Value> {
  path: string;
}

export interface ListNode<
  Value = unknown,
  ContentProps = Record<string, unknown>,
> {
  value: Value;
  label: string;
  contentProps?: ContentProps;
}

export interface ListSelectedNode<Value = unknown> {
  value: Value;
}

export type DateFilterValue = DateRangeTypeUnion | TimestampDateRange;

export type SelectionListFilterValue<Value = unknown> =
  | ListSelectedNode<Value>[]
  | 'all';

export type SelectionTreeFilterValue<Value = unknown> =
  | TreeSelectedNode<Value>[]
  | 'all';

export interface ListNodeComponentProps<
  Value = unknown,
  ContentProps = Record<string, unknown>,
> extends ListNode<Value, ContentProps> {
  searchValue?: string;
  isSelected?: boolean;
  onNodeSelect: (node: ListSelectedNode<Value>) => void;
}

export enum AttachmentType {
  image = 'image',
  audio = 'audio',
  video = 'video',
  file = 'file',
  storyMention = 'story_mention',
  share = 'share',
  sticker = 'sticker',
  location = 'location',
  contacts = 'contacts',
  fallback = 'fallback',
}

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  dataCid?: string | null;
  actionType?: 'button' | 'submit' | 'reset';
  textColor?: 'white' | 'black';
  active?: boolean;
  noActive?: boolean;
  hover?: boolean;
  bold?: boolean;
  matchParent?: boolean;
  hasTwoRows?: boolean;
  size?: 'small' | 'medium' | 'large';
  loaderSize?: 'large' | 'medium';
  icon?: SvgComponent;
  iconFill?: ImageFills;
  iconPosition?: ImagePositions;
  iconSize?: ImageSize;
  iconClassName?: string;
  type?: 'primary' | 'secondary' | 'tertiary' | 'dashed' | 'gradient' | 'text';
  color?: 'green' | 'red' | 'blue' | 'orange';
  caretSize?: ImageSize;
  caretBoxCustomClassName?: string;
  innerClassName?: string;
  isCaretUp?: boolean;
  isLoading?: boolean;
  withCaret?: boolean;
  withCaretOverflow?: boolean;
  noCaretBorder?: boolean;
  noBorder?: boolean;
  noRightBorder?: boolean;
  noLeftBorder?: boolean;
  noRightBorderRadius?: boolean;
  noLeftBorderRadius?: boolean;
}

export interface TypographyProps {
  variant?:
    | 'h0'
    | 'h1'
    | 'h1Bold'
    | 'h2'
    | 'h2Bold'
    | 'h3'
    | 'subheading1'
    | 'subheading2'
    | 'label'
    | 'labelCompact'
    | 'body'
    | 'bodyCompact'
    | 'bodyCompactBold'
    | 'bodyMini'
    | 'bodyMiniBold'
    | 'caption'
    | 'note'
    | 'button'
    | 'primaryButton'
    | 'secondaryButton'
    | 'link';
  component?: keyof JSX.IntrinsicElements;
  children: ReactNode;
  color?:
    | 'grey_100'
    | 'grey_50'
    | 'grey_25'
    | 'grey_8'
    | 'blue_100'
    | 'red_100'
    | 'red_80'
    | 'green_100'
    | 'white_100';
  bottomOffset?: 0 | 2 | 4 | 8 | 12 | 16 | 20 | 24;
  align?: 'left' | 'center' | 'right';
  block?: boolean;
  inline?: boolean;
  pointer?: boolean;
  className?: string;
  style?: React.CSSProperties;
  dataCid?: string;
  htmlFor?: string;
}

export interface CarouselHandle {
  slideToNext: () => void;
  slideToPrev: () => void;
}

export interface InputProps {
  dataCid?: string;
  type?: HTMLInputTypeAttribute;
  name?: string;
  className?: string;
  wrapperClassName?: string;
  id?: string;
  placeholder?: string;
  variant?: 'regular' | 'table';
  autoSelect?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  noBorder?: boolean;
  noRightBorderRadius?: boolean;
  withoutArrows?: boolean;
  withoutEye?: boolean;
  maxLength?: number;
  max?: number;
  min?: number;
  value?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  prefix?: ReactNode;
  postfix?: ReactNode;
  onClear?: () => void;
  onSearch?: () => void;
  onEnterPressed?: KeyboardEventHandler<HTMLInputElement>;
  onEscapePressed?: KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onDoubleClick?: MouseEventHandler<HTMLInputElement>;
  alignText?: 'right' | 'center' | 'left';
  size?: 'extraSmall' | 'small' | 'normal' | 'large' | 'huge';
  iconSize?: 16 | 6 | 8 | 10 | 12 | 14 | 20;
  formatRef?: RefObject<HTMLInputElement>;
  autoComplete?: string;
  // for get rid of autoComplete in input
  withNamePrefix?: boolean;
}

export interface SignaturePadProps
  extends Omit<CanvasHTMLAttributes<HTMLCanvasElement>, 'onChange'> {
  dataCid?: string;
  signaturePadRef: MutableRefObject<SignaturePad | null>;
  mime?: MimeType;
  options?: Options;
  error?: boolean;
  onChange?: (data: string) => void;
}

// HOOKS

export interface UseCashboxesDataOptions {
  onlyTypes?: PaymentMethods[];
  haveAccess?: (id: number) => boolean;
}

export interface UseWarehousesDataOptions {
  withAssets?: boolean;
  onlyCurrentBranch?: boolean;
  haveAccess?: (id: number) => boolean;
}

export interface UseBranchesDataOptions {
  onlyCurrentBranch?: boolean;
  withAllBranches?: boolean;
  withBranchAccess?: boolean;
  withoutCurrentBranch?: boolean;
  withoutArchiveBranches?: boolean;
  currentBranchId?: number;
  hideRowsWithoutBranchAccess?: boolean;
}

export interface ModalHeaderProps {
  modalHeaderParams: { height: number };
}

export interface GalleryMediaAsset {
  id: string;
  file_url: string;
  filename?: string;
  fileType: AttachmentType;
}
