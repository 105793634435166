import { fetchRequest } from '@@services/transport/fetch';

export const getTrialTours = (payload, options = {}) => {
  return fetchRequest({
    url: '/engagement/get-trial-tours',
    payload,
    ...options,
  });
};

export const getProductTours = (payload, options = {}) => {
  return fetchRequest({
    url: '/engagement/get-product-tours',
    payload,
    ...options,
  });
};
