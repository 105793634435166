import _assign from 'lodash/assign';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isPlainObject from 'lodash/isPlainObject';
import _keys from 'lodash/keys';
import store from 'store';

export const storageRemoveKey = (key) => store.remove(key);

export const storageRemoveAll = () => store.clearAll();

export const storageSet = (key, value) => {
  try {
    store.set(key, value);
  } catch (error) {
    store.remove(key);
    store.set(key, value);
  }
};

export const storageGet = (key, def) => {
  const item = store.get(key);

  if (!item) {
    store.remove(key);
    return def;
  }

  return item;
};

/**
 * @description
 * This function works only with serialized structure: [{id: <node_id>, state: <node_value>}, ...]
 * Get the state of storage by the given key and target id.
 *
 * @param {string} key - item local storage key.
 * @param {number|string} targetId - id of existing storage value.
 * @param {unknown} [def] - default value.
 */
export const getStorageState = (key, targetId, def) => {
  const persistedValue = storageGet(key, []);

  if (!_isArray(persistedValue)) {
    return def;
  }

  const targetStorageItem = persistedValue.find(({ id }) => id === targetId);

  if (_isEmpty(targetStorageItem)) {
    return def;
  }

  return targetStorageItem.state;
};

/**
 * @description
 * Updates the state by the given value under existing local storage key.
 * Serialized structure: [{id: <node_id>, state: <node_value>}, ...]
 *
 * @param {string} key - item local storage key.
 * @param {object} value - value to put into local storage item.
 * @param {object} options - custom parameters to make manager logic more flexible.
 * @param {boolean} options.withStateExpansion - indicates whether to put a property that did not exist before.
 */
export const storageUpdate = (key, value, options = {}) => {
  const { withStateExpansion } = options;

  const oldValue = storageGet(key, []);
  const [targetStorageItem] = oldValue.filter(({ id }) => id === value.id);
  const targetStorageItemIndex = oldValue.indexOf(targetStorageItem);

  if (_isEmpty(oldValue) || targetStorageItemIndex < 0) {
    storageSet(key, oldValue.concat(value));
    return;
  }

  const newStateValue = _isPlainObject(value.state)
    ? _keys(value.state).reduce((acc, stateKey) => {
        const isContainKey = Object.prototype.hasOwnProperty.call(
          targetStorageItem.state,
          stateKey,
        );

        return {
          ...acc,
          [stateKey]:
            isContainKey || withStateExpansion
              ? value.state[stateKey]
              : targetStorageItem.state[stateKey],
        };
      }, {})
    : value.state;

  storageSet(
    key,
    _assign([...oldValue], {
      [targetStorageItemIndex]: { id: value.id, state: newStateValue },
    }),
  );
};
