import once from 'lodash/once';

export const initDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
};

export const setDataLayer = once(() => {
  const { EMPLOYEE_ID, IS_OWNER } = require('interface/User');
  const {
    ID,
    LICENSE: { TYPE, TITLE, DAYS_LEFT },
  } = require('interface/Company');
  const { DOMAIN } = require('interface/System');

  initDataLayer();
  window.dataLayer.push({
    userId: EMPLOYEE_ID,
    companyId: ID,
    owner: IS_OWNER ? 'True' : 'False',
    licenseType: TYPE,
    licenseName: TITLE,
    licenseDaysLeft: DAYS_LEFT,
    domain: DOMAIN,
  });
});

const isShowGaTag = (config = {}) => !config.debug && config.GTM_TOKEN;

const getHeadScript = (gaToken) => {
  return (
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});" +
    "var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
    "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
    `})(window,document,'script','dataLayer','${gaToken}');`
  );
};

const getBodyScript = (gaToken) => {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gaToken}' height='0' width='0' style='display:none;visibility:hidden'></iframe>`;
};

export const getDevHeadScript = () => 'var ga = function(){}';

export const getHeadGaTag = (config) => {
  if (!isShowGaTag(config)) {
    return getDevHeadScript();
  }

  return getHeadScript(config.GTM_TOKEN);
};

export const getBodyGaTag = (config) => {
  if (!isShowGaTag(config)) {
    return null;
  }

  return getBodyScript(config.GTM_TOKEN);
};
