import { HttpStatusCode } from 'axios';

export const RETRY_STATUS_CODES: number[] = [
  HttpStatusCode.RequestTimeout,
  HttpStatusCode.ServiceUnavailable,
];

// Max and min value in which scope jitter function gets random delay time in milliseconds
export const JITTER_DELAY_BOUNDARIES = [100, 250];

// Delay before next function call in milliseconds
export const DEFAULT_BACKOFF_TIME = 300;

// Number of retries to execute on failure result
export const DEFAULT_RETRIES_COUNT = 2;

export const NOTIFY_PREFIX_REGEXP = /^(_notify_|Notify|notify)/;
