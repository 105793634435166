{merge} = require "lib/fp"
{disable, byId} = require "lib/DOM"
{onEsc, offEnter} = require "lib/listener"
{isMobile} = require "lib/mobile"
{render, uniq} = require "lib/helpers"
{dialogSpinner} = require "lib/Layouts"
{hasErrors} = require "lib/plugins/Validate"

# New dialog module! In future should replace current one.
{INSTANCES, getVisibleDialogs, hasVisibleDialogs, isLastVisibleDialog} = require "lib/widgets/Dialog/main"
{showWorkspace, hideWorkspace} = require "lib/widgets/Dialog/lib"


WINDOW = jQuery window

BODY = jQuery document.body

CLS_LOCKED = "js-dialog-locked"

SPEED =
    FAST: 120
    SLOW: 220

PREFIX =
    MASK: "mk-"
    DIALOG: "dg-"

DEFAULT =
    hideOnEsc: true
    hideOnMask: true
    focus: true
    # bool: should dialog being locked on submit?
    autolock: true
    # You can set default dialog width or it will be calculated from the given
    #   css class.
    width: 410
    onShow: ->
    onHide: ->
    onPreHide: ->
    onCancel: ->


lockTemplate = ({text, btnText}) ->
    ["div", { "class": "b-dialog__lock" }
        ["div", { "class": "b-dialog__notify
                            b-dialog__notify_type_lock" }
            ["span", { "class": "h-d-b h-fs-18" }, text]
            ["button", {"class": "b-btn b-btn_color_white h-mt-20 h-m-center"}, btnText]
        ]
    ]

setInstance = (id, instance) -> INSTANCES[id] = instance

getInstance = (id) -> INSTANCES[id] or {}

markInstance = (id, flag) -> INSTANCES[id]._visible = flag

buildInstance = (id, config) ->
    {cls, hideOnMask, autolock, onCancel} = config

    props =
        dialog:
            id: id
            class: cls or "b-dialog"
            style: "height: #{WINDOW.outerHeight()}px;"
        mask:
            id: "#{PREFIX.MASK}#{id}"
            class: "h-dialog-mask"
            style: "cursor: pointer;" if hideOnMask

    dialog = jQuery "<div>", props.dialog
    mask = jQuery "<div>", props.mask

    BODY.css "overflow", "hidden"
    hideWorkspace() unless hasVisibleDialogs()

    BODY.append mask
        .append dialog.html render dialogSpinner

    if hideOnMask then mask.click ->
        onCancel()
        hide id

    dialog.on "click", ".js-close-dialog", ->
        onCancel()
        hide id

    # Disable submit button of the dialog to prevent multi-submitting.
    dialog.on "click", ".js-submit-dialog", ->
        # Do not disable submit button when the dialog has an errors.
        # NOTE Errors can be appended to the another elements that are related
        # to the dialog but not  to be in the dialog container.
        if autolock and not hasErrors dialog
            disable jQuery this if autolock

    setInstance id, merge config, {dialog, mask}

    {dialog, mask}

focus = (dialog) -> if isMobile() then dialog else require("lib/DOM").focus dialog

getDialog = (id) -> getInstance(id).dialog

getActiveDialogs = -> BODY.find "[id^=#{PREFIX.DIALOG}]:not(.#{CLS_LOCKED})"

getLastActiveDialog = ->
    getActiveDialogs().filter( (i, dialog) ->
        ((jQuery dialog).attr "class").indexOf('dialog') > -1).last()

getActiveDialog = -> getActiveDialogs().last()

getDialogId = (jdialog) -> jdialog.attr "id"

hide = (id) ->
    {dialog, mask, onHide, onPreHide} = getInstance id
    return id if dialog is undefined

    offEnter dialog

    dialog.addClass CLS_LOCKED

    mask.animate opacity: "0.1", SPEED.SLOW, -> mask.remove()
    dialog.animate right: "-100%", SPEED.SLOW, ->
        onPreHide? id, dialog
        dialog.remove()
        markInstance id, false
        onHide? id, dialog
        unless hasVisibleDialogs()
            showWorkspace()
            BODY.css "overflow", "auto"

    id

hideExcept = (ids) ->
    hide id for id, {_visible} of INSTANCES when _visible and id not in ids

hideAll = -> hide id for id, {_visible} of INSTANCES when _visible is true

show = (id, ev) ->
    buildInstance id, getInstance id
    {dialog, mask, cls, onShow} = config = getInstance id

    opts = if cls then {right: 0} else {width: config.width, right: 0}

    mask.animate {opacity: "0.5"}, SPEED.SLOW
    dialog.animate opts, SPEED.SLOW, ->
        markInstance id, true
        onShow? dialog, ev

    id

setContent = (dialog, html, cb) ->
    config = getInstance dialog.attr "id"
    # Test content width for the flawless implementation of the animation.
    dialog.html html

    dialogWidth = parseInt dialog.css "width"
    width = if config.cls
        dialog.outerWidth()
    else
        dialog.children().outerWidth() +
        parseInt(dialog.css('padding-left')) +
        parseInt(dialog.css('padding-right'))

    # Cleaning up focus.
    (jQuery document.activeElement).blur()

    focus(dialog) if config.focus

    # Cleanup width for a better processing of the layout resizing.
    dialog.css "width", ""

    if typeof cb is "function" then cb()

lockDialog = (dialog, opts) ->
    dialog.addClass("b-dialog__blurred").append render lockTemplate opts
    dialog.on "click", "button", opts.fnbtnClick

dialog = (trigger, opts, {validationCb}= {}) ->
    # Overloading.
    if opts is undefined
        opts = trigger; trigger = undefined

    id = opts?.id or "#{PREFIX.DIALOG}#{uniq()}"
    config = merge DEFAULT, opts

    setInstance id, config
    trigger?.on "click", (ev) ->
        valid = if validationCb then validationCb() else true
        if valid
            show id, ev

    id


lockScroll = (dialog) ->
    dialog.css "overflow", "hidden"


makeScroll = (dialog) ->
    dialog.css "overflow", ""


# Bind Dialog global handlers.
onEsc BODY, ->
    id = getLastActiveDialog().attr "id"
    return if !!(byId('modal1_root').length) or !isLastVisibleDialog(id)

    hide id if getInstance(id).hideOnEsc

WINDOW.resize ->
    for own _, {dialog, _visible} of INSTANCES when _visible is true
        dialog.css "height", WINDOW.outerHeight()


module.exports = {
    dialog, getDialog, hide, hideAll, show, setContent, getVisibleDialogs,
    hasVisibleDialogs, getDialogId, getActiveDialog, lockDialog, getActiveDialogs,
    hideExcept, lockScroll, makeScroll
}
