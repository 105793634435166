import SvgAsset0 from '@@assets/images/icons/asset0.svg';
import SvgAsset1 from '@@assets/images/icons/asset1.svg';
import SvgAsset2 from '@@assets/images/icons/asset2.svg';
import SvgAsset3 from '@@assets/images/icons/asset3.svg';
import SvgAsset4 from '@@assets/images/icons/asset4.svg';
import SvgAsset5 from '@@assets/images/icons/asset5.svg';
import SvgAsset6 from '@@assets/images/icons/asset6.svg';
import SvgAsset7 from '@@assets/images/icons/asset7.svg';
import SvgAsset8 from '@@assets/images/icons/asset8.svg';
import SvgAsset9 from '@@assets/images/icons/asset9.svg';
import SvgAsset10 from '@@assets/images/icons/asset10.svg';
import SvgAsset11 from '@@assets/images/icons/asset11.svg';
import SvgAsset12 from '@@assets/images/icons/asset12.svg';
import SvgAsset13 from '@@assets/images/icons/asset13.svg';
import SvgAsset14 from '@@assets/images/icons/asset14.svg';
import SvgAsset15 from '@@assets/images/icons/asset15.svg';
import SvgAsset16 from '@@assets/images/icons/asset16.svg';
import SvgAsset17 from '@@assets/images/icons/asset17.svg';
import SvgAsset18 from '@@assets/images/icons/asset18.svg';
import SvgAsset19 from '@@assets/images/icons/asset19.svg';
import SvgAsset20 from '@@assets/images/icons/asset20.svg';
import SvgAsset21 from '@@assets/images/icons/asset21.svg';
import SvgAsset22 from '@@assets/images/icons/asset22.svg';
import SvgAsset23 from '@@assets/images/icons/asset23.svg';
import SvgBranchType1 from '@@assets/images/icons/branch_type1.svg';
import SvgBranchType2 from '@@assets/images/icons/branch_type2.svg';
import SvgBranchType3 from '@@assets/images/icons/branch_type3.svg';
import SvgBranchType4 from '@@assets/images/icons/branch_type4.svg';

export const BRANCH_ICON_FILL = {
  BR0: 'br0',
  BR1: 'br1',
  BR2: 'br2',
  BR3: 'br3',
  BR4: 'br4',
  BR5: 'br5',
  BR6: 'br6',
  BR7: 'br7',
  BR8: 'br8',
  BR9: 'br9',
  BR10: 'br10',
  BR11: 'br11',
} as const;

export const ICON_FILL = {
  BLACK_100: 'black_100',
  GREY_8: 'grey_8',
  GREY_25: 'grey_25',
  GREY_40: 'grey_40',
  GREY_50: 'grey_50',
  GREY_80: 'grey_80',
  GREY_100: 'grey_100',
  WHITE_100: 'white_100',
  ORANGE_80: 'orange_80',
  ORANGE_100: 'orange_100',
  BLUE_80: 'blue_80',
  BLUE_100: 'blue_100',
  GREEN_100: 'green_100',
  RED_100: 'red_100',
  RED_80: 'red_80',
  PINK_100: 'pink_100',
  CURRENT_COLOR: 'currentColor',
  ...BRANCH_ICON_FILL,
} as const;

export const ASSET_ICON = {
  'i-asset0': SvgAsset0,
  'i-asset1': SvgAsset1,
  'i-asset2': SvgAsset2,
  'i-asset3': SvgAsset3,
  'i-asset4': SvgAsset4,
  'i-asset5': SvgAsset5,
  'i-asset6': SvgAsset6,
  'i-asset7': SvgAsset7,
  'i-asset8': SvgAsset8,
  'i-asset9': SvgAsset9,
  'i-asset10': SvgAsset10,
  'i-asset11': SvgAsset11,
  'i-asset12': SvgAsset12,
  'i-asset13': SvgAsset13,
  'i-asset14': SvgAsset14,
  'i-asset15': SvgAsset15,
  'i-asset16': SvgAsset16,
  'i-asset17': SvgAsset17,
  'i-asset18': SvgAsset18,
  'i-asset19': SvgAsset19,
  'i-asset20': SvgAsset20,
  'i-asset21': SvgAsset21,
  'i-asset22': SvgAsset22,
  'i-asset23': SvgAsset23,
};

export const BRANCH_ICON = [
  SvgBranchType1,
  SvgBranchType2,
  SvgBranchType3,
  SvgBranchType4,
];

export const BRANCH_FILL = Object.values(BRANCH_ICON_FILL);
