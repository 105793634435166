import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getEvents } from '@@api/eventLog';

import { INITIAL_STATE_MODEL } from './constants';

export const fetchEvents = createAsyncThunk(
  'eventFeed/fetchEvents',
  async ({ objectId, contextType }, { rejectWithValue }) => {
    const response = await getEvents({
      object: { id: objectId },
      context_type: contextType,
    });

    if (response.message) {
      return rejectWithValue(response.message);
    }

    return response;
  },
);

const eventFeedSlice = createSlice({
  name: 'eventFeed',
  initialState: {},
  reducers: {
    setViewMode: (state, { payload }) => {
      const { name, viewMode } = payload;

      state[name] = {
        ...state[name],
        viewMode,
      };
    },
    resetState: (state, { payload }) => {
      const { name } = payload;

      delete state[name];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.pending, (state, { meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...INITIAL_STATE_MODEL,
        ...state[name],
        isLoading: true,
      };
    });

    builder.addCase(fetchEvents.fulfilled, (state, { payload, meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        data: payload,
        isLoading: false,
      };
    });

    builder.addCase(fetchEvents.rejected, (state, { meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        isLoading: false,
      };
    });
  },
});

export const { setViewMode, resetState } = eventFeedSlice.actions;

export default eventFeedSlice.reducer;
