import { initStore } from './initStore';

let reduxStore: ReturnType<typeof initStore>;

const storeBuild = () => {
  if (!reduxStore) {
    reduxStore = initStore();
  }

  return reduxStore;
};

const { store, persistor } = storeBuild();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { persistor, store };
