_values = require('lodash/values')

{range, copy} = require "lib/helpers"
{toObject} = require "lib/fp"

{STATUS_INSTANCE} = require('@@constants/statuses')

[MINUTES, HOURS, DAYS] = range 3

TIME_NORM_UNIT = {MINUTES, HOURS, DAYS}

MEASURES = [
    [TIME_NORM_UNIT.MINUTES, __("Min.")]
    [TIME_NORM_UNIT.HOURS, __("Hour.")]
    [TIME_NORM_UNIT.DAYS, __("v3 D.")]
]

TIME_NORM_MAPPING = [
    [TIME_NORM_UNIT.MINUTES, __("m.")]
    [TIME_NORM_UNIT.HOURS, __("h.")]
    [TIME_NORM_UNIT.DAYS, __("v1 d.")]
]

STATUS_TYPES =
    SUCCESSFULLY_CLOSED: 6
    UNSUCCESSFULLY_CLOSED: 7

REQUIRED_FIELD_TYPES =
    COMMENT: 1,
    PAYMENT: 2,

[COMPANY_OR_LOCATION, EMPLOYEE, CLIENT] = range 3

ASSET_WH_TYPES = [
    {
        id: COMPANY_OR_LOCATION,
        title: __('Company or location'),
        info: __('To change the status manager has to confirm asset transfer to the selected warehouse or company. Specify warehouse type.')
    },
    {
        id: EMPLOYEE,
        title: __('Employee'),
        info: __('To change the status manager has to confirm asset transfer to the warehouse of the assigned specialist')
    },
    {
        id: CLIENT,
        title: __('Client'),
        info: __('To change the status manager has to confirm asset transfer to the customer')
    }
]

getStatus = (statusId) -> require('interface/Company').STATUSES_HASH[statusId] or {}

getTimeNormMeasures = ->
    toObject MEASURES

getTimeNormMapping = ->
    toObject TIME_NORM_MAPPING

getStatusesByInstance = (statuses = [], instance = 0) ->
  return statuses unless statuses.length
  statuses.filter((status) -> status.instance is instance)

getAllowedSeeStatuses = ->
    {ROLE} = require "interface/User"
    STATUSES = copy require("interface/Company").STATUSES

    STATUSES.filter ({roles_can_see}) -> ROLE in roles_can_see.ids

getAllowedSetStatuses = (status) ->
    {ROLE} = require "interface/User"
    {STATUSES} = require "interface/Company"

    STATUSES.filter ({id, roles_can_set}) ->
        id isnt status.id and ROLE in roles_can_set.ids and id in status.next_statuses

getLeadAllowedSeeStatuses = ->
    getStatusesByInstance(getAllowedSeeStatuses(), STATUS_INSTANCE.LEAD)

filterStatusIdsByInstance = (ids, instance) ->
    ids.filter((id) -> getStatus(id).instance is instance)

isClosedStatus = ({group}) ->
    group.type in _values(STATUS_TYPES)

module.exports = {
    getTimeNormMeasures, getTimeNormMapping, getStatusesByInstance,
    getAllowedSetStatuses, getAllowedSeeStatuses, getLeadAllowedSeeStatuses, getStatus,
    filterStatusIdsByInstance, isClosedStatus, TIME_NORM_UNIT, STATUS_INSTANCE,
    STATUS_TYPES, ASSET_WH_TYPES, REQUIRED_FIELD_TYPES,
}

