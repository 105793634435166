import _isNaN from 'lodash/isNaN';
import _isNull from 'lodash/isNull';
import _isNumber from 'lodash/isNumber';

export const round = (number, precision = 2) => {
  return Math.round(parseFloat(number, 10) * 10 ** precision) / 10 ** precision;
};

export const toFloat = (amount) => {
  return Number.isNaN(amount) ||
    amount === '' ||
    amount == null ||
    amount === undefined
    ? 0.0
    : parseFloat(amount);
};

export const toLocaleNumber = (amount, options = {}) => {
  const { DECIMAL_SEP = ',', RANK_SEP = ' ' } = require('interface/Company');
  const {
    decimalCount = null,
    decimalSeparator = DECIMAL_SEP,
    thousandsSeparator = RANK_SEP,
    cutExtraZeros = false,
  } = options;

  const defaultDecimalCount = 2;
  const absDecimalCount = _isNull(decimalCount)
    ? defaultDecimalCount
    : Math.abs(decimalCount);
  const preparedDecimalCount = Number.isNaN(absDecimalCount)
    ? defaultDecimalCount
    : absDecimalCount;

  const negativeSign = amount < 0 ? '-' : '';

  const roundedAmount = round(
    Math.abs(Number(amount) || 0),
    preparedDecimalCount,
  ).toFixed(preparedDecimalCount);

  const i = parseInt(roundedAmount, 10).toString();

  const j = i.length > 3 ? i.length % 3 : 0;

  const integer =
    negativeSign +
    (j ? i.substring(0, j) + thousandsSeparator : '') +
    i.substring(j).replace(/(\d{3})(?=\d)/g, `$1${thousandsSeparator}`);

  const remainder = round(Math.abs(roundedAmount - i), preparedDecimalCount)
    .toFixed(preparedDecimalCount)
    .slice(2);

  if (remainder === '0'.repeat(preparedDecimalCount) || !preparedDecimalCount) {
    return integer;
  }

  return (
    integer +
    decimalSeparator +
    (cutExtraZeros ? remainder.replace(/0+$/, '') : remainder)
  );
};

export const isFloat = (num) => {
  // check if the input value is a `number`
  if (_isNumber(num) && !_isNaN(num)) {
    // If it's not `integer` then it's `float`
    if (!Number.isInteger(num)) {
      return true;
    }
  }

  return false;
};
