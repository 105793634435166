export const DEFAULT_BARCODE_TYPE = 'ean13';

export const BARCODE_TYPE = {
  PRODUCTS: 'product',
};

export const SCANCODE_ITEM_TYPE = {
  EAN_13: 'ean13',
  EAN_8: 'ean8',
  UPC: 'upc',
  CODE_128: 'code128',
  QR_CODE: 'qrcode',
} as const;

export const BARCODE_ITEM_MAX_SYMBOLS = {
  [SCANCODE_ITEM_TYPE.EAN_13]: 13,
  [SCANCODE_ITEM_TYPE.EAN_8]: 8,
  [SCANCODE_ITEM_TYPE.UPC]: 12,
  [SCANCODE_ITEM_TYPE.CODE_128]: 64,
};

export const BARCODE_ITEMS = [
  { value: SCANCODE_ITEM_TYPE.EAN_13, label: 'EAN-13' },
  { value: SCANCODE_ITEM_TYPE.EAN_8, label: 'EAN-8' },
  { value: SCANCODE_ITEM_TYPE.UPC, label: 'UPC-A' },
  { value: SCANCODE_ITEM_TYPE.CODE_128, label: 'Code128' },
];
