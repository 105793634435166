export const PLAN_TYPE = {
  TRIAL: 0,
  TEST: 1,
  PARTNER: 2,
  HOBBY_OLD: 15, // Unlimited sales
  HOBBY_2019: 16, // Limited sales
  STARTUP_2019: 17,
  BUSINESS_2019: 18,
  MAXIMUM_2019: 19,
  HOBBY: 20, // Limited sales
  STARTUP: 21,
  BUSINESS: 22,
  ENTERPRISE: 23,
} as const;
