export const RANGE = {
  WEEK: 'week',
  MONTH: 'month',
};

export const SCALE = {
  LESS: 0,
  MORE: 1,
};

export const DAY_TYPE = {
  WORK: 0,
  VACATION: 1,
  SICK: 2,
  DAY_OFF: 3,
  NON_WORK: 4,
};

export const COUNTER = {
  DAYS: 'days',
  HOURS: 'hours',
};
