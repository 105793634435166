# User core lib.
{pubsubhub} = require "lib/helpers"

{fetchRequest} = require("@@services/transport/fetch")

# const string: module namespace.
NS = "user"

# User-related events.
USER_EVENT =
    # Fires when new user config is received from backend;
    # example handler looks like: handler(config)
    ON_CONFIG_REFRESH: "#{NS}_refresh"

# User events hub.
# Subscribe here for any user events you need.
user_hub = pubsubhub()


# Updates global app state related to user config.
# -> object state: new user state.
# Returns object: new user state.
setState = (state, cb) ->
    # Updating module state.
    module.exports[k] = v for own k, v of state

    # Publishing config refresh event.
    user_hub.pub(
        USER_EVENT.ON_CONFIG_REFRESH
        module.exports
    )

    cb and cb()

    # Returning value.
    module.exports


update = (cb) ->
    fetchRequest({
        url: "/settings/user"
        sccb: ({user}) -> cb setState user
    })


module.exports = {
    USER_EVENT
    user_hub
    setState
    update
}
