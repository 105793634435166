import { COUNT, DURATION, MONEY } from '@@constants/fieldTypes';

export const DIAGRAM_TYPE = 0;
export const TREND_TYPE = 1;

export const CHART_TYPE = {
  HORIZONTAL_BAR: 0,
  VERTICAL_BAR: 1,
  LINE: 2,
};

export const DIRECTION = {
  UP: 0,
  DOWN: 1,
};

export const PAYMENT_STATUS = {
  PAID: 0,
  NOT_PAID: 1,
  NO_MODEL: 2,
};

export const CUBES_STATUS = {
  NONE: 0,
  CREATING: 1,
  CREATED: 2,
};

export const ANALYTICS_LS_NAME = '/analytics/report';

export const BASE_CHART_COLORS = {
  [MONEY]: '#3AB57B',
  [COUNT]: '#428BCA',
  [DURATION]: '#F5BE3F',
};

export const CHART_COLORS = [
  '#8D2CA7',
  '#3AB57B',
  '#F5BE3F',
  '#F2522C',
  '#4054B3',
  '#7A88C9',
  '#616161',
  '#E47E76',
];

export const CHART_DATA_FIELD = '@@data_field';

export const LAST_30_DAYS = '30_days';
export const LAST_24_WEEKS = '24_weeks';
export const LAST_24_MONTHS = '24_months';

export const LAST_30_DAYS_DRILLDOWN = 'created_at@daily:day';
export const LAST_24_WEEKS_DRILLDOWN = 'created_at@week:week';
export const LAST_24_MONTHS_DRILLDOWN = 'created_at@daily:month';

export const CREATED_AT_YEAR = 'created_at.year';
export const CREATED_AT_YEAR_ISO = 'created_at.year_iso';
export const CREATED_AT_MONTH = 'created_at.month';
export const CREATED_AT_WEEK = 'created_at.week';
export const CREATED_AT_DAY = 'created_at.day';

export const WIDTHS_BY_AGGREGATES_NAMES = {
  fillout_time_avg: 220,
  total_summ_avg: 150,
  total_profit_sum: 180,
  discount_sum_avg: 170,
  total_sum_avg: 150,
  total_sum_sum: 150,
  total_discount_avg: 170,
  duration_sum: 240,
  duration_avg: 220,
};

export const WIDTHS_BY_AGGREGATES_TYPES = {
  [MONEY]: 120,
  [COUNT]: 120,
};

export const DIMENSION_TYPE = {
  AUTHOR: 'author',
  MANAGER: 'manager',
};
