import DndProvider from './provider';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withDndContext(Component, providerProps = {}) {
  function WithDndContext(props) {
    return (
      <DndProvider {...providerProps}>
        <Component {...props} />
      </DndProvider>
    );
  }

  WithDndContext.displayName = `WithDndContext(${getDisplayName(Component)})`;

  return WithDndContext;
}

export default withDndContext;
