###
Remonline Client library
(order/sale/refund etc client is meaned here)
###
omit = require "lodash/omit"

{fetchRequest} = require("@@services/transport/fetch")
{buildFilterURL} = require "lib/url"
{merge, exclude, each} = require "lib/fp"
{buildMeta, proto} = require "lib/reflection"
{toInt, toFloat, toBool, isArray, copy, last, keys} = require "lib/helpers"
{havePermission} = require "interface/IAccess"
P = require("@@constants/permissions/user").default
{error} = require "lib/logger"

# Core libs.
{AdCampaign} = require "lib/ad_campaign"


# const int: 'none' client juridical value.
JURIDICAL_NONE = null


# Person (physical/individual) client.
getPerson = () -> ({
    id: false
    title: __("Individual")
})


# Legal (company/entity/corp) client.
getLegal = () -> {
    id: true
    title: __("Company")
}


# Client juridicals collection.
getJuridicals = () -> [getPerson(), getLegal()]


# Fixed
getFixed = () -> {
    id: false
    title: __("Fixed")
}


# By type
getByPriceType = () -> {
    id: true
    title: __("By price type")
}


# Discount by
getDiscountContent = () -> [getFixed(), getByPriceType()]


# const int: nont-existing client id.
NONE_ID = -1


# Client.
class TClient

    # int: client id.
    id: NONE_ID

# float: client balance.
    balance: undefined

# [float]: client balances.
    balance_arr: [0.0, 0.0, 0.0]

    # bool: juridical value.
    juridical: getPerson().id

    # object: client as employee data.
    employee: null

    # bool: is client supplier?
    supplier: false

    # bool: is client conflicted?
    conflicted: false

    # string: name.
    name: ""

    # [object]: client phones.
    phone: []

    # string: email address.
    email: ""

    # bool: receive email notifications?
    should_send_email: true

    # string address.
    address: ""

    # string: discount code.
    discount_code: ""

    # int: services discount.
    services_pct: 0

    # int: materials discount.
    materials_pct: 0

    # int: products discount.
    goods_pct: 0

    # int: materials margin
    materials_margin_id: null

    # int: goods margin
    goods_margin_id: null

    # AdCampaign: marketing source.
    ad_campaign: new AdCampaign

    # string: notes.
    notes: ""

    # [string]: tags.
    tags: [ ]

    # bool: deleted client?
    deleted: false

    # <string, string>: custom fields values.
    custom: { }

    # int: amplitude event type
    event: undefined

    # Ctor.
    constructor: (o={ }) ->
        @id = toInt o.id, @id
        @balance = o.balance or @balance
        @balance_arr = o.balance_arr or @balance_arr
        @juridical = toBool o.juridical, @juridical
        @employee = o.employee or @employee
        @supplier = toBool o.supplier, @supplier
        @conflicted = toBool o.conflicted, @conflicted
        @name = o.name or @name
        @email = o.email or @email
        @should_send_email = toBool o.should_send_email, @should_send_email
        @address = o.address or @address
        @discount_code = o.discount_code or @discount_code
        @services_pct = toFloat o.services_pct, @services_pct
        @materials_pct = toFloat o.materials_pct, @materials_pct
        @goods_pct = toFloat o.goods_pct, @goods_pct
        @materials_margin_id = toInt o.materials_margin_id or @materials_margin_id
        @goods_margin_id = toInt o.goods_margin_id or @goods_margin_id
        @ad_campaign = new AdCampaign o.ad_campaign
        @notes = o.notes or @notes
        @tags = if isArray o.tags then o.tags else @tags
        @phone = if isArray o.phone then o.phone else @phone
        @deleted = toBool o.deleted, @deleted
        @event = o.event or @event
        @custom = o.custom or @custom

# TClient meta-class (model/reflection)
meta = buildMeta TClient


# Client service.
class IClient

    # const string: base URL.
    URL_BASE = "/settings"

    # const string: client tags URL.
    @URL_CLIENT_TAGS: "#{URL_BASE}/get-client-tags"

    # Converts given array of phones into payload format.
    # -> [object] array: phones array.
    # Returns object: payload representation.
    @convertPhone: (array) ->
        phones_json: JSON.stringify array
        phone:
            # Getting last phone from available ones
            # (as it was previosly realized in business-logic)
            if array.length is 0 then ""
            else last(array).phone

    # Returns object: AdCampaign in payload format.
    # -> AdCampaign ms: marketing source instance.
    @convertAdCampaign: (ms) -> ad_campaign_id: toInt(ms.id) or ""

    # Returns string: client tags in payload format.
    # -> [string] array: tags array.
    @convertTags: (array) -> tags: array.join ','

    # Converts TClient to payload data.
    # -> TClient client: source client.
    # Returns object: payload.
    @toPayload: (client) ->
        {phone, tags, custom, ad_campaign
            goods_margin_id, materials_margin_id
        } = payload = copy client

        payload = merge payload, @convertPhone phone
        payload = merge payload, @convertTags tags

        if goods_margin_id
            payload = omit(payload, ['goods_pct'])
        else payload = omit(payload, ['goods_margin_id'])

        if materials_margin_id
            payload = omit(payload, ['materials_pct'])
        else payload = omit(payload, ['materials_margin_id'])

        payload[k] = v for k, v of custom
        delete payload["custom"]

        payload = merge payload, @convertAdCampaign ad_campaign

        delete payload["ad_campaign"]

        payload

    # Returns object: key-value collection of custom client fields,
    #   where values are extracted from input object.
    # -> object o: input object.
    @extractCustom: (o) ->
        props = keys proto TClient
        each o, { }, (a, k, v) ->
            a[k] = v if k not in props

    # Performs client creation.
    # -> args: TClient client: client data.
    # -> opts: sccb, ercb: callbacks.
    # Returns void.
    @create: ({client}, opts={ }) ->
        # Converting to payload;
        # Sending request.
        fetchRequest(merge opts, {
            url: "#{URL_BASE}/create-client"
            method: "POST"
            # id is excluded since this is a new client.
            payload: exclude(@toPayload(client), ["id"])
            sccb: (data) =>
                # Converting response, to represent it as TClient.
                opts.sccb new TClient merge data, {
                    ad_campaign: new AdCampaign data.ad_campaign
                    phone: data.phone
                    custom: @extractCustom data
                }
        })

    # Performs client updating.
    # -> object fields: client fields to update.
    # -> opts: sccb, ercb: callbacks.
    # Returns void.
    @update: (fields, opts={ }) ->
        # Converting to payload.
        # Since input is a raw object, toPayload() is not used.
        {phone, ad_campaign, tags} = payload = copy fields

        # Due to backend realization,
        # phone should always have some value.
        payload = merge payload, @convertPhone phone or [ ]

        if ad_campaign then payload = merge(
            payload, @convertAdCampaign id: ad_campaign
        )

        if tags then payload = merge(
            payload, @convertTags tags
        )

        # Sending request.
        fetchRequest(merge opts, {
            url: "#{URL_BASE}/update-client"
            method: "POST"
            payload
            sccb: (data) ->
                # Converting response, to represent it as TClient.
                opts.sccb new TClient merge data, {
                    ad_campaign: new AdCampaign data.ad_campaign
                    phone: data.phone
                    custom: do ->
                        props = keys proto TClient
                        each data, { }, (a, k, v) ->
                            a[k] = v if k not in props
                }
        })

    # Performs client removal.
    # -> args: [int] ids: client ids.
    # -> opts: sccb, ercb: callbacks.
    # Returns void.
    @remove: ({ids}, opts={ }) ->
        fetchRequest(merge opts, {
            url: "#{URL_BASE}/delete-client"
            method: "POST"
            payload: {ids}
        })

    # Returns bool: is client deleted?
    @isDeleted: (client) -> client.deleted

    # Gets data of specified client.
    # -> args: int id: client id.
    # -> opts: sccb, ercb: callbacks.
    # Returns void.
    @get: ({id}, cbs = {}, opts={ }) ->
        # Sending request
        fetchRequest(merge cbs, {
            url: "#{URL_BASE}/get-client"
            payload: merge({}, {id}, opts)
            sccb: (response) =>
                cbs.sccb @createTClientByParsedData(response)
            })

    # Create TClient instance, basing on parsed data.
    # -> response: client object.
    # Returns TClient instance.
    @createTClientByParsedData: (response) ->
        # Parsing response object; getting custom fields.
        x = { };
        x[k] = v for k, v of response when @isCustom k

        # Creating TClient instance, basing on parsed data.
        new TClient merge response, custom: x

    # Suggest clients.
    # -> string q: query text.
    # -> opts: sccb, ercb: callbacks.
    # Returns void.
    @suggest: (q, opts={ }) ->
        # Sending request.
        fetchRequest(merge opts, {
            url: buildFilterURL("/settings/suggest/clients", q)
            sccb: (response) ->
                {count, data} = response

                # Parsing response data
                # with passing it to callback.
                opts.sccb toInt(count), data.map (o) ->
                    new TClient merge o, {
                        ad_campaign: new AdCampaign id: o.ad_campaign
                    }
        })

    # Generates discount code.
    # -> opts: sccb, ercb: callbacks.
    # Returns void.
    @genDiscountCode: ({sccb}) ->
        fetchRequest({
            url: "#{URL_BASE}/generate-discount-code"
            method: "POST"
            sccb
        })

    # Returns object: meta-class of TClient.
    @reflect: -> meta

    # Returns bool: is this a custom field?
    # -> string name: field name.
    @isCustom: (name) ->
        # If name is not in TClient fields names,
        # so this name is considered as custon one.
        name not in keys @reflect()

    juridical__title = {
        "#{getPerson().id}": getPerson().title
        "#{getLegal().id}": getLegal().title
    }

    # Returns string: juridical friendly name.
    @getJuridicalTitle: (juridical) ->
        juridical__title["#{juridical}"] or error "Unknown juridical #{juridical}"



# Client permissions.
class IPerms

    # Returns bool: can edit client?
    @canEdit: -> havePermission P.PERMISSION_EDIT_CLIENTS

    # Returns bool: can delete client?
    @canDelete: -> havePermission P.PERMISSION_CAN_DELETE_CLIENT

    # Returns bool: can edit client discount?
    @canEditDiscount: -> havePermission P.PERMISSION_EDIT_CLIENTS_DISCOUNT

    # Returns bool: can see clients?
    @canSee = -> havePermission P.PERMISSION_SEE_CLIENTS

    # Returns bool: can see `supplier` clients?
    @canSeeSuppliers = -> havePermission P.PERMISSION_SEE_SUPPLIERS

    # Returns bool: can export clients?
    @canExport = -> havePermission P.PERMISSION_CLIENTS_EXPORT

    # Returns bool: can import clients?
    @canImport = -> havePermission P.PERMISSION_CLIENTS_IMPORT


module.exports = {
    JURIDICAL_NONE
    getDiscountContent
    getFixed
    getJuridicals
    getPerson
    getLegal
    TClient
    IClient
    IPerms
}
