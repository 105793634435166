import _findIndex from 'lodash/findIndex';

export const syncWithPersistedFields = (fields, persistedFields) => {
  let realFields = [];
  const newFields = [];

  if (persistedFields) {
    fields.forEach((item, index) => {
      const persistFieldPos = _findIndex(
        persistedFields,
        (column) => column.field === item.field,
      );
      if (persistFieldPos < 0) {
        newFields.push([item, index]);
        return;
      }
      realFields[persistFieldPos] = {
        ...item,
        checked: persistedFields[persistFieldPos].checked,
        width: item.fixedWidth || persistedFields[persistFieldPos].width,
      };
    });

    if (newFields[0]) {
      newFields.reverse().forEach((item) => {
        if (item[0].groupId) {
          const index = _findIndex(
            realFields,
            (column) => column && column.groupId === item[0].groupId,
          );
          realFields.splice(index > -1 ? index + 1 : item[1], 0, item[0]);
        } else {
          realFields.splice(item[1], 0, item[0]);
        }
      });
    }

    realFields = realFields.filter((item) => item);
  } else {
    realFields = fields;
  }

  return realFields;
};
