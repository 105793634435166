###
Meta-objects.
###

{keys, copy} = require "lib/helpers"


# Meta-object record.
class TRecord

    # [func]: validation pipeline.
    pipeline: [ ]

    # object: template attributes.
    attrs: { }

    constructor: (o={ }) ->
        @pipeline = o.pipeline or @pipeline
        @attrs = o.attrs or @attrs


# Returns object: type prototype.
# -> T: type.
proto = (T) ->
    T.prototype


# Returns T: instance class.
# -> object instance: target instance.
classof = (instance) -> instance.constructor


# Returns object meta-object for given type.
# -> T: type.
buildMeta = (T) ->
    # Building default meta-object.
    # All fields of given type are described as default TRecord.
    meta = { }
    meta[name] = new TRecord for name in keys proto T
    meta


# Returns object: dumps given instance to plain object.
plain = (instance) -> copy instance


module.exports = {
    TRecord
    buildMeta
    classof
    proto
    plain
}
