import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    tasksInfo: {},
    invalidSessionFormat: null,
    sidebar: {
      isCollapsed: false,
      isShowSubmenu: false,
      isShowMenu: false,
    },
    avatar: '',
  },
  reducers: {
    toggleMenu(state) {
      state.sidebar.isShowMenu = !state.sidebar.isShowMenu;
    },
    collapseMenu(state) {
      state.sidebar.isCollapsed = !state.sidebar.isCollapsed;
    },
    toggleSubMenu(state) {
      state.sidebar.isShowSubmenu = !state.sidebar.isShowSubmenu;
    },
    changeInvalidSession(state, { payload }) {
      state.invalidSessionFormat = payload;
    },
    changeTasksInfo(state, { payload }) {
      state.tasksInfo = payload;
    },
    setAvatar(state, { payload }) {
      state.avatar = payload;
    },
  },
});

export const {
  toggleMenu,
  collapseMenu,
  toggleSubMenu,
  changeInvalidSession,
  changeTasksInfo,
  setAvatar,
} = uiSlice.actions;

export default uiSlice.reducer;
