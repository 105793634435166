import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Spinner.module.scss';

function Spinner(props) {
  const { top, left, size, color, withoutMargin } = props;

  const defaultSpinnerCls = classnames(styles.defaultSpinner, {
    [styles[`defaultSpinner_size_${size}`]]: size,
    [styles[`defaultSpinner_color_${color}`]]: color,
    [styles.defaultSpinner_withoutMargin]: withoutMargin,
  });

  const loaderStyles = {
    style: {
      ...(top && { top: `${top}px` }),
      ...(left && { left: `${left}px` }),
    },
  };

  return (
    <div className={styles.spinnerWrapper} {...loaderStyles}>
      <div data-cid='spinner' className={defaultSpinnerCls} />
    </div>
  );
}

Spinner.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  color: PropTypes.oneOf(['blue', 'orange', 'white']),
  withoutMargin: PropTypes.bool,
};

Spinner.defaultProps = {
  size: 'medium',
  color: 'blue',
  top: 0,
  left: 0,
  withoutMargin: false,
};

export default Spinner;
