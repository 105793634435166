require "lib/prototype/String"

React = require 'react'
ReactDOM = require 'react-dom/client'

App = require("@@modules/App").default
root = ReactDOM.createRoot document.getElementById("main-root")
root.render React.createElement(App)


