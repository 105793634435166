import { createSlice } from '@reduxjs/toolkit';

export const initialState = {};

const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    setShouldUpdateBadges(state, { payload }) {
      const { badgeType, shouldUpdateBadges } = payload;

      state[badgeType] = {
        shouldUpdateBadges,
      };
    },
  },
});

export const { setShouldUpdateBadges } = badgesSlice.actions;

export default badgesSlice.reducer;
