import { fetchRequest } from '@@services/transport/fetch';

export const createBranch = (payload, options) => {
  return fetchRequest({
    url: '/settings/create-branch',
    method: 'POST',
    payload,
    ...options,
  });
};

export const updateBranch = (payload, options) => {
  return fetchRequest({
    url: '/settings/update-branch',
    method: 'POST',
    payload,
    ...options,
  });
};

export const deleteBranch = (payload, options) => {
  return fetchRequest({
    url: '/settings/delete-branch',
    method: 'POST',
    payload,
    ...options,
  });
};

export const archiveBranch = (payload, options) => {
  return fetchRequest({
    url: '/settings/archive-branches',
    method: 'POST',
    payload,
    ...options,
  });
};

export const restoreBranch = (payload, options) => {
  return fetchRequest({
    url: '/settings/dearchive-branches',
    method: 'POST',
    payload,
    ...options,
  });
};

export const getAccessBranch = (payload, options) => {
  return fetchRequest({
    url: '/access/branch',
    payload,
    ...options,
  });
};

export const setBranch = (payload, options = {}) => {
  return fetchRequest({
    url: '/set-branch',
    method: 'POST',
    payload,
    ...options,
  });
};
