import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

export const initialState = [];

const callCardsSlice = createSlice({
  name: 'callCards',
  initialState,
  reducers: {
    showCallCard(state, { payload }) {
      const cardId = payload.call_id || payload.id || nanoid();

      state.push({ cardId, isShow: true, ...payload });
    },
    setCallCardShow(state, { payload }) {
      const { id, isShow } = payload;
      const index = state.findIndex(({ cardId }) => cardId === id);

      return state.map((callCard, i) => {
        return i === index ? { ...callCard, isShow } : callCard;
      });
    },
    setCallCardShowAll(state, { payload }) {
      const { isShow } = payload;

      return state.map((callCard) => {
        return { ...callCard, isShow };
      });
    },
    hideCallCard(state, { payload }) {
      return state.filter(({ cardId }) => cardId !== payload);
    },
    hideAllCallCard() {
      return [];
    },
  },
});

export const {
  showCallCard,
  setCallCardShow,
  setCallCardShowAll,
  hideCallCard,
  hideAllCallCard,
} = callCardsSlice.actions;

export default callCardsSlice.reducer;
