import { fetchRequest } from '@@services/transport/fetch';

export const getEvents = (payload, opts = {}) => {
  return fetchRequest({
    url: '/event-log/object-events/get',
    method: 'POST',
    payload,
    ...opts,
  });
};

export const fileAttach = (payload, opts = {}) => {
  return fetchRequest({
    url: '/event-log/file/attach',
    method: 'POST',
    payload,
    ...opts,
  });
};
