import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSystemNotification } from '@@api/headerNotifications';

export const initialState = {
  data: {},
  loading: false,
  error: false,
};

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { rejectWithValue }) => {
    const response = await getSystemNotification();

    if (response.message) {
      return rejectWithValue(response.message);
    }

    return response;
  },
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification: (state, { payload }) => {
      const { type, additionalData } = payload;

      state.data = {
        ...state.data,
        flag: { ...state.data.flag, [type]: true },
        ...additionalData,
      };
    },
    hideNotification: (state, { payload }) => {
      const { type } = payload;

      state.data = {
        ...state.data,
        flag: { ...state.data.flag, [type]: false },
      };
    },
    removeNotifications: (state) => {
      state.data = {};
    },
    setNotificationsData: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      state.data = {};
      state.loading = true;
      state.error = false;
    });

    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = false;
    });

    builder.addCase(fetchNotifications.rejected, (state) => {
      state.data = {};
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  showNotification,
  hideNotification,
  removeNotifications,
  setNotificationsData,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
