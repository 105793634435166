###
Atom.

Atoms are an efficient way to represent some state that will never need to be
coordinated with any other, and for which you wish to make synchronous changes.
###

{copy} = require "lib/helpers"


Atom = (state) ->
    @state = copy state
    @


is_atom = (x) ->
    x instanceof Atom


verify = (x, cb) ->
    if is_atom x then return cb()

    throw "deref & swap operates only with Atom, recieved - #{x}"


# Idempotent operation.
# Create new instance of the Atom with the `state`.
atom = (state) ->
    if is_atom state then return state

    new Atom state


# Getting the state of the `atom` without changes.
deref = (atom) -> verify atom, ->
    atom.state


# Swaps the value of `atom` to be:
#   => new_state = f current-state-of-atom, args...
swap = (atom, args..., f) -> verify atom, ->
    atom.state = f atom.state, args...
    atom


module.exports = {atom, deref, swap}
