import type { AxiosResponse } from 'axios';
import { HttpStatusCode } from 'axios';
import _isObject from 'lodash/isObject';

const REFRESH_TOKEN = 'refresh_token';

/*
 * Set tokens pair into the local storage.
 * Security. We should store only refresh token. Do not store access token in local storage. Those data is always
 *   accessible by JavaScript. Cookies can mitigate this risk using the httpOnly flag.
 * https://cheatsheetseries.owasp.org/cheatsheets/HTML5_Security_Cheat_Sheet.html#local-storage
 */
export const setTokens = (responseData?: {
  refresh_token?: string;
  refreshToken?: string;
}) => {
  if (!_isObject(responseData)) {
    return;
  }

  const { refresh_token, refreshToken } = responseData;

  if (refresh_token) {
    localStorage.setItem(REFRESH_TOKEN, refresh_token);
  }

  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
};

export const isTokenExpire = (response: AxiosResponse) => {
  return !!response && response.status === HttpStatusCode.Unauthorized;
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const removeTokens = () => {
  localStorage.removeItem(REFRESH_TOKEN);
};
