{fetchRequest} = require("@@services/transport/fetch")
assign = require "lodash/assign"
omit = require "lodash/omit"
sortBy = require "lodash/sortBy"
isArray = require "lodash/isArray"
_isEmpty = require "lodash/isEmpty"

{only} = require "lib/fp"
{bool, toInt, toBool} = require "lib/helpers"
{readonly, writable} = require "lib/DOM"

# Core libs.
{TField, IFieldSvc, onlyVisible} = require "lib/forms/fields"
{reload, get, bind, create} = require "lib/forms/transport"

{NAME, isActive} = require "interface/Integrations"

{ validateImei } = require('@@helpers/validate');

# Relation between assetfield and response from backend
class TAssetFormEntry

  # int: context position.
  pos: 0

  # bool: is field required in context of assettype?
  required: false

  # string: default value.
  default_value: ""

  # bool: visible in order field?
  # Shoulb be the same as in AssetFormEntry on backend.
  card_visible: false

  # bool: Forbidden editing after creation
  is_forbidden_after_creation: false

  # bool: Forbidden deleting
  is_forbidden_for_deletion: false

  constructor: (o) ->
    @required = toBool o.required, @required
    @card_visible = toBool o.card_visible, @card_visible
    @is_forbidden_after_creation = toBool o.is_forbidden_after_creation, @is_forbidden_after_creation
    @is_forbidden_for_deletion = toBool o.is_forbidden_for_deletion, @is_forbidden_for_deletion
    @default_value = o.default_value or @default_value
    @pos = toInt o.pos, @pos

# Asset form field.
class TAssetField extends TField

  types: [ ]

  constructor: (o) ->
    super o
    @types = (
      if isArray(o.types)
        o.types.map (item) ->
          new TAssetFormEntry item
      else @types
    )


# Fields cache.
# Only for internal usage!
cache = []

initCache = (objects = []) ->
  return objects if not objects.length
  cache = objects.map (obj) -> new TAssetField obj

# Updates cache value.
# -> TAssetField field1: new field data.
# Returns TAssetField: field1.
reCache = (field1) ->
  # Getting field index.
  in_composite = []

  index = -1
  for field, i in cache
    index = i if field.id is field1.id
    if field1.values
      in_composite.push(i) if field.id.toString() in field1.values.split(",")

  # Updating cache.
  if index is -1 then cache.push field1
  else cache[index] = field1
  if field1.types.length
    for i in in_composite
      cache[i].types[0].is_forbidden_after_creation = field1.types[0].is_forbidden_after_creation
      cache[i].types[0].card_visible = field1.types[0].card_visible

  # Returning passed field.
  field1

# Asset form field service.
class IAssetFieldSvc extends IFieldSvc
  UID = ''

  VIN_CACHE = {}

  IMEI_CACHE = {}

  # const string: API base URL.
  URL_BASE = "/forms/assets"

  # const int: backend field type.
  fieldtype = "asset"

  checkerValues = {}

  getImeiChecker: () -> checkerValues

  reload: ({sccb} = {}) ->
    reload {fieldtype}, {
      sccb: (res) ->
        initCache res
        sccb()
      }

  # -> opts: bool first: insert field first or last?
  # -> opts: void sccb, ercb: callbacks.
  create: (field, opts={ }) =>
    # Transforming field to payload.
    # Field is binided to first type in types array.
    {book_id, types} = field
    binding = types[0]

    field_data = omit field, [
      "types"
      "book_id" unless book_id
    ]

    # set entity_id = -1 as NONE_TYPE
    payload = only {
      fieldtype
      field: JSON.stringify field_data
      place: if binding then JSON.stringify {
        first: opts.first or false
        card_visible: binding.card_visible
        is_forbidden_after_creation: binding.is_forbidden_after_creation
        is_forbidden_for_deletion: binding.is_forbidden_for_deletion
        required: binding.required or false
        entity_id: -1
      }
    }, bool

    create payload, assign {}, opts, {
      sccb: (res) => opts.sccb reCache new TAssetField res
    }

  # -> int id: field id.
  get: (id, {sccb}) ->
    get {id, fieldtype}, {
      sccb: (res) -> sccb reCache new TAssetField res if sccb
    }

  # -> int id: field id.
  # -> opts: bool first: insert field first or last?
  # -> opts: void sccb, ercb: callbacks.
  bind: (id, entity_id, opts={}) ->
    payload = {
      id
      fieldtype
      place: JSON.stringify {
        first: opts.first or false
        entity_id
        required: false
      }
    }

    bind payload, {
      sccb: (field) -> opts.sccb reCache new TAssetField field
    }

  isExist: ({types}) -> return !!types.length


  getVisFields: (isForm = true) ->
      sorted = []
      visibleFields = onlyVisible(cache)

      visibleFields.map (field) ->
          field.types.length && field.types.map ({pos}) ->
            sorted.push({field, pos})

      if !isForm and sorted.length
        sortBy(sorted, 'pos').map ({field}) -> field
      else sortBy(visibleFields, 'id')

  getVisFieldsWithoutGroup: (isForm = true) ->
    @getVisFields(isForm).filter(({name}) -> name != 'group')

  getFieldName: (name) ->
    if ['imei', 'vin', 'serial_number'].indexOf(name) > -1
      return 'uid'
    else name

  isAssetField: (name) ->
    ['group', 'brand', 'model', 'modification'].indexOf(name) > -1

  cleanImeiValue: () -> 
    UID = ''

  uidChecker: (nodeWrapper, {sccb} = {}) ->
    nodeWrapper.focusout(() ->
      input = nodeWrapper.find('input')
      uid = input?.val()?.replace(/\s/g, '') or ''

      if UID != uid and validateImei(uid) and isActive(NAME.IMEI_LOOKUP)
        if IMEI_CACHE[uid]
          UID = uid
          checkerValues = assign({}, IMEI_CACHE[uid])
          sccb(checkerValues, true)
        else
          readonly(input)

          fetchRequest({
            url: '/integrations/service/imei-lookup'
            payload: {imei: uid}
            sccb: (response) ->
              writable(input)

              if response.found
                checkerValues = assign({}, response)
              else
                checkerValues = {}

              UID = uid
              IMEI_CACHE[uid] = checkerValues
              sccb(checkerValues)

            ercb: () -> writable(input)
          })

      if UID != uid and uid.length == 17 and isActive(NAME.VIN_LOOKUP)
        if VIN_CACHE[uid]
          UID = uid
          checkerValues = assign({}, VIN_CACHE[uid])
          sccb(checkerValues, true)
        else
          readonly(input)

          fetchRequest({
            url: '/integrations/service/vin-lookup'
            payload: {vin: uid}
            sccb: (response) ->
              writable(input)

              if !_isEmpty(response)
                checkerValues = assign({}, response)
              else
                checkerValues = {}
              
              UID = uid
              VIN_CACHE[uid] = checkerValues
              sccb(checkerValues, true)

            ercb: () -> writable(input)
          })
    );

# Use it everywhere in project.
assetFieldService = new IAssetFieldSvc()


module.exports = {
  initCache
  reCache
  TAssetFormEntry
  TAssetField
  IAssetFieldSvc
  assetFieldService
}
