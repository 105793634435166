import { useMemo } from 'react';
import { DndProvider as ReactDndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import PropTypes from 'prop-types';

function DndProvider(props) {
  const { children, provider, options } = props;

  const touchBackendOptions = useMemo(
    () => ({
      enableMouseEvents: true,
      ...options,
    }),
    [options],
  );

  return (
    <ReactDndProvider backend={provider} options={touchBackendOptions}>
      {children}
    </ReactDndProvider>
  );
}

DndProvider.propTypes = {
  children: PropTypes.node.isRequired,
  /** backend provider (touch or html5) */
  provider: PropTypes.any,
  /** touch backend options */
  options: PropTypes.objectOf(PropTypes.any),
};

DndProvider.defaultProps = {
  provider: TouchBackend,
  options: {},
};

export default DndProvider;
