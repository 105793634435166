export const STATUSES_GROUP_TYPES = {
  EMPTY: 0,
  ALLOWED_SET: 1,
  FILTERED_STATUSES: 2,
} as const;

// Must be synced with remonline/lib/utils/status.py
export const STATUSES_TYPES = {
  NONE: 0,
  NEW: 1,
  IN_PROCESS: 2,
  DELAYED: 3,
  EXECUTED: 4,
  DELIVERY: 5,
  CLOSED_SUCCESSFULLY: 6,
  CLOSED_UNSUCCESSFULLY: 7,
} as const;

export const CLOSED_STATUS_TYPES = [
  STATUSES_TYPES.CLOSED_SUCCESSFULLY,
  STATUSES_TYPES.CLOSED_UNSUCCESSFULLY,
] as const;

export const MODULE_TYPE = {
  CHOOSE_DIALOG: 0,
} as const;

export const STATUS_REQUIRED_ACTION_TYPES = {
  COMMENT: 'comment',
  PAYMENT: 'payment',
  ORDER: 'order',
  SALE: 'sale',
} as const;

// Must be synced with remonline/status/constants.py
export const STATUS_INSTANCE = {
  ORDER: 0,
  LEAD: 1,
  ESTIMATE: 8,
} as const;

export const STATUSES_REQUIRED_FIELDS = [
  {
    id: 1,
    instance: STATUS_INSTANCE.ORDER,
    type: 'comment',
    name: __('Comment'),
  },
  {
    id: 2,
    instance: STATUS_INSTANCE.ORDER,
    type: 'payment',
    name: __('Create payment'),
  },
  {
    id: 3,
    instance: STATUS_INSTANCE.LEAD,
    type: 'order',
    name: __('Create order'),
  },
  {
    id: 4,
    instance: STATUS_INSTANCE.LEAD,
    type: 'sale',
    name: __('Create sale'),
  },
  {
    id: 1,
    instance: STATUS_INSTANCE.LEAD,
    type: 'comment',
    name: __('Comment'),
  },
  {
    id: 1,
    instance: STATUS_INSTANCE.ESTIMATE,
    type: 'comment',
    name: __('Comment'),
  },
  {
    id: 3,
    instance: STATUS_INSTANCE.ESTIMATE,
    type: 'order',
    name: __('Create order'),
  },
];

export const DEFAULT_STATUS_ID = -1;
