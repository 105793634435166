export enum EventType {
  // ORDER
  ORDER_CREATED = 28,
  ORDER_DELETED = 33,
  ORDER_LOCATION_CHANGED = 9,
  ORDER_STATUS_CHANGED = 0,
  ORDER_CLIENT_CHANGED = 32,
  ORDER_TYPE_CHANGED = 29,
  ORDER_DEADLINE_CHANGED = 11,
  ORDER_SCHEDULED_AT_CHANGED = 30,
  ORDER_MANAGER_CHANGED = 1,
  ORDER_ENGINEER_CHANGED = 2,
  ORDER_WORK_ADDED = 6,
  ORDER_WORK_DELETED = 10,
  ORDER_SERVICE_ADDED = 26,
  ORDER_SERVICE_DELETED = 27,
  ORDER_PART_ADDED = 7,
  ORDER_UNCOMPLETED_ADDED = 21,
  ORDER_PART_DELETED = 8,
  ORDER_UNCOMPLETED_DELETED = 23,
  ORDER_LEAD_CHANGED = 22,
  ORDER_IN_DEBT_CLOSED = 25,

  // ESTIMATE
  DOCUMENT_ACCEPTED = 77,
  DOCUMENT_DECLINED = 78,

  // LEAD
  LEAD_CREATED = 53,
  LEAD_DELETED = 54,
  LEAD_STATUS_CHANGED = 55,
  LEAD_CLIENT_CHANGED = 56,
  LEAD_TYPE_CHANGED = 59,
  LEAD_DEADLINE_CHANGED = 60,
  LEAD_LOCATION_CHANGED = 61,
  LEAD_MANAGER_CHANGED = 62,

  // TASK
  TASK_CREATED = 17,
  TASK_DELETED = 18,
  TASK_COMPLETED = 19,
  TASK_UNCOMPLETED = 20,
  TASK_DEADLINE_CHANGED = 57,
  TASK_ASSIGNEE_CHANGED = 58,

  // CLIENT
  CLIENT_CREATED = 49,
  CLIENT_DELETED = 50,
  CUSTOMER_FEEDBACK_CREATED = 14,
  CLIENT_DISCOUNT_CHANGED = 76,

  // WAREHOUSE
  WAREHOUSE_POSTING_CREATED = 36,
  WAREHOUSE_POSTING_DELETED = 37,
  WAREHOUSE_WRITE_OFF_CREATED = 39,
  WAREHOUSE_WRITE_OFF_DELETED = 40,
  WAREHOUSE_TRANSFER_CREATED = 41,
  WAREHOUSE_TRANSFER_DELETED = 42,
  STOCK_TAKE_DELETED = 43,
  STOCK_TAKE_CREATED = 44,
  SUPPLIER_RETURN_CREATED = 45,
  SUPPLIER_RETURN_DELETED = 46,
  WAREHOUSE_PRODUCT_CREATED = 67,
  WAREHOUSE_PRODUCT_DELETED = 68,
  WAREHOUSE_PRODUCT_RESTORED = 69,
  WAREHOUSE_PRODUCT_TITLE_CHANGED = 70,

  // SALE
  SALE_CREATED = 35,
  SALE_DELETED = 38,

  // FINANCE
  FISCAL_RECEIPT_PRINTED = 15,
  CLIENT_REFUND_CREATED = 47,
  CLIENT_REFUND_DELETED = 48,
  INVOICE_CREATED = 63,
  INVOICE_DELETED = 64,
  CASHBOX_CREATED = 65,
  CASHBOX_DELETED = 66,

  // CALLS & SMS
  CALL_OUTGOING = 12,
  CALL_INCOMING = 13,
  SMS_SENT = 3,

  // AUTHENTICATION
  USER_LOGGED_IN = 31,

  // SETTINGS
  EMPLOYEE_CREATED = 71,
  EMPLOYEE_DELETED = 72,
  LOCATION_CREATED = 51,
  LOCATION_DELETED = 52,
  WAREHOUSE_CREATED = 73,
  WAREHOUSE_DELETED = 74,

  // SALARY
  COMMISSION_CREATED = 81,
  COMMISSION_CHANGED = 82,
  COMMISSION_USED = 83,
  COMMISSION_CENCELED = 84,
  COMMISSION_DELETED = 85,

  // OTHER
  ATTACHMENT_CREATED = 5,
  ATTACHMENT_DELETED = 16,
  COMMENT_CREATED = 4,
}

export enum EventContextType {
  SYSTEM = 1,
  ORDER = 2,
  LEAD = 3,
  CLIENT = 4,
  SALE = 5,
  TASK = 6,
  WAREHOUSE = 7,
  REFUND = 8,
  INVOICE = 9,
}
