###
    Validation module.

    Used for client-side validation of the forms.
    Form validation is based on two main arguments:
        1. The container Node of the form;
        2. Validation schema.

    The scheme is a set of rules by which validation occurs. It has the
        following form:

    schema = [
        name: [required]
        phone: [required, phone]
        email: [email]

        <name>: [<rule> ... <rule>]
    ]

    Where the `name` - the name of the attribute that should identify the Node.
    Example:
        <input type="text" name="phone" />
        <input type="text" name="first-name" />

        schema = [
            phone: [<rule>]
            "first-name": [<rule>]
        ]

        ...and perform validation:
            result = (validate jnode, schema)
            result є {1, 0}

    All the new rules, you must describe in this module.
###
_isNumber = require('lodash/isNumber')

{now, toTimestamp} = require "@@helpers/format/date"
{merge, all} = require "lib/fp"
{trim, bool, key, val, isNumber, isInteger, render, getDataByPath} = require "lib/helpers"
{dataToOpts} = require "lib/DOM"
{warn} = require "lib/logger"

{toast} = require('@@helpers/toast')

REG =
    EMAIL: /^[+\w\d.-]+@[a-zA-Z\d.-]+(\.[a-zA-Z\d.-]+)+?$/
    LOGIN: "^[a-zA-Z0-9-_%+:\\.@]+$"
    PASSWORD: "^[a-zA-Z0-9`~!@#$%^&*()_\\-+={}\\[\\]|:;\"'<>,.?/\\\\]+$"
    IPV4: "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"

MSG =
    REQUIRE: __("The field cannot be empty")
    UNIQUE: (title) -> __("Service \"%(title)s\" already exists").format({title})
    NUMBER: __("Enter a number value")
    INTEGER: __("Enter an integer value")
    EMAIL: __("Enter a valid email")
    PHONE: __("Enter a valid phone number")
    LOGIN: __("Login can only consist of Latin characters and digits")
    PASSWORD: __("Password contains invalid characters")
    CONDITIONAL: __("Invalid condition")
    IPV4: __("Invalid IP address format")
    FUTURE_TIME: __("You cannot select the future time")
    MIN: (val) -> __("Enter the value no less than %(val)s").format({val})
    MAX: (val) -> __("Enter the value less than %(val)s").format({val})
    RANGE: (i, j) -> __("Enter the value between %(i)s and %(j)s").format({i, j})
    MAX_LENGTH: (length) -> __("The number of characters must not exceed %(length)s").format({length})
    MIN_LENGTH: (length) -> __("The number of characters must be no less than %(length)s").format({length})

VALIDATION_TEXT =
    MIN_LOGIN_LENGTH: (length) -> __("Login cannot be shorter than %(length)s characters").format({length})
    MIN_PASSWORD_LENGTH: (length) -> __("The password cannot be shorter than %(length)s characters long").format({length})

backendNotifyKey = '_notify_'
drfBackendNotifyKey = 'Notify'

findErrors = (container) ->
    # Find an error in the current node and in the children.
    container
        .find(".errors-container")
        .addBack(".errors-container")

getErrorMsg = (msgs) ->
    return if typeof msgs is "string" then msgs else getErrorMsg msgs[0]

tplError = (msgs, position, alignment = "left", paddingpayment) ->
    tplClassName = "validate-error #{alignment}"
    if position is "absolute"
        tplClassName += " abs"

    if paddingpayment
        tplClassName += " paddingPayment"

    ["div", {"class": tplClassName},
        getErrorMsg(msgs)
    ]

hasErrors = (container) ->
    findErrors(container).length > 0

hideErrors = (container, {isGlobal = true} = {}) ->
    container = findErrors(if container instanceof jQuery then container else jQuery(container))
    # Clear all error messages.
    container.removeClass("errors-container")

    # TODO: this line of code removes error globally,
    # not only within container - why? I don't think this is a good behaviour.
    if isGlobal
        jQuery(".validate-error").remove()
    else
        container.find('.validate-error').remove()


# This is local-specific version of hideErrors(),
# cos hideErrors() one is works globally (which is strange).
# -> jquery container: target container.
# Returns void.
hideErrorsInContainer = (container) ->
    container.find(".validate-error").remove()
    return


showErrors = (errors) ->

    _iterator = (obj) ->
        {result, jnode, opts} = val obj
        contClassName = "errors-container"
        dropdowns = jnode.parents ".ui-dropdown"
        anchor = if dropdowns.length is 0 then jnode else dropdowns
        wrapperMsg = anchor.parent()

        if opts
            if opts.wrapper is 'parent'
                wrapperMsg  = wrapperMsg.parent()

            if opts.wrapper is 'child'
                wrapperMsg  = anchor

            if opts.position is 'absolute'
                contClassName += " rel"

            if opts.place
                wrapperMsg = jQuery opts.place

            if opts.message
                result = opts.message

        anchor.parent().addClass contClassName
        if not wrapperMsg.children('.validate-error').length
            wrapperMsg.append render tplError result, opts?.position, opts?.alignment, opts?.paddingpayment

    errors.map _iterator

###
    Validate given `container` with the `model`. Also you can pass additional
        `opts` sush as positioning options of the tooltip.

    Example:
        =>
            VALIDATION_SCHEMA = [
                {value: [required, number, min 0]}
                {description: [required]}
            ]

            isValid = validate container, VALIDATION_SCHEMA
            return null unless isValid

    Each key of object of `model` array is the `name` attribute of the DOM node.
    In case of `isValid` is `false` it will throw tooltips with errors over
        broken nodes.
###
validate = (container, model, opts = {}) ->
    _iterator = (obj) ->
        name = key obj
        rules = val obj
        # In order we have 2 field with same name
        # for valid check need grep only visible node
        jnode = container.find "[name='#{name}']#{if name is 'ad_campaign_id' then ':visible' else ''}"
        # You can pass additional parameters for the validation function via data
        #   attributes. Currently supports only `msg` arg in the `required` validator.
        nodeOpts = dataToOpts "validation", jnode
        error = {}

        if jnode.length > 1
            jnode.each((index, item) ->
                node = jQuery(item)
                result = rules.map (f) -> f node.val(), nodeOpts
                # Get all invalid results.
                result = result.filter (i) -> i isnt true

                return error unless bool result
                error[name] = {result, jnode: node, opts: merge(opts, nodeOpts)}
            )

        else if jnode.length > 0
            result = rules.map (f) -> f jnode.val(), nodeOpts
            # Get all invalid results.
            result = result.filter (i) -> i isnt true

            return error unless bool result
            error[name] = {result, jnode, opts: merge(opts, nodeOpts)}

        else
            # Program error.
            warn "can't find node with the attr `#{name}`"

        error

    errors = model.map _iterator
    errors = errors.filter (i) -> bool i
    hideErrors container
    if bool errors then (showErrors errors); false else true

withValidation = (container, schema, cb) ->
    if validate container, schema
        return cb()

    return false

# Throw a custom error.
throwError = (jnode, msg, opts) ->
    nodeOpts = dataToOpts "validation", jnode
    showErrors [{"_error": {result: [msg], jnode: jnode, opts: merge(opts, nodeOpts)}}]

###
    Dispatch and display errors given from the `xhr` in various vays (dependent
        on the error prefix).

    displayErrors(xhr):
        Just process given erorrs from the AJAX response.

    displayErrors(form, xhr):
        Process and display errors on the given `form`.
###
displayErrors = (form, xhr, opts) ->
    # Overloading.
    if xhr is undefined
        xhr = form; form = undefined

    return undefined unless xhr.message

    # xhr.message - this is a deprecated method for displaying errors.
    errors = xhr.message.validation or xhr.message

    for own k, v of errors
        # Dispatch notification method dependent on the key prefix.
        if k.indexOf(backendNotifyKey) is 0 or k.indexOf(drfBackendNotifyKey) is 0
            toast.error v
        else if form
            container = if form instanceof jQuery then form else jQuery form
            throwError container.find("[name=\"#{k}\"]"), v, opts
        else
            warn "undefined error notification type: #{k}"

test = (validator, args...) ->
    return (validator args...) is true

required = (val, {msg}={}) -> if bool trim val then true else msg or MSG.REQUIRE

unique = (items, path=undefined, {msg}={}) -> (val) ->
    # Do not check for empty values.
    value = trim val.toLowerCase()
    return true unless bool value
    values = if path then getDataByPath(items, path) else items

    if values.length and values.indexOf(value) > -1 then msg or MSG.UNIQUE val else true

number = (val) ->
    # Do not check for empty values.
    return true unless bool val

    if isNumber val then true else MSG.NUMBER

integer = (val) ->
    # Do not check for empty values.
    return true unless bool val

    if isInteger val then true else MSG.INTEGER

min = (val, msg) -> (inputed) ->
    # Do not check for empty values.
    return true unless bool inputed

    if inputed >= val then true else msg or MSG.MIN val

max = (val, msg) -> (inputed) ->
    # Do not check for empty values.
    return true unless bool inputed

    if inputed <= val then true else msg or MSG.MAX val

range = ([_min, _max], msg) -> (val) ->
    # Do not check for empty values.
    return true unless bool val

    condition = val >= _min and val <= _max
    if condition then true else msg or MSG.RANGE _min, _max

maxLength = (val, msg) -> (inputed) ->
    # Do not check for empty values.
    return true unless bool inputed

    if inputed.length <= val then true else msg or MSG.MAX_LENGTH val

minLength = (val, msg) -> (inputed) ->
    # Do not check for empty values.
    return true unless bool inputed

    if inputed.length >= val then true else msg or MSG.MIN_LENGTH val

email = (val) ->
    # Do not check for empty values.
    return true unless bool val

    pattern = new RegExp REG.EMAIL, "gi"
    if (pattern.test val) then true else MSG.EMAIL

multyEmail = (emails) ->
    # Do not check for empty values.
    return true unless bool emails

    pattern = new RegExp REG.EMAIL, "gi"
    emails = emails.split(',').map trim
    checks = emails.map (email) ->
        pattern.lastIndex = 0
        pattern.test email
    if all checks then true else MSG.EMAIL

login = (val) ->
    # Do not check for empty values.
    return true unless bool val

    pattern = new RegExp REG.LOGIN, "gi"
    if (pattern.test val) then true else MSG.LOGIN

password = (val) ->
    # Do not check for empty values.
    return true unless bool val

    pattern = new RegExp REG.PASSWORD, "gi"
    if (pattern.test val) then true else MSG.PASSWORD

matchExact = (regExpString, msg) -> (val) ->
    # Do not check for empty values.
    return true unless bool val

    pattern = new RegExp regExpString, "gi"
    match = val.match pattern
    if match isnt null and match[0] is val then true else msg

ipv4 = (val) ->
    # Do not check for empty values.
    return true unless bool val

    pattern = new RegExp REG.IPV4, "gi"
    if (pattern.test val) then true else MSG.IPV4

conditional = (condition, msg) -> (inputed) ->
    # Do not check for empty values.
    return true unless bool inputed

    if bool condition(inputed) then true else msg or MSG.CONDITIONAL

is_future_time = (ts, opts = {}) ->
    {format} = opts
    if (!_isNumber(ts)) then ts = toTimestamp(ts, format)
    if ts > now() then MSG.FUTURE_TIME else true

# XXX: https://remonline.atlassian.net/browse/DEV-1343
# Number usability correction.
wrap_normalize_number = (chain) ->
    chain = chain.map (f) -> (v) ->
        if v
            v = v.replace ",", "."
        f v

    chain


validateTag = (tags) ->
    # Do not check for empty values.
    return true unless bool tags

    for tag in tags.split(',')
        if tag.length > 32
            return MSG.MAX_LENGTH 32

    return true


module.exports = {
    test, hideErrors, validate, throwError, required, unique, number, integer, min, max, range,
    email, hasErrors, displayErrors, login, multyEmail, password, matchExact, is_future_time,
    conditional, withValidation, maxLength, minLength, findErrors, ipv4,
    wrap_normalize_number, validateTag, hideErrorsInContainer, backendNotifyKey,
    VALIDATION_TEXT, MSG
}
