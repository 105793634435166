import { EventContextType } from '@@types/events';

export type TaskStatus = 'open' | 'closed';

export interface TaskEmployee {
  id: number;
  fullname: string;
}

export enum TaskRelObjType {
  ORDER = EventContextType.ORDER,
  LEAD = EventContextType.LEAD,
  CLIENT = EventContextType.CLIENT,
  SALE = EventContextType.SALE,
  TASK = EventContextType.TASK,
}

export interface TaskRelObj {
  type: (typeof TaskRelObjType)[keyof typeof TaskRelObjType];
  id: number;
  name: string;
}

export interface Task {
  id: number;
  created: number;
  status: TaskStatus;
  author: TaskEmployee;
  summary: string;
  description: string;
  deadline: number;
  closed?: number;
  closed_by?: TaskEmployee;
  rel_obj?: TaskRelObj;
  comment: string;
  assignees: TaskEmployee[];
  is_deleted: boolean;
}

export enum AssigneeType {
  SAME_TASK = 0,
  UNIQUE_TASK = 1,
}

export interface TaskIdArgs {
  task_id: number;
}

export interface GetTasksArgs {
  relation: 'assignee' | 'author' | 'all';
  authors?: number[] | null;
  assignees?: number[] | null;
  page?: number;
  q?: string;
}

export interface GetClosedTasksArgs extends GetTasksArgs {
  closed_start: number;
  closed_end: number;
}

export type TaskCreateArgs = Pick<
  Task,
  'assignees' | 'comment' | 'deadline' | 'description' | 'summary' | 'rel_obj'
> & { assignee_type: AssigneeType.SAME_TASK | AssigneeType.UNIQUE_TASK };

export type TaskUpdateArgs = Pick<
  Task,
  'assignees' | 'comment' | 'deadline' | 'description' | 'summary' | 'rel_obj'
> &
  TaskIdArgs;

export type TaskFlashbackArgs = Pick<Task, 'comment'> & TaskIdArgs;
