import { getRequest } from '@@helpers/transport/retry';

export const getRequestParams = (requestId: string) => {
  const { method, params } = (getRequest(requestId) || {}) as {
    method: string;
    params: object;
  };

  switch (method) {
    case 'legacy':
      return { ...params, shouldCallCallbacks: false };
    case 'rpc':
      return params;
    default:
      return true;
  }
};
