{hashTable} = require "lib/fp"
{pubsubhub} = require "lib/helpers"
{STATUS_INSTANCE} = require "lib/statuses"

{fetchRequest} = require("@@services/transport/fetch")

EVENT =
    ON_CONFIG_REFRESH: "refresh"

hub = pubsubhub()

findBranch = (id) ->
    branches = module.exports.BRANCHES or []
    for branch in branches when branch.id is parseInt id, 10
        return branch

    undefined

findOrderType = (id) ->
    types = module.exports.ORDER_TYPES or []
    for type in types when type.id is parseInt id, 10
        return type

    undefined

findLeadType = (id) ->
    types = module.exports.LEAD_TYPES or []
    for type in types when type.id is parseInt id, 10
        return type

    undefined

findCashbox = (id) ->
    cashboxes = module.exports.CASHBOXES or []
    for cashbox in cashboxes when cashbox.id is parseInt id, 10
        return cashbox

    undefined

getOrderTypes = (withDeleted) ->
    if withDeleted is true
        return module.exports.ORDER_TYPES

    module.exports.ORDER_TYPES.filter (o) -> o.deleted is false

getLeadsTypes = (withDeleted) ->
    if withDeleted is true
        return module.exports.LEAD_TYPES

    module.exports.LEAD_TYPES.filter (o) -> o.deleted is false

filterCashboxes = (cond) -> module.exports.CASHBOXES.filter cond

setStaffDefaultState = () ->
    module.exports.CURRENCY = "EUR"
    module.exports.CURRENCY_NAME = "Euro"
    module.exports.CURRENCY_SYMBOL = "€"
    module.exports.CURRENCY_SYMBOL_LEFT = true
    module.exports.DECIMAL_SEP = ","
    module.exports.RANK_SEP = " "
    module.exports.TIME_FORMAT = "24H"
    module.exports.WEEK_START = "Monday"

    module.exports

setState = (state) ->
    if module.exports.TIMEZONE
        require("@@helpers/timezone/migrations").migrateLocalStorageDates(
            module.exports.TIMEZONE,
            state.TIMEZONE
        )

    for own k, v of state
        module.exports[k] = v

    module.exports.ACTIVE_EMPLOYEES = module.exports.EMPLOYEES.filter (x) -> not x.isDeleted
    module.exports.ACTIVE_BRANCHES = module.exports.BRANCHES.filter (x) -> not x.deleted and not x.archived

    module.exports.ARCHIVE_BRANCHES = module.exports.BRANCHES.filter (x) -> x.archived

    # Set statuses hash for the faster processing.
    module.exports.STATUSES_HASH = hashTable "id", module.exports.STATUSES
    module.exports.BRANCHES_HASH = hashTable "id", module.exports.BRANCHES
    module.exports.EMPLOYEES_HASH = hashTable "id", module.exports.EMPLOYEES
    module.exports.ORDER_TYPES_HASH = hashTable "id", module.exports.ORDER_TYPES
    module.exports.LEAD_TYPES_HASH = hashTable "id", module.exports.LEAD_TYPES
    module.exports.COMPANY_PHONES_HASH = hashTable "id", module.exports.COMPANY_PHONES
    module.exports.WAREHOUSES_HASH = hashTable "id", module.exports.WAREHOUSES
    module.exports.BRANCHES_HASH = hashTable "id", module.exports.BRANCHES
    module.exports.UOMS_HASH = hashTable "id", module.exports.UOMS

    # Publishing config refresh event.
    hub.pub(
        EVENT.ON_CONFIG_REFRESH
        module.exports
    )

    module.exports

clearTimezone = () ->
    delete module.exports.TIMEZONE

update = (cb) ->
    fetchRequest({
        url: "/settings/company"
        sccb: ({company}) -> cb setState company
    })

is_ru = ->
    module.exports.COUNTRY.toLowerCase() is "ru"

isTrainingVisible = ->
    !module.exports.LICENSE?.TYPE and module.exports.ONBOARDING_TRIAL_TOURS_COUNT > 0

module.exports = {
    setState, setStaffDefaultState, findBranch, update, getOrderTypes, findOrderType, findCashbox,
    filterCashboxes, is_ru, isTrainingVisible, findLeadType, getLeadsTypes, clearTimezone, hub, EVENT
}
