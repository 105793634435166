import { INSTANCE_TYPE } from '@@constants/instanceTypes';

export enum StatusType {
  NEW = 1,
  ON_PERFORMANCE = 2,
  DELAYED = 3,
  REALIZED = 4,
  DELIVERY = 5,
  SUCCESS_CLOSED = 6,
  FAIL_CLOSED = 7,
}

export type StatusTypeUnion = (typeof StatusType)[keyof typeof StatusType];

export enum StatusRequiredField {
  UNDEFINED = 0,
  COMMENT = 1,
  PAYMENT = 2,
  CREATE_ORDER = 3,
  CREATE_SALE = 4,
}

export type StatusRequiredFieldUnion =
  (typeof StatusRequiredField)[keyof typeof StatusRequiredField];

export type StatusInstanceType = ExtractValues<
  Pick<typeof INSTANCE_TYPE, 'ORDER' | 'LEAD' | 'ESTIMATE'>
>;

export type GroupInstance =
  | typeof INSTANCE_TYPE.LEAD
  | typeof INSTANCE_TYPE.ORDER
  | typeof INSTANCE_TYPE.ESTIMATE;

export interface Group {
  id: number;
  idx: number;
  name: string;
  type: StatusTypeUnion;
  instances: GroupInstance[];
  color: string;
}

export interface Status {
  id: number;
  idx: number;
  name: string;
  color: string;
  instance: StatusInstanceType;
  required_field: StatusRequiredFieldUnion;
  should_move_asset: boolean;
  group: Omit<Group, 'color'>;
  display_in_overdue: boolean;
  asset_move_data: Record<string, unknown>;
  roles_can_set: {
    ids: number[];
    names: string[];
  };
  roles_can_see: {
    ids: number[];
    names: string[];
  };
  next_statuses: number[];
  time_norm?: number;
  time_norm_unit: number;
}
