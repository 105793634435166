map = require "lodash/map"

setState = (state, cb) ->
    map(state, (value, key) ->
        module.exports[key] = value
    )

    cb and cb()

module.exports = {setState}
