# Dialog footer prefabs.

{isJsonml, isjQuery, render} = require "lib/helpers"
{ pMerge, create_button, update_button, print_button, submit_button
, delete_ico_button } = require "lib/layout"
{error} = require "lib/logger"

# Current app.
{SUBMIT_CNAME, REMOVE_CNAME} = require "lib/widgets/Dialog/lib"


# Footer item wrapper function.
# -> markup|jquery content: content to wrap.
# -> kwargs: string space: item spacing;
#    see b-ddd-ftr__lside for available spaces
# Returns {data: jquery}: wrapper object.
footerItem = (content, kwargs={}) ->
    return unless content

    # Nothing to do, if this already wrapped item.
    return content if content.data

    tpl = (space) ->
        ["div"
            {
                "class": [
                    "b-ddd-ftr__item"
                    "b-ddd-ftr__item_space_#{space}" if space
                ].join " "
            }
        ]

    # Returning wrapper object.
    {space} = kwargs

    # Is this a markup?
    if isJsonml content then return data: (
        jQuery render tpl(space).concat [content]
    )

    # Is this a DOM-struct?
    if isjQuery content then return data: (
        jQuery render tpl space
    ).html content

    # In other case - WTF?
    error "Bad argument passed to footerItem();
           jsonml or jquery object is expected"


# Submit buttons.

# This is a base submit button attribs.
# js-submit-dialog is only for legacy apps needs.
SUBMIT = "class": "#{SUBMIT_CNAME} js-submit-dialog"

# Returns object: submit button attribs.
# -> object custom_attrs: custom attribs.
submitAttrs = (custom_attrs) ->
    pMerge custom_attrs, SUBMIT


createb = (opts={}) ->
    footerItem(
        create_button submitAttrs opts
        space: "2x"
    )


updateb = (opts={}) ->
    footerItem(
        update_button submitAttrs opts
        space: "2x"
    )


printb = (opts={}) ->
    footerItem(
        print_button submitAttrs opts
        space: "2x"
    )


# If submit buttons above is not enough.
# -> string text: custom button text.
submitb = (text, opts={}) ->
    footerItem(
        submit_button text, submitAttrs opts
        space: "2x"
    )


# Removal buttons.
# h-trash-button is only for legacy apps needs.
REMOVE =
    "class": "#{REMOVE_CNAME} h-trash-button"
    "data-cid": "modal-delete-btn"

removeb = (kwargs={}) ->
    footerItem(
        delete_ico_button pMerge REMOVE, kwargs
        space: "2x"
    )


module.exports = {
    footerItem
    submitb
    createb
    updateb
    removeb
    printb
}
