# Dialog footer

# +-------------------------------------------------------------+
# | [Submit] Left side    |                      Right side [X] |
# +-------------------------------------------------------------+

{render} = require "lib/helpers"
{qs, root} = require "lib/tplapp"
{footerItem} = require "lib/widgets/Dialog/footer/prefabs"


# const string: app namespace.
NS = "footer"


# Dialog footer.
class TFooter

    # string: required for
    # TTplApp realization.
    ns: NS

    # IContainer: app container.
    container: null

    # [markup|jquery]: left side layout items.
    # See standard available controls in PREFABS library.
    lside: [ ]

    # [markup|jquery]: right side layout items.
    # See standard available controls in PREFABS library.
    rside: [ ]

    # Ctor.
    # See TFooter props for opts info.
    constructor: (@container, opts={ }) ->
        @lside = opts.lside or @lside
        @rside = opts.rside or @rside


# Dialog footer template.
class IFooterTpl

    # Returns markup: footer markup.
    @init: (self) ->
        ["div", { "class": "b-ddd-ftr
                            b-ddd-ftr_fix_no-js-margin
                            js-#{NS}-root" }
            ["div", { "class": "b-ddd-ftr__lside js-#{NS}-lside" }]
            ["div", { "class": "b-ddd-ftr__rside js-#{NS}-rside" }]
        ]


# Dialog footer interface.
class IFooter

    # Returns IContainer: footer root.
    @getRoot: (self) -> root self

    # Renders dialog template.
    @render: (self) ->
        # Rendering template.
        self.container.append(
            render IFooterTpl.init self
        )

        # Putting items into
        # left, right placeholders.
        append = (item, area) ->
            area.append footerItem(item).data

        lside_root = qs self, "lside"
        append item, lside_root for item in self.lside.filter (x) -> x

        rside_root = qs self, "rside"
        append item, rside_root for item in self.rside.filter (x) -> x

        self

    # Performs dialog config
    # after template render.
    @config: (self) -> self

    # Entry point.
    # -> TFooter: app instance.
    @init: (self) -> @config @render self


module.exports = {
    IFooterTpl
    TFooter
    IFooter
    NS
}
