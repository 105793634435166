import _toNumber from 'lodash/toNumber';

import { DISCOUNT_MULTIPLIERS } from '@@constants/billing';
import { LICENSE_DISCOUNT_TYPE } from '@@constants/discount';
import { PLAN_TYPE } from '@@constants/license';
import { isAfter, now } from '@@helpers/format/date';

const {
  isActiveTempDiscount,
  isTest,
  isTrial,
  isBusiness,
  isMaximum,
} = require('lib/billing');

export const getTotal = (options) => {
  const {
    period = 1,
    price,
    branches,
    employees,
    branch_seat_price,
    employee_seat_price,
    freeEmployees,
    freeBranches,
  } = options;

  return (
    period *
    (price +
      Math.max(branches - freeBranches, 0) * branch_seat_price +
      Math.max(employees - freeEmployees, 0) * employee_seat_price)
  );
};

const getExtraDiscount = (price) => {
  const { DISCOUNT } = require('interface/Company');

  const discounts = [DISCOUNT.SPREADER, DISCOUNT.PERSONAL];

  const totalWithDiscounts = discounts.reduce((res, discount) => {
    if (discount) {
      return res - (res * discount) / 100;
    }

    return res;
  }, price);

  return price - totalWithDiscounts;
};

const getDiscountNextPayment = (price, { discount }) => {
  if (!discount) {
    return 0;
  }

  return (price * discount) / 100.0;
};

const getTempDiscount = (price, period) => {
  if (period !== 6 || isActiveTempDiscount()) {
    return 0;
  }

  return price * DISCOUNT_MULTIPLIERS[period];
};

const getDiscount = (price, period) => {
  const discount = price * DISCOUNT_MULTIPLIERS[period];
  return _toNumber(discount) || 0;
};

export const calculateTotalPrice = (period, options) => {
  const { nextDiscounts, nextDiscountType, nextDiscountTill } = options;

  const total = getTotal({ period, ...options });

  const discountPerPeriod = nextDiscounts?.[period];
  const isDiscountExist = discountPerPeriod?.discount > 0;
  const isPercentDiscountType =
    nextDiscountType === LICENSE_DISCOUNT_TYPE.PERCENT;
  const isDiscountValid = isAfter(nextDiscountTill, now());

  if (isDiscountExist && isDiscountValid) {
    if (isPercentDiscountType) {
      const discount = getDiscountNextPayment(total, discountPerPeriod);

      return {
        total: total - discount,
        discount,
        freeDays: 0,
      };
    }

    return {
      total,
      discount: 0,
      freeDays: discountPerPeriod.discount,
    };
  }

  const tempDiscount = getTempDiscount(total, period);
  const discountByPeriod = getDiscount(total, period) || 0;
  const discount = tempDiscount > 0 ? tempDiscount : discountByPeriod;

  const fullDiscount = discount + getExtraDiscount(total - discount);

  return {
    total: total - fullDiscount,
    discount: fullDiscount,
    freeDays: 0,
  };
};

export const getPlanNameForAmplitude = (plan) => {
  if (plan === PLAN_TYPE.STARTUP) {
    return 'standart';
  }

  if (plan === PLAN_TYPE.BUSINESS) {
    return 'business';
  }

  return '';
};

export const isProAccess = () => {
  return isTest() || isTrial() || isBusiness() || isMaximum();
};
