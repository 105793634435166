{KEY, get, set} = require "interface/IStorage"


module.exports =
    get: (id) -> get(KEY.DATE_SELECTORS, {})[id]

    set: (id, type) ->
        return undefined if id is undefined

        stored = get KEY.DATE_SELECTORS, {}
        stored[id] = type
        set KEY.DATE_SELECTORS, stored

    remove: (id) ->
        return undefined if id is undefined

        stored = get KEY.DATE_SELECTORS, {}
        delete stored[id]
        set KEY.DATE_SELECTORS, stored
