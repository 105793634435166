import { toFloat, toLocaleNumber } from '@@helpers/format/number';

import { prepareMoneySymbolPosition } from './prepareMoneySymbolPosition';

const prepareMoney = (
  amount,
  {
    symbolPosition = 'right',
    symbolSeparator = ' ',
    symbol,
    ...numberOpts
  } = {},
) => {
  const formattedNumber = toLocaleNumber(amount, numberOpts);

  if (symbol) {
    return prepareMoneySymbolPosition({
      formattedNumber,
      symbol,
      symbolSeparator,
      symbolPosition,
    });
  }

  return formattedNumber;
};

export const toMoney = (amount, withSymbol = false, opts = {}) => {
  const floatAmount = toFloat(amount);

  if (withSymbol) {
    const {
      CURRENCY_SYMBOL,
      CURRENCY_SYMBOL_LEFT,
      LICENSE,
    } = require('interface/Company');
    const symbolPosition = CURRENCY_SYMBOL_LEFT ? 'left' : 'right';

    const licenseSymbolPosition = LICENSE?.CURRENCY_SYMBOL_LEFT
      ? 'left'
      : 'right';

    return prepareMoney(floatAmount, {
      symbol: opts.license_currency ? LICENSE.CURRENCY_SYMBOL : CURRENCY_SYMBOL,
      symbolPosition: opts.license_currency
        ? licenseSymbolPosition
        : symbolPosition,
      ...opts,
    });
  }

  return prepareMoney(floatAmount, opts);
};
