export const ASSETS_DIRECTORY_TYPES = {
  GROUP: 'group',
  BRAND: 'brand',
  MODEL: 'model',
  MODIFICATION: 'modification',
};

export const ASSETS_DIRECTORY_TYPE_MAPPING = {
  [ASSETS_DIRECTORY_TYPES.GROUP]: 0,
  [ASSETS_DIRECTORY_TYPES.BRAND]: 1,
  [ASSETS_DIRECTORY_TYPES.MODEL]: 2,
  [ASSETS_DIRECTORY_TYPES.MODIFICATION]: 3,
} as const;

const ASSETS_DIRECTORY_MAIN_TABLE_PATH = '/settings/directory/asset';

export const ASSETS_DIRECTORY_TABLE_NAMES = {
  [ASSETS_DIRECTORY_TYPES.GROUP]: `${ASSETS_DIRECTORY_MAIN_TABLE_PATH}/group`,
  [ASSETS_DIRECTORY_TYPES.BRAND]: `${ASSETS_DIRECTORY_MAIN_TABLE_PATH}/brand`,
  [ASSETS_DIRECTORY_TYPES.MODEL]: `${ASSETS_DIRECTORY_MAIN_TABLE_PATH}/model`,
  [ASSETS_DIRECTORY_TYPES.MODIFICATION]: `${ASSETS_DIRECTORY_MAIN_TABLE_PATH}/modification`,
};

export const ASSETS_DIRECTORY_PARENT_TABLE = {
  [ASSETS_DIRECTORY_TYPES.GROUP]: '',
  [ASSETS_DIRECTORY_TYPES.BRAND]:
    ASSETS_DIRECTORY_TABLE_NAMES[ASSETS_DIRECTORY_TYPES.GROUP],
  [ASSETS_DIRECTORY_TYPES.MODEL]:
    ASSETS_DIRECTORY_TABLE_NAMES[ASSETS_DIRECTORY_TYPES.BRAND],
  [ASSETS_DIRECTORY_TYPES.MODIFICATION]:
    ASSETS_DIRECTORY_TABLE_NAMES[ASSETS_DIRECTORY_TYPES.MODEL],
};
