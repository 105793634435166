export const TIME_UNITS = {
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
};

export const PERIOD_TYPES = {
  CUSTOM: 'custom',
  MONTH: 'month',
  CURRENT: 'current',
} as const;

export const SECONDS_IN_TIMESTAMP = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;

export const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;
export const SECONDS_IN_DAY = MINUTES_IN_DAY * SECONDS_IN_MINUTE;

export const MILLISECONDS_IN_MINUTE = SECONDS_IN_MINUTE * SECONDS_IN_TIMESTAMP;
export const MILLISECONDS_IN_HOUR = MINUTES_IN_HOUR * MILLISECONDS_IN_MINUTE;
export const MILLISECONDS_IN_DAY = HOURS_IN_DAY * MILLISECONDS_IN_HOUR;
