import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';

import {
  CHART_TYPE,
  CREATED_AT_DAY,
  CREATED_AT_MONTH,
  CREATED_AT_WEEK,
  CREATED_AT_YEAR,
  CREATED_AT_YEAR_ISO,
  DIAGRAM_TYPE,
  LAST_24_MONTHS,
  LAST_24_MONTHS_DRILLDOWN,
  LAST_24_WEEKS,
  LAST_24_WEEKS_DRILLDOWN,
  LAST_30_DAYS,
  LAST_30_DAYS_DRILLDOWN,
} from '@@constants/analytics';
import { COUNT, DURATION, MONEY } from '@@constants/fieldTypes';
import { toTime } from '@@helpers/format/date';
import { toMoney } from '@@helpers/format/money';
import { toLocaleNumber } from '@@helpers/format/number';

const getTypes = require('lib/widgets/DateSelector/types');

export const getDimensionKey = (field) => {
  const [baseKey, hierarchy] = field.split('@');

  if (hierarchy) {
    return baseKey;
  }

  return baseKey.split(':')[0];
};

export const getHierarchyKey = (field) => {
  const [, hierarchies] = field.split('@');

  if (hierarchies) {
    return hierarchies.split(':')[0];
  }

  return 'default';
};

export const getLevelKey = (field) => {
  return field.split(':')[1];
};

export const getDimensionFieldName = (dimensions, key, subName) => {
  const dimensionKey = getDimensionKey(key);

  const dimension = dimensions[dimensionKey];

  if (_get(dimension, 'has_details')) {
    const levelKey = getLevelKey(key);
    const level = _get(dimension, `levels.${levelKey}`);

    if (level && subName === 'id') {
      return `${dimensionKey}.${level.key}`;
    }

    if (level && subName === 'label') {
      return `${dimensionKey}.${level.label_attribute}`;
    }

    if (level && subName === 'order') {
      return `${dimensionKey}.${level.order_attribute}`;
    }

    return dimensionKey;
  }

  if (dimension && dimension.default_hierarchy_name !== 'default') {
    const levelKey = getLevelKey(key);

    return `${dimensionKey}.${levelKey}`;
  }

  return dimensionKey;
};

export const getMembersSortKey = (dimensions, dimensionKey) => {
  const idKey = getDimensionFieldName(dimensions, dimensionKey, 'id');
  const labelKey = getDimensionFieldName(dimensions, dimensionKey, 'label');
  const orderKey = getDimensionFieldName(dimensions, dimensionKey, 'order');

  if (idKey === orderKey) {
    return 'id';
  }

  if (labelKey === orderKey) {
    return 'title';
  }

  return null;
};

export const getMembersConfig = (object, dimensionKey) => {
  return {
    object,
    dimensionKey,
    dimension: getDimensionKey(dimensionKey),
    hierarchy: getHierarchyKey(dimensionKey),
    level: getLevelKey(dimensionKey),
  };
};

export const formatValueByType = (value, type) => {
  if (type === MONEY) {
    return toMoney(value, true);
  }

  if (type === COUNT) {
    return toLocaleNumber(value, { decimalCount: 0 });
  }

  if (type === DURATION) {
    return toTime(value);
  }

  return String(value);
};

export const getAggregateDataStoreKey = (
  config,
  { withoutSegment = false } = {},
) => {
  const preparedConfig = {
    sort: config.sort,
    object: config.object,
    groupBy: config.groupBy,
    segmentedBy: withoutSegment ? '' : config.segmentedBy,
    filters: config.filters.map(({ name, value, equal }) => ({
      name,
      value,
      equal,
    })),
  };

  return JSON.stringify(preparedConfig);
};

export const getChartLabel = (label) => {
  return _isUndefined(label) || label === 'undefined'
    ? __('Not specified')
    : String(label);
};

export const getMap = (items, keyName = 'name') => {
  return new Map(items.map((item) => [item[keyName], item]));
};

export const getDictionary = (items, keyName = 'name') => {
  return items.reduce((result, item) => {
    // eslint-disable-next-line no-param-reassign
    result[item[keyName]] = item;

    return result;
  }, {});
};

export const getRanges = () => {
  const types = getTypes();

  return [
    {
      label: types.LAST_30_DAYS.title(),
      value: LAST_30_DAYS,
    },
    {
      label: types.LAST_24_WEEKS.title(),
      value: LAST_24_WEEKS,
    },
    {
      label: types.LAST_24_MONTHS.title(),
      value: LAST_24_MONTHS,
    },
  ];
};

export const getDefaultChartType = (type, chartType) => {
  if (_isUndefined(chartType)) {
    return type === DIAGRAM_TYPE ? CHART_TYPE.VERTICAL_BAR : CHART_TYPE.LINE;
  }

  return chartType;
};

export const getTrendOptions = () => {
  const types = getTypes();

  return {
    [LAST_30_DAYS]: {
      name: LAST_30_DAYS,
      drilldown: LAST_30_DAYS_DRILLDOWN,
      range: types.LAST_30_DAYS.range,
      attributes: [CREATED_AT_YEAR, CREATED_AT_MONTH, CREATED_AT_DAY],
      cut: ['created_at@daily:30daysago-today'],
      parse: 'yyyy.M.d',
      format: 'dd.MM',
      tooltipFormat: 'dd MMMM',
    },
    [LAST_24_WEEKS]: {
      name: LAST_24_WEEKS,
      drilldown: LAST_24_WEEKS_DRILLDOWN,
      range: types.LAST_24_WEEKS.range,
      attributes: [CREATED_AT_YEAR_ISO, CREATED_AT_WEEK],
      cut: ['created_at@daily:24weeksago-today'],
      format: 'dd.MM',
      tooltipFormat: 'dd MMMM',
    },
    [LAST_24_MONTHS]: {
      name: LAST_24_MONTHS,
      drilldown: LAST_24_MONTHS_DRILLDOWN,
      range: types.LAST_24_MONTHS.range,
      attributes: [CREATED_AT_YEAR, CREATED_AT_MONTH],
      cut: ['created_at@daily:24monthsago-today'],
      parse: 'yyyy.M',
      format: 'MM/yy',
      tooltipFormat: 'MMMM yyyy',
    },
  };
};
