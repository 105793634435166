export const pages = {
  staff: {
    fileName: 'staff',
  },
  entrance: {
    fileName: 'entrance',
  },
  app: {
    fileName: 'app',
  },
  expired: {
    fileName: 'expired',
  },
  changePassword: {
    fileName: 'changePassword',
  },
} as const;
