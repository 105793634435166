###
    Auxiliary functions from the functional programming.
###


{keys, isArray, isFunction, copy, bool} = require "lib/helpers"


partial = (f, a...) -> (b...) -> f a..., b...

compose = (funcs...) -> (args...) ->
    funcs.reverse().map (f) -> args = [f args...]
    args[0]

apply = (funcs..., arg) -> funcs.map (f) -> f arg

###
    Performs a set of functions within the encapsulated state.
    Example:
        array = [1,2,3]
        => state [], (s) -> array.map (i) -> s.push i + 2
        [3,4,5]

        => state {}, (s) -> array.map (i) -> s[i] = true
        {1: true, 2: true, 3: true}
###
state = (s, funcs...) ->
    (compose funcs...) s
    s

###
    Remove `keys` from the given object.
    Example:
        => exclude {a: 1, b: 2, c: 3}, ["a", "c"]
        {b: 2}
###
exclude = (o, ks) ->
    _o = copy o
    for own k of _o when k in ks
        delete _o[k]

    _o

###
    Example:
        => toHash [["x", "y"], ["a", "b"], ["i", "j"]]
        {x: "y", a: "b", i: "j"}
###
toHash = (listOfTuples) ->
    _fixTypo = (name) -> name.charAt(0).toLowerCase() + name[1...]

    f = (s) -> listOfTuples.map ([k, v]) -> s[_fixTypo k] = v
    state {}, f

###
    Example:
        => toArray {"0": "a", "1": "b", "2": "c"}
        [["0", "a"], ["1", "b"], ["2", "c"]]
###
toArray = (o) ->
    f = (s) ->
        for own k, v of o
            s.push [k, v]
    state [], f

###
    Example:
        => [["0", "a"], ["1", "b"], ["2", "c"]]
        {"0": "a", "1": "b", "2": "c"}
###
toObject = (array) ->
    _iterator = (x, [k, v]) ->
        x[k] = v
        x
    array.reduce _iterator, new Object

###
    Example:
        => only {a: false, b: 4, c: "string"}, bool
        {b: 4, c: "string"}

        => only {a: 1, b: 2, c: 3}, ["a", "c"]
        {a: 1, c: 3}

        => only {a: 1, b: 2, c: -> 1}, {"functions"}
        {c: -> 1}
###
only = (o, ks) ->
    _iterator = (cond, s) ->
        for own k, v of o when cond k, v
            s[k] = v

    cond = if isArray ks
        (k) -> k in ks

    else if "functions" of ks
        (_, v) -> isFunction v

    else if isFunction ks
        (_, v) -> ks v

    state {}, partial _iterator, cond

###
    TBD: resolve collisions.
    Build from the array of object a new hash table.
    Example:
        => hashTable "name", [{name: "A", id: 0}, {name: "B", id: 1}]
        {"A": {name: "A", id: 0}, "B": {name: "B", id: 1}}
###
hashTable = (key, array = []) ->
    f = (s) -> array.map (_o, idx) ->
        o = copy _o
        # Saved index helps increase performance in some cases
        #   when working with hash table.
        # Used to build the backward relation with the given array.
        o._idx = idx
        s[o[key]] = o
    state {}, f

sum = (array) ->
    return 0 unless bool array
    array.reduce (x, y) -> parseFloat(x, 10) + parseFloat(y, 10)

merge = (args...) ->
    _reducer = (accum, o) ->
        for own k, v of o
            accum[k] = v

        accum

    args.reduce _reducer, {}

mergeWith = (fn, args...) ->
    _reducer = (accum, o) ->
        for own k, v of o
            if accum[k] is undefined
                accum[k] = v
            else
                accum[k] = fn accum[k], v

        accum

    args.reduce _reducer, {}

# Check if any item of the `array` are presented.
any = (array) -> (array.filter (v) -> bool v).length > 0

# Check if all items of the `array` are presented.
all = (array) -> (array.filter (v) -> bool v).length is array.length

# Returns an empty function.
empty = -> ->

###
    Associates a value in a nested associative structure, where `pairs` is a
        sequence of indexes and keys, and `val` is the new value and returns a
        new nested structure.

    Example:
        => assocIn [{name: "James", age: 20}, {name: "John", age: 43}], [1, "age"], 25
        [{name: "James", age: 25}, {name: "John", age: 43}]
###
assocIn = (v, pairs, val) ->
    f = (s) -> pairs.reduce (idx, key) -> s[idx][key] = val
    state v, f

###
    Updates a value in a associative structure, where `matcher` is a function
        that will test the old value and return the new value with the applied `fn`,
        and returns a new structure.

    Example:
        matcher = ({age}) -> age > 25
        fn = ({name}) -> "#{name} is very young"

        => updateIn [{name: "James", age: 20}, {name: "John", age: 43}], matcher, fn
        [{name: "James is very young", age: 20}, {name: "John", age: 43}]
###
updateIn = (coll, matcher, fn) -> coll.map (i) -> if matcher i then (fn i) else i

###
    Example:
        pred = (i) -> i % 2
        => sliceBy (pred, [1,2,3,4])
        {result: [2, 4], rest: [1, 2]}
###
sliceBy = (pred, array) ->
    f = ({result, rest}) ->
        array.map (i) -> if pred i then result.push i else rest.push i

    state {result: [], rest: []}, f

###
    Example:
        pred = (i) -> i.id
        => groupBy (pred, [{'id': 1}, {'id': '2'}])
        {1: [{'id': 1}], 2: [{'id': 2}]}
###
groupBy = (pred, array) ->
    grouper = (x, y) ->
        key = pred y
        x[key] = (x[key] or []).concat y
        x

    array.reduce grouper, new Object

unique = (array) ->
    o = new Object()
    array.map (i) -> o[i] = 1
    keys o

vectorToO = (vector) ->
    o = new Object()
    vector.map (i) -> o[i] = i
    o

# Orders array of objects by given key.
# -> string key: key in input array.
# -> [object] array: array (you don't believe!)
# Returns [object]: sorted array.
sortBy = (key, array) ->
    array1 = copy array
    array1.sort (i, j) ->
        if i[key] < j[key]
            -1

        else if i[key] > j[key]
            1

        else
            0

    array1


call = (f, args...) -> f args...

# TBD: full featured flatten fn.
# Note: supports only one level depp.
flatten = (array_of_arrays) -> [].concat.apply [], array_of_arrays

juxt = (funcs...) -> (args...) ->
    funcs.map (f) -> f args...

concat = (args...) ->
    args.join " "


# Applies fn to each key-value pair in item.
# -> object dict: input dict.
# -> object a: initial object, array (or etc)
# -> void fn: function to apply.
# Returns: result object.
each = (dict, a, fn) ->
    # Function receives:
    # -> object a: result object state.
    # -> string k: current key name.
    # -> object v: current key value.
    fn a, k, v for k, v of dict; a


# Returns bool: applies test() to every kv-pair of object;
# if test() returns `false` for any of kv-pair, - function returns false.
# Useful when eg you need to test that all kv-pairs have the same value.
# -> object o: test object.
# -> bool test: testing func.
every = (o, test) ->
    return false unless test v for k, v of o
    true


module.exports = {
    partial, compose, apply, state, exclude, toHash, toArray, only, hashTable,
    sum, merge, any, all, empty, assocIn, updateIn, sliceBy, groupBy,
    unique, vectorToO, sortBy, call, flatten, toObject, juxt, mergeWith, concat,
    each, every
}
