import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';

interface InitI18nextOptions {
  locale: string;
  loadPath: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultVariables?: { [index: string]: any };
  ns?: string | readonly string[];
  defaultNS?: false | string | readonly string[];
  setGlobalTranslateFn?: () => void;
}

export const initI18next = async ({
  locale,
  loadPath,
  defaultVariables = {},
  ns,
  defaultNS,
  setGlobalTranslateFn = () => {},
}: InitI18nextOptions) => {
  await i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      ns,
      defaultNS,
      fallbackNS: ns,
      load: 'all',
      fallbackLng: locale,
      lng: locale,
      keySeparator: false,
      nsSeparator: false,
      returnEmptyString: false,
      interpolation: { defaultVariables },
      backend: {
        backends: [HttpBackend, HttpBackend],
        backendOptions: [
          { loadPath },
          {
            loadPath: `/static/dist/i18n/${__DEV__ ? '' : `${process.env.REVISION}/`}{{lng}}/{{ns}}.json`,
          },
        ],
      },
    });

  setGlobalTranslateFn?.();
};
