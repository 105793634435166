export const DISCOUNT_TYPES = {
  CURRENCY: 0,
  PCT: 1,
} as const;

export const LICENSE_DISCOUNT_TYPE = {
  PERCENT: 0,
  DAYS: 1,
} as const;

export const DISCOUNT_SPONSOR = {
  COMPANY_DISCOUNT: 0,
  DISCOUNT: 1,
} as const;

export const CLIENT_DISCOUNT_TYPE = {
  GOODS: 'goods',
  MATERIALS: 'materials',
  SERVICES: 'services',
  ORDER_SERVICES: 'order_services',
  SALE_SERVICES: 'sale_services',
} as const;
