import { routerReducer } from 'react-router-redux';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import {
  createMigrate,
  createTransform,
  persistCombineReducers,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createResponsiveStateReducer } from 'redux-responsive';

import chatServiceApi from '@@api/chatService/rtk';
import api from '@@api/rtk';
import {
  DESKTOP_BREAKPOINT,
  EXTRA_SMALL_BREAKPOINT,
  HUGE_BREAKPOINT,
  INTERMEDIATE_BREAKPOINT,
  INTERMEDIATE_MEDIUM_BREAKPOINT,
  INTERMEDIATE_SMALL_BREAKPOINT,
  LARGE_BREAKPOINT,
  MEDIUM_BREAKPOINT,
  SMALL_BREAKPOINT,
  TINY_BREAKPOINT,
} from '@@constants/ui';

import adCampaigns from './adCampaigns/slice';
import analytics from './analytics/slice';
import appInfo from './appInfo/slice';
import auth from './auth/slice';
import badges from './badges/slice';
import branches from './branches/slice';
import callCards from './callCards/slice';
import dndTree from './dndTree/slice';
import dropdown from './dropdown/slice';
import eventFeed from './eventFeed/slice';
import fletcher from './fletcher/slice';
import modals from './modals/slice';
import notifications from './notifications/slice';
import onboarding from './onboarding/slice';
import order from './order/slice';
import resources from './resources/slice';
import schedule from './schedule/slice';
import tables from './tables/slice';
import tasks from './tasks/slice';
import ui from './ui/slice';
import {
  cachedTables,
  fullCachedTables,
  nestedTables,
  sortAndFieldsCachedFletcherTables,
  sortAndFieldsCachedTables,
} from './constants';

const tablesTransform = createTransform(
  (inboundState) => {
    const tableNames = {};

    cachedTables.forEach((tableName) => {
      tableNames[tableName] = omit(
        inboundState[tableName],
        'data',
        'loading',
        'error',
      );
    });

    fullCachedTables.forEach((tableName) => {
      tableNames[tableName] = omit(inboundState[tableName], 'loading', 'error');
    });

    sortAndFieldsCachedTables.forEach((tableName) => {
      const fletcherTableName = sortAndFieldsCachedFletcherTables.find(
        (name) => tableName === name,
      );

      const actualTableName = fletcherTableName
        ? Object.keys(inboundState).find((key) => {
            return !key.indexOf(fletcherTableName);
          })
        : tableName;

      tableNames[tableName] = pick(inboundState[actualTableName], [
        'sort',
        'fields',
      ]);
    });

    nestedTables.forEach((tableName) => {
      tableNames[tableName] = pick(inboundState[tableName], ['persistConfig']);
    });

    return tableNames;
  },
  (outboundState) => outboundState,
  { whitelist: ['tables'] },
);

const scheduleTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => outboundState,
  { whitelist: ['schedule'] },
);

const uiTransform = createTransform(
  (inboundState) => ({
    sidebar: pick(inboundState.sidebar, ['isCollapsed']),
  }),
  (outboundState) => outboundState,
  { whitelist: ['ui'] },
);

const migrations = {
  1: (state) => {
    // don`t delete
    if (state.schedule && !state.schedule.settings) {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          settings: {
            stepInTime: 60,
            weekSlotRowsCount: 2,
            orderFields: ['client_field', 'order_id_label', 'asset_field'],
          },
        },
      };
    }

    return state;
  },
};

const persistConfig = {
  key: 'app',
  version: 1,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: ['tables', 'schedule', 'ui'],
  transforms: [tablesTransform, scheduleTransform, uiTransform],
};

export default persistCombineReducers(persistConfig, {
  [api.reducerPath]: api.reducer,
  [chatServiceApi.reducerPath]: chatServiceApi.reducer,
  ui,
  tables,
  schedule,
  branches,
  appInfo,
  notifications,
  onboarding,
  dndTree,
  dropdown,
  fletcher,
  modals,
  auth,
  analytics,
  callCards,
  eventFeed,
  badges,
  tasks,
  adCampaigns,
  resources,
  order,
  routing: routerReducer,
  viewport: createResponsiveStateReducer({
    tiny: TINY_BREAKPOINT,
    extraSmall: EXTRA_SMALL_BREAKPOINT,
    intermediateSmall: INTERMEDIATE_SMALL_BREAKPOINT,
    small: SMALL_BREAKPOINT,
    intermediateMedium: INTERMEDIATE_MEDIUM_BREAKPOINT,
    medium: MEDIUM_BREAKPOINT,
    intermediate: INTERMEDIATE_BREAKPOINT,
    desktop: DESKTOP_BREAKPOINT,
    large: LARGE_BREAKPOINT,
    huge: HUGE_BREAKPOINT,
  }),
});
